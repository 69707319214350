import SVG from "react-inlinesvg";

const Icon = ({
  name,
  uri,
  className,
  color,
  height,
  width,
  size = 24,
  ...props
}) => {
  return (
    <SVG
      fill={color}
      className={className}
      height={height || size}
      width={width || size}
      src={uri || `/icons/${name}.svg`}
      {...props}
    />
  );
};

export default Icon;
