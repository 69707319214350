import React, { useCallback, useEffect, useState } from "react"
import Button from "../Button"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Icon from "../Icon"
import Footer from "../Footer"
import { useTranslation } from "react-i18next"
import { CATEGORY_FILES, CATEGORY_FILES_VI } from "../../constants/Files"
import { t } from "i18next"
import { Popover } from "react-tiny-popover"
import { beforeReleaseDay } from "../../utils/dateTime"

const MENU = [
  {
    text: "introduction",
    url: "/gioi-thieu-giai-thuong",
  },
  {
    text: "vanXuanAward2024",
    subMenu: [
      {
        text: "vanXuan2024",
        url: "/van-xuan",
      },
      {
        text: "vanXuanClassic2024",
        url: "/van-xuan-classic",
      },
      {
        text: "vanXuanStars2024",
        url: "/van-xuan-stars",
      },
    ],
  },
  {
    text: "companionExperts",
    url: "/chuyen-gia-dong-hanh",
  },
  {
    text: "newsEvents",
    url: "/tin-tuc-cuoc-thi",
  },
  {
    text: "library",
    url: "/thu-vien-bai-thi",
  },
  {
    text: "winnerList",
    url: "/danh-sach-thang-giai",
  },
]

const Header = ({ className = "" }) => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const currentURL = location.pathname
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)

  useEffect(() => {
    if (visibleMenu) {
      document.getElementById("mainLayoutId").classList.add("overflow-y-hidden")
    } else {
      document.getElementById("mainLayoutId").classList.remove("overflow-y-hidden")
    }
  }, [visibleMenu])

  const handleClickItem = useCallback((item) => {
    if (item.sectionId) {
      navigate(item.url, {
        state: {
          sectionId: item.sectionId,
        },
      })
    } else {
      if (item.url) {
        navigate(item.url)
      }
    }
  }, [])

  return (
    <header
      className={`fixed top-0 left-0 z-[99] w-full bg-header ${
        visibleMenu ? "md:flex md:h-full md:flex-col md:bg-background" : ""
      } ${className}`}
    >
      <div
        className={
          "mx-auto flex w-full max-w-[1568px] items-center justify-between px-4 py-10 md:py-6"
        }
      >
        <Link to="/" className="z-10 flex items-center gap-x-4 text-left">
          <img src={"/images/logo.png"} alt="Logo" className={"w-[120px] md:w-[80px]"} />
          {visibleMenu && (
            <img
              src={"/images/logo-text.svg"}
              alt="Logo"
              className={"hidden md:block md:w-[174px]"}
            />
          )}
        </Link>
        <button className={"hidden md:block"} onClick={() => setVisibleMenu(!visibleMenu)}>
          <Icon name={visibleMenu ? "close" : "menu"} />
        </button>
        <div className={"flex items-center gap-x-10 md:hidden"}>
          <ul className={"flex items-center gap-x-4"}>
            <li>
              <Link to={"/"}>
                <Icon name={"home"} />
              </Link>
            </li>
            {MENU.map((item) => {
              const isActive = item.url !== "/" && currentURL.includes(item.url)
              return (
                <li
                  onClick={() => handleClickItem(item)}
                  key={item.text}
                  className={`group relative cursor-pointer py-2 text-14 ${
                    isActive ? "border-b-1 border-white" : ""
                  }`}
                >
                  <span className={"uppercase hover:font-bold"}>{t(item.text)}</span>

                  {item?.subMenu && (
                    <ul
                      className={
                        "absolute top-[100%] left-0 hidden w-[180px] space-y-4 rounded-md bg-white p-4 text-black group-hover:block"
                      }
                    >
                      {item.subMenu.map((i) => {
                        const isSelected = item.url !== "/" && currentURL === i.url
                        return (
                          <li onClick={() => handleClickItem(i)}>
                            <span className={`hover:font-bold ${isSelected ? "border-b-1" : ""}`}>
                              {t(i.text)}
                            </span>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>
          <div className={"flex items-center gap-x-6"}>
            <Button
              disabled={!beforeReleaseDay()}
              onClick={() => {
                navigate("/dang-ky-du-thi")
              }}
              className={"h-[45px]"}
            >
              <b className={"text-14"}>{t("registration")}</b>
            </Button>
            <Button
              disabled={!beforeReleaseDay()}
              onClick={() => {
                navigate("/nop-bai-du-thi")
              }}
              className={"h-[45px]"}
            >
              <b className={"text-14"}>{t("submit")}</b>
            </Button>
            <Popover
              containerClassName="language-popover"
              onClickOutside={() => {
                setOpenPopover(false)
              }}
              isOpen={openPopover}
              positions={["bottom", "left"]}
              align={"center"}
              content={
                <div className="min-w-[180px] space-y-4 overflow-hidden rounded-lg bg-white p-4">
                  <div
                    className="cursor-pointer text-background"
                    onClick={() => {
                      i18n.changeLanguage("vi")
                      setOpenPopover(false)
                    }}
                  >
                    Tiếng việt
                  </div>
                  <div
                    className="cursor-pointer text-background"
                    onClick={() => {
                      i18n.changeLanguage("en")
                      setOpenPopover(false)
                    }}
                  >
                    English
                  </div>
                </div>
              }
            >
              <div
                className={"flex cursor-pointer items-center gap-x-2"}
                onClick={() => setOpenPopover(true)}
              >
                <Icon name={"language"} className={"fill-white"} />
                <p>{i18n.language === "vi" ? "VN" : "EN"}</p>
                <Icon name={"show-more"} className={"fill-white"} />
              </div>
            </Popover>
          </div>
        </div>
      </div>
      <div
        className={`z-[99] h-0 flex-1 overflow-y-auto transition-all duration-500 ${
          visibleMenu ? "md:opacity-1 md:h-auto" : "md:h-0 md:opacity-0"
        }`}
      >
        <Footer isHeaderMobile onClickItem={() => setVisibleMenu(false)} />
      </div>
    </header>
  )
}

export default Header
