import dayjs from "dayjs"
import { RELEASE_DAY } from "../constants"

export const formatDate = (date, format = "DD/MM/YYYY") => {
  return dayjs(date).format(format)
}

export const beforeReleaseDay = () => {
  // return false
  return dayjs().isBefore(RELEASE_DAY, "day")
}
