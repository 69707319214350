import { MessengerChat } from "react-messenger-chat-plugin"
import { ToastContainer } from "react-toastify"
import dayjs from "dayjs"
import localeData from "dayjs/plugin/localeData"

import MainRoutes from "router/MainRoutes"
import MainLayout from "./layouts/MainLayout"
import "./App.css"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

dayjs.extend(localeData)
function App() {
  const { i18n } = useTranslation()

  useEffect(() => {
    if (!i18n.language || !["vi", "en"].includes(i18n.language)) {
      i18n?.changeLanguage("vi")
    }
  }, [i18n])

  useEffect(() => {
    if (i18n.language === "vi") {
      import(`dayjs/locale/vi`).then(() => dayjs.locale("vi"))
    } else {
      import(`dayjs/locale/en-gb`).then(() => dayjs.locale("en-gb"))
    }
  }, [i18n.language])

  return (
    <>
      <MainLayout>
        <MainRoutes />
        <ToastContainer />
        <MessengerChat
          pageId="107351212459812"
          language="vi_VN"
          bottomSpacing={100}
          greetingDialogDisplay={"show"}
        />
      </MainLayout>
    </>
  )
}

export default App
