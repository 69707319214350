export const CATEGORY_FILES = {
  "grand-prix": "/files/VẠN XUÂN/Grand Prix/Grand prix.pdf",

  // COMMUNICATION
  "chien-dich-csr-an-tuong-nhat": "/files/VẠN XUÂN/COMMUNICATION/Chiến dịch CSR ấn tượng nhất.pdf",
  "chien-dich-influencers-an-tuong-nhat":
    "/files/VẠN XUÂN/COMMUNICATION/Chiến dịch Influencers ấn tượng nhất.pdf",
  "chien-dich-promotion-xuat-sac-nhat":
    "/files/VẠN XUÂN/COMMUNICATION/Chiến dịch Promotion xuất sắc nhất.pdf",
  "chien-dich-ra-mat-san-pham-an-tuong":
    "/files/VẠN XUÂN/COMMUNICATION/Chiến dịch ra mắt sản phẩm ấn tượng.pdf",
  "chien-dich-tai-dinh-vi-thuong-hieu-an-tuong":
    "/files/VẠN XUÂN/COMMUNICATION/Chiến dịch tái định vị thương hiệu ấn tượng.pdf",
  "chien-dich-tet-an-tuong-nhat": "/files/VẠN XUÂN/COMMUNICATION/Chiến dịch Tết ấn tượng nhất.pdf",

  // EVENT - ACTIVATION
  "hoat-dong-activation-cua-nam":
    "/files/VẠN XUÂN/EVENT - ACTIVATION/Hoạt động activation của năm.pdf",
  "su-kien-cua-nam": "/files/VẠN XUÂN/EVENT - ACTIVATION/Sự kiện của năm.pdf",
  "su-kien-tet-an-tuong-nhat": "/files/VẠN XUÂN/EVENT - ACTIVATION/Sự kiện Tết ấn tượng nhất.pdf",
  "su-kien-truyen-cam-hung-cua-nam":
    "/files/VẠN XUÂN/EVENT - ACTIVATION/Sự kiện truyền cảm hứng của năm.pdf",

  // CREATIVE CONCEPTS
  "key-visual-xuat-sac-nhat": "/files/VẠN XUÂN/CREATIVE CONCEPTS/BEST KEY VISUAL.pdf",
  "music-video-quang-cao-an-tuong":
    "/files/VẠN XUÂN/CREATIVE CONCEPTS/Music Video quảng cáo ấn tượng.pdf",
  "tvc-noi-bat-cua-nam": "/files/VẠN XUÂN/CREATIVE CONCEPTS/TVC nổi bật của năm.pdf",

  // COMPANY
  "digital-agency-cua-nam": "/files/VẠN XUÂN/COMPANY/agency/Digital Agency của năm.pdf",
  "event-agency-cua-nam": "/files/VẠN XUÂN/COMPANY/agency/Event Agency của năm.pdf",
  "independent-agency-cua-nam": "/files/VẠN XUÂN/COMPANY/agency/Independent Agency của năm.pdf",
  "network-agency-cua-nam": "/files/VẠN XUÂN/COMPANY/agency/Network Agency của năm.pdf",
  "pr-agency-cua-nam": "/files/VẠN XUÂN/COMPANY/agency/PR Agency của năm.pdf",

  "production-house-cua-nam":
    "/files/VẠN XUÂN/COMPANY/Production House/Creative Production House của năm.pdf",

  "nen-tang-quang-cao-cua-nam": "/files/VẠN XUÂN/COMPANY/Platform/Nền tảng của năm.pdf",

  "thuong-hieu-banh-keo-cua-nam": "/files/VẠN XUÂN/COMPANY/Brand/Creative brand of the year.pdf",
  "thuong-hieu-bao-hiem-cua-nam": "/files/VẠN XUÂN/COMPANY/Brand/Creative brand of the year.pdf",
  "thuong-hieu-bat-dong-san-cua-nam":
    "/files/VẠN XUÂN/COMPANY/Brand/Creative brand of the year.pdf",
  "thuong-hieu-dinh-duong-cua-nam": "/files/VẠN XUÂN/COMPANY/Brand/Creative brand of the year.pdf",
  "thuong-hieu-do-uong-co-con-cua-nam":
    "/files/VẠN XUÂN/COMPANY/Brand/Creative brand of the year.pdf",
  "thuong-hieu-do-uong-khong-con-cua-nam":
    "/files/VẠN XUÂN/COMPANY/Brand/Creative brand of the year.pdf",
  "thuong-hieu-du-lich-lu-hanh-cua-nam":
    "/files/VẠN XUÂN/COMPANY/Brand/Creative brand of the year.pdf",
  "thuong-hieu-duoc-yeu-thich-nhat": "/files/VẠN XUÂN/COMPANY/Brand/Creative brand of the year.pdf",
  "thuong-hieu-gia-vi-cua-nam": "/files/VẠN XUÂN/COMPANY/Brand/Creative brand of the year.pdf",
  "thuong-hieu-my-pham-lam-dep-cua-nam":
    "/files/VẠN XUÂN/COMPANY/Brand/Creative brand of the year.pdf",
  "thuong-hieu-ngan-hang-cua-nam": "/files/VẠN XUÂN/COMPANY/Brand/Creative brand of the year.pdf",
  "thuong-hieu-o-to-cua-nam": "/files/VẠN XUÂN/COMPANY/Brand/Creative brand of the year.pdf",
  "thuong-hieu-thuc-pham-cua-nam": "/files/VẠN XUÂN/COMPANY/Brand/Creative brand of the year.pdf",

  // TALENT
  "account-cua-nam": "/files/VẠN XUÂN/TALENT/Agency/Account của năm.pdf",
  "creative-director-cua-nam": "/files/VẠN XUÂN/TALENT/Agency/Creative Director của năm.pdf",
  "strategist-cua-nam": "/files/VẠN XUÂN/TALENT/Agency/Strategist của năm.pdf",

  "art-director-cua-nam": "/files/VẠN XUÂN/TALENT/Production House/Art Director của năm.pdf",
  "dao-dien-cua-nam": "/files/VẠN XUÂN/TALENT/Production House/Đạo diễn của năm.pdf",
  "dop-cua-nam": "/files/VẠN XUÂN/TALENT/Production House/D.O.P của năm.pdf",
  "music-composer-cua-nam": "/files/VẠN XUÂN/TALENT/Production House/Music Composer của năm.pdf",
  "photographer-cua-nam": "/files/VẠN XUÂN/TALENT/Production House/Photographer của năm.pdf",

  "dai-su-quang-cao-an-tuong-nhat":
    "/files/VẠN XUÂN/TALENT/Influencer/Đại sứ Quảng cáo ấn tượng nhất.pdf",
  "dai-su-quang-cao-duoc-yeu-thich-nhat":
    "/files/VẠN XUÂN/TALENT/Influencer/Đại sứ Quảng cáo được yêu thích nhất.pdf",
  "dai-su-quang-cao-nhi-duoc-yeu-thich-nhat":
    "/files/VẠN XUÂN/TALENT/Influencer/Đại sứ quảng cáo nhí được yêu thích nhất.pdf",
  "dai-su-quang-cao-truyen-cam-hung":
    "/files/VẠN XUÂN/TALENT/Influencer/Đại sứ Quảng cáo truyền cảm hứng.pdf",
  "kol-noi-bat-cua-nam": "/files/VẠN XUÂN/TALENT/Influencer/KOL nổi bật của năm.pdf",
  "nha-sang-tao-noi-dung-an-tuong":
    "/files/VẠN XUÂN/TALENT/Influencer/Nhà sáng tạo nội dung ấn tượng.pdf",
  "social-seller-cua-nam": "/files/VẠN XUÂN/TALENT/Influencer/Social Seller của năm.pdf",
}

export const CATEGORY_FILES_VI = {
  "grand-prix": "/files/VẠN XUÂN TV/Grand Prix/Grand prix.pdf",

  // COMMUNICATION
  "chien-dich-csr-an-tuong-nhat":
    "/files/VẠN XUÂN TV/COMMUNICATION/CHIẾN DỊCH CSR ẤN TƯỢNG NHẤT.pdf",
  "chien-dich-influencers-an-tuong-nhat":
    "/files/VẠN XUÂN TV/COMMUNICATION/CHIẾN DỊCH INFLUENCERS ẤN TƯỢNG NHẤT.pdf",
  "chien-dich-promotion-xuat-sac-nhat":
    "/files/VẠN XUÂN TV/COMMUNICATION/CHIẾN DỊCH PROMOTION XUẤT SẮC NHẤT.pdf",
  "chien-dich-ra-mat-san-pham-an-tuong":
    "/files/VẠN XUÂN TV/COMMUNICATION/CHIẾN DỊCH RA MẮT SẢN PHẨM ẤN TƯỢNG.pdf",
  "chien-dich-tai-dinh-vi-thuong-hieu-an-tuong":
    "/files/VẠN XUÂN TV/COMMUNICATION/CHIẾN DỊCH TÁI ĐỊNH VỊ THƯƠNG HIỆU ẤN TƯỢNG.pdf",
  "chien-dich-tet-an-tuong-nhat":
    "/files/VẠN XUÂN TV/COMMUNICATION/CHIẾN DỊCH TẾT ẤN TƯỢNG NHẤT.pdf",

  // EVENT - ACTIVATION
  "hoat-dong-activation-cua-nam":
    "/files/VẠN XUÂN TV/EVENT - ACTIVATION/HOẠT ĐỘNG ACTIVATION CỦA NĂM.pdf",
  "su-kien-cua-nam": "/files/VẠN XUÂN TV/EVENT - ACTIVATION/SỰ KIỆN CỦA NĂM.pdf",
  "su-kien-tet-an-tuong-nhat":
    "/files/VẠN XUÂN TV/EVENT - ACTIVATION/SỰ KIỆN TẾT ẤN TƯỢNG NHẤT.pdf",
  "su-kien-truyen-cam-hung-cua-nam":
    "/files/VẠN XUÂN TV/EVENT - ACTIVATION/SỰ KIỆN TRUYỀN CẢM HỨNG CỦA NĂM.pdf",

  // CREATIVE CONCEPTS
  "key-visual-xuat-sac-nhat": "/files/VẠN XUÂN TV/CREATIVE CONCEPTS/BEST KEY VISUAL.pdf",
  "music-video-quang-cao-an-tuong":
    "/files/VẠN XUÂN TV/CREATIVE CONCEPTS/MUSIC VIDEO QUẢNG CÁO ẤN TƯỢNG.pdf",
  "tvc-noi-bat-cua-nam": "/files/VẠN XUÂN TV/CREATIVE CONCEPTS/TVC NỔI BẬT CỦA NĂM.pdf",

  // COMPANY
  "digital-agency-cua-nam": "/files/VẠN XUÂN TV/COMPANY/AGENCY/DIGITAL AGENCY CỦA NĂM.pdf",
  "event-agency-cua-nam": "/files/VẠN XUÂN TV/COMPANY/AGENCY/EVENT AGENCY CỦA NĂM.pdf",
  "independent-agency-cua-nam": "/files/VẠN XUÂN TV/COMPANY/AGENCY/INDEPENDENT AGENCY CỦA NĂM.pdf",
  "network-agency-cua-nam": "/files/VẠN XUÂN TV/COMPANY/AGENCY/NETWORK AGENCY CỦA NĂM.pdf",
  "pr-agency-cua-nam": "/files/VẠN XUÂN TV/COMPANY/AGENCY/PR AGENCY CỦA NĂM.pdf",

  "production-house-cua-nam":
    "/files/VẠN XUÂN TV/COMPANY/PRODUCTION HOUSE/CREATIVE PRODUCTION HOUSE CỦA NĂM.pdf",

  "nen-tang-quang-cao-cua-nam": "/files/VẠN XUÂN TV/COMPANY/PLATFORM/NỀN TẢNG CỦA NĂM.pdf",

  "thuong-hieu-banh-keo-cua-nam":
    "/files/VẠN XUÂN TV/COMPANY/BRAND/Thương hiệu sáng tạo của năm.pdf",
  "thuong-hieu-bao-hiem-cua-nam":
    "/files/VẠN XUÂN TV/COMPANY/BRAND/Thương hiệu sáng tạo của năm.pdf",
  "thuong-hieu-bat-dong-san-cua-nam":
    "/files/VẠN XUÂN TV/COMPANY/BRAND/Thương hiệu sáng tạo của năm.pdf",
  "thuong-hieu-dinh-duong-cua-nam":
    "/files/VẠN XUÂN TV/COMPANY/BRAND/Thương hiệu sáng tạo của năm.pdf",
  "thuong-hieu-do-uong-co-con-cua-nam":
    "/files/VẠN XUÂN TV/COMPANY/BRAND/Thương hiệu sáng tạo của năm.pdf",
  "thuong-hieu-do-uong-khong-con-cua-nam":
    "/files/VẠN XUÂN TV/COMPANY/BRAND/Thương hiệu sáng tạo của năm.pdf",
  "thuong-hieu-du-lich-lu-hanh-cua-nam":
    "/files/VẠN XUÂN TV/COMPANY/BRAND/Thương hiệu sáng tạo của năm.pdf",
  "thuong-hieu-duoc-yeu-thich-nhat":
    "/files/VẠN XUÂN TV/COMPANY/BRAND/Thương hiệu sáng tạo của năm.pdf",
  "thuong-hieu-gia-vi-cua-nam": "/files/VẠN XUÂN TV/COMPANY/BRAND/Thương hiệu sáng tạo của năm.pdf",
  "thuong-hieu-my-pham-lam-dep-cua-nam":
    "/files/VẠN XUÂN TV/COMPANY/BRAND/Thương hiệu sáng tạo của năm.pdf",
  "thuong-hieu-ngan-hang-cua-nam":
    "/files/VẠN XUÂN TV/COMPANY/BRAND/Thương hiệu sáng tạo của năm.pdf",
  "thuong-hieu-o-to-cua-nam": "/files/VẠN XUÂN TV/COMPANY/BRAND/Thương hiệu sáng tạo của năm.pdf",
  "thuong-hieu-thuc-pham-cua-nam":
    "/files/VẠN XUÂN TV/COMPANY/BRAND/Thương hiệu sáng tạo của năm.pdf",

  // TALENT
  "account-cua-nam": "/files/VẠN XUÂN TV/TALENT/AGENCY/ACCOUNT CỦA NĂM.pdf",
  "creative-director-cua-nam": "/files/VẠN XUÂN TV/TALENT/AGENCY/CREATIVE DIRECTOR CỦA NĂM.pdf",
  "strategist-cua-nam": "/files/VẠN XUÂN TV/TALENT/AGENCY/STRATEGIST CỦA NĂM.pdf",

  "art-director-cua-nam": "/files/VẠN XUÂN TV/TALENT/PRODUCTION HOUSE/ART DIRECTOR CỦA NĂM.pdf",
  "dao-dien-cua-nam": "/files/VẠN XUÂN TV/TALENT/PRODUCTION HOUSE/ĐẠO DIỄN CỦA NĂM.pdf",
  "dop-cua-nam": "/files/VẠN XUÂN TV/TALENT/PRODUCTION HOUSE/D.O.P CỦA NĂM.pdf",
  "music-composer-cua-nam": "/files/VẠN XUÂN TV/TALENT/PRODUCTION HOUSE/MUSIC COMPOSER CỦA NĂM.pdf",
  "photographer-cua-nam": "/files/VẠN XUÂN TV/TALENT/PRODUCTION HOUSE/PHOTOGRAPHER CỦA NĂM.pdf",

  "dai-su-quang-cao-an-tuong-nhat":
    "/files/VẠN XUÂN TV/TALENT/INFLUENCER/ĐẠI SỨ QUẢNG CÁO ẤN TƯỢNG NHẤT.pdf",
  "dai-su-quang-cao-duoc-yeu-thich-nhat":
    "/files/VẠN XUÂN TV/TALENT/INFLUENCER/ĐẠI SỨ QUẢNG CÁO ĐƯỢC YÊU THÍCH NHẤT.pdf",
  "dai-su-quang-cao-nhi-duoc-yeu-thich-nhat":
    "/files/VẠN XUÂN TV/TALENT/INFLUENCER/ĐẠI SỨ QUẢNG CÁO NHÍ ĐƯỢC YÊU THÍCH NHẤT.pdf",
  "dai-su-quang-cao-truyen-cam-hung":
    "/files/VẠN XUÂN TV/TALENT/INFLUENCER/ĐẠI SỨ QUẢNG CÁO TRUYỀN CẢM HỨNG.pdf",
  "kol-noi-bat-cua-nam": "/files/VẠN XUÂN TV/TALENT/INFLUENCER/KOL NỔI BẬT CỦA NĂM.pdf",
  "nha-sang-tao-noi-dung-an-tuong":
    "/files/VẠN XUÂN TV/TALENT/INFLUENCER/NHÀ SÁNG TẠO NỘI DUNG ẤN TƯỢNG.pdf",
  "social-seller-cua-nam": "/files/VẠN XUÂN TV/TALENT/INFLUENCER/SOCIAL SELLER CỦA NĂM.pdf",
}
