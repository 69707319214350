import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_FSHARE_API_URL}/api`,
  timeout: 60000,
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('FSHARE_LOGIN_TOKEN');
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    config.headers['Fshare-User-Agent'] = process.env.REACT_APP_FSHARE_APP_NAME;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // handle refresh token
    }
    return Promise.reject(error);
  },
);

export const setAuthToken = (token, sessionId) => {
  if (token) {
    //applying token
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    instance.defaults.headers.common['fshare-user-agent'] = process.env.REACT_APP_FSHARE_APP_NAME;
    instance.defaults.headers.common["fshare-session-id"] = sessionId;
  } else {
    //deleting the token from header
    delete instance.defaults.headers.common["Authorization"];
  }
};

export default instance;
