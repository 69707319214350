import axiosFShare from "../axiosFShare"


export const loginFShare = (payload) => {
  return axiosFShare.post('/user/login', payload)
}

export const getFshareUploadLink = (formData) => {
  return axiosFShare.post('/session/upload', formData)
}

export const getFshareDownloadLink = (payload) => {
  return axiosFShare.post('/session/download', payload)
}

export const setFshareDirectLink = (payload) => {
  return axiosFShare.post('/share/SetDirectLink', payload)
}

