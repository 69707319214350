import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import dayjs from "dayjs"

const HomePage = React.lazy(() => import("pages/Home"))
const RegisterPage = React.lazy(() => import("pages/Register"))
const CreateSubmissionPage = React.lazy(() => import("pages/CreateSubmission"))
const CreateSubmissionResultPage = React.lazy(() => import("pages/CreateSubmissionResult"))
const SubmissionsPage = React.lazy(() => import("pages/Submissions"))
const SubmissionDetailPage = React.lazy(() => import("pages/SubmissionDetail"))
const BlogsPage = React.lazy(() => import("pages/Blogs"))
const BlogDetailPage = React.lazy(() => import("pages/BlogDetail"))
const IntroducePage = React.lazy(() => import("pages/Introduce"))
const CompanionExpertPage = React.lazy(() => import("pages/CompanionExpert"))
const WinningListPage = React.lazy(() => import("pages/WinningList"))
const VanXuanClassicPage = React.lazy(() => import("pages/VanXuanClassic"))
const VanXuanPage = React.lazy(() => import("pages/VanXuan"))
const VanXuanStarsPage = React.lazy(() => import("pages/VanXuanStars"))
const PdfPage = React.lazy(() => import("pages/Pdf"))

require("dayjs/locale/vi")
dayjs.locale("vi")

const MainRoutes = () => {
  return (
    <React.Suspense fallback={<div className={"fixed top-0 left-0 h-full w-full "} />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/dang-ky-du-thi" element={<RegisterPage />} />
        <Route path="/nop-bai-du-thi" element={<CreateSubmissionPage />} />
        <Route path="/nop-bai-du-thi/thanh-toan" element={<CreateSubmissionResultPage />} />
        <Route path="/thu-vien-bai-thi" element={<SubmissionsPage />} />
        <Route path="/bai-thi/:id" element={<SubmissionDetailPage />} />
        <Route path="/tin-tuc-cuoc-thi" element={<BlogsPage />} />
        <Route path="/tin-tuc-cuoc-thi/:id" element={<BlogDetailPage />} />
        <Route path="/gioi-thieu-giai-thuong" element={<IntroducePage />} />
        <Route path="/chuyen-gia-dong-hanh" element={<CompanionExpertPage />} />
        <Route path="/danh-sach-thang-giai" element={<WinningListPage />} />
        <Route path="/van-xuan" element={<VanXuanPage />} />
        <Route path="/van-xuan-classic" element={<VanXuanClassicPage />} />
        <Route path="/van-xuan-stars" element={<VanXuanStarsPage />} />
        <Route path="/pdf" element={<PdfPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </React.Suspense>
  )
}

export default MainRoutes
