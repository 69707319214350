const awards = {
  "chien-dich-tet-an-tuong-nhat": "The most impressive Tet campaign",
  "chien-dich-tai-dinh-vi-thuong-hieu-an-tuong": "Impressive brand repositioning campaign",
  "chien-dich-influencers-an-tuong-nhat": "The most impressive Influencers campaign",
  "chien-dich-ra-mat-san-pham-an-tuong": "Impressive product launch campaign",
  "chien-dich-csr-an-tuong-nhat": "The most impressive CSR campaign",
  "chien-dich-promotion-xuat-sac-nhat": "Best Promotion Campaign",
  "su-kien-cua-nam": "Event of the year",
  "su-kien-tet-an-tuong-nhat": "The most impressive Tet event",
  "su-kien-truyen-cam-hung-cua-nam": "Inspirational event of the year",
  "hoat-dong-activation-cua-nam": "Activation activity of the year",
  "tvc-noi-bat-cua-nam": "Outstanding TVC of the year",
  "key-visual-xuat-sac-nhat": "Best Key Visual",
  "music-video-quang-cao-an-tuong": "Music Impressive promotional video",
  "independent-agency-cua-nam": "Independent Agency of the Year",
  "network-agency-cua-nam": "Network Agency of the Year",
  "digital-agency-cua-nam": "Digital Agency of the Year",
  "pr-agency-cua-nam": "PR Agency of the year",
  "event-agency-cua-nam": "Event Agency of the year",
  "production-house-cua-nam": "Creative Production House of the year",
  "nen-tang-quang-cao-cua-nam": "Advertising platform of the year",
  "thuong-hieu-duoc-yeu-thich-nhat": "Most creative favorite brand",
  "thuong-hieu-my-pham-lam-dep-cua-nam": "Creative cosmetic & beauty brand of the year",
  "thuong-hieu-bao-hiem-cua-nam": "Creative insurance brand of the year",
  "thuong-hieu-ngan-hang-cua-nam": "Creative banking brand of the year",
  "thuong-hieu-o-to-cua-nam": "Creative car brand of the year",
  "thuong-hieu-du-lich-lu-hanh-cua-nam": "Creative travel and tourism brand of the year",
  "thuong-hieu-do-uong-co-con-cua-nam": "Creative alcoholic beverage brand of the year",
  "thuong-hieu-do-uong-khong-con-cua-nam": "Creative non-alcoholic beverage brand of the year",
  "thuong-hieu-dinh-duong-cua-nam": "Creative nutrition brand of the year",
  "thuong-hieu-gia-vi-cua-nam": "Creative culinary brand of the year",
  "thuong-hieu-thuc-pham-cua-nam": "Creative food brand of the year",
  "thuong-hieu-banh-keo-cua-nam": "Creative confectionery brand of the year",
  "thuong-hieu-bat-dong-san-cua-nam": "Creative real-estate Brand of the Year",
  "strategist-cua-nam": "Strategist of the year",
  "account-cua-nam": "Account of the year",
  "creative-director-cua-nam": "Creative Director of the year",
  "dao-dien-cua-nam": "Director of the year",
  "dop-cua-nam": "D.O.P of the year",
  "art-director-cua-nam": "Art Director of the year",
  "music-composer-cua-nam": "Music Composer of the Year",
  "photographer-cua-nam": "Photographer of the year",
  "kol-noi-bat-cua-nam": "Outstanding KOL of the year",
  "nha-sang-tao-noi-dung-an-tuong": "Impressive content creator",
  "social-seller-cua-nam": "Social Seller of the year",
  "dai-su-quang-cao-truyen-cam-hung": "Inspirational Advertising Ambassador",
  "dai-su-quang-cao-an-tuong-nhat": "Most impressive Advertising Ambassador",
  "dai-su-quang-cao-duoc-yeu-thich-nhat": "Most popular Advertising Ambassador",
  "dai-su-quang-cao-nhi-duoc-yeu-thich-nhat": "Most popular child advertising ambassador",
  "quang-cao-tren-truyen-hinh": "Television advertising",
  "quang-cao-tren-bang-bien-ngoai-troi": "Billboard and outdoor advertising",
  "quang-cao-tren-bao-dien-tu-va-nen-tang-xa-hoi": "Online newspaper and social media advertising",
  "quang-cao-truyen-thong-tich-hop": "Integrated media advertising",
  "kich-ban-tvc-xuat-sac-nhat": "Best TVC script",
  "dao-dien-tvc-xuat-sac-nhat": "Best TVC director",
  "giam-doc-sang-tao-xuat-sac-nhat": "Best creative director",
  "giam-doc-hinh-anh-xuat-sac-nhat": "Best cinematographer",
  "thong-diep-quang-cao-an-tuong": "Impressive advertising message",
  "copcept-thiet-ke-sang-tao-nhat": "Most creative design concept",
  "key-visua-xuat-sac-nhat": "Best key visual",
  "thiet-ke-ooh-don-an-tuong": "Impressive OOH (single) design",
  "thiet-ke-chuoi-ooh-an-tuong": "Impressive OOH campaign design",
  "thong-diep-quang-cao-ooh-an-tuong": "Impressive OOH advertising message",
  "kich-ban-xuat-sac-nhat": "Best script",
  "dao-dien-xuat-sac-nhat": "Best director",
  "thiet-ke-hinh-anh-an-tuong": "Impressive image design",
  "thong-diep-quang-cao-online-an-tuong": "Impressive online advertising message",
  "hoat-dong-nang-cao-hinh-anh-thuong-hieu-an-tuong": "Impressive brand image enhancement activity",
  "thuong-hieu-co-hoat-dong-xa-hoi-y-nghia-thuong-hieu-vi-cong-dong-xuat-sac-nhat":
    "Brand with meaningful social activities / Best community-focused brand",
  "thuong-hieu-co-nhieu-hoat-dong-sang-tao-nhat-thuong-hieu-co-gia-tri-cao-nhat":
    "Brand with the most creative activities / Most valuable brand",
  "chien-dich-truyen-hinh-truyen-thanh-xuat-sac-nhat": "Best TV/radio campaign",
  "chien-dich-ooh-xuat-sac-nhat": "Best OOH campaign",
  "chien-dich-mang-xa-hoi-xuat-sac-nhat": "Best social media campaign (Zalo/FB/IG/TikTok)",
  "hoat-dong-tuong-tac-an-tuong": "Impressive interactive activity",
  "dai-su-quang-cao-an-tuong": "Impressive brand ambassador",
  "dai-su-quang-cao-yeu-thich": "Favorite brand ambassador",
}

const en = {
  translation: {
    grade: "grade",
    introduction: "Introduction",
    vanXuanAward2024: "Van Xuan Award 2024",
    vanXuanClassic2024: "Van Xuan Classic 2024",
    vanXuan2024: "Van Xuan 2024",
    vanXuanStars2024: "Van Xuan Stars 2024",
    companionExperts: "Companion Experts",
    newsEvents: "News/ Events",
    library: "Library",
    winnerList: "Winner List",
    registration: "Registration",
    submit: "Submit",
    vietnamCreativeAdvertisingAward2023: "Vietnam Creative Advertising Award 2023",
    vanXuan: "Van Xuan",
    vanXuanClassic: "Van Xuan Classic",
    VanXuan: "Van Xuan",
    VanXuanClassic: "Van Xuan Classic",
    vanXuanStars: "Van Xuan Stars",
    detail: "Detail",
    timeForSubmissionOfEntries: "Time for submission of entries",
    theOnlineRegistrationPortalIsOpenFrom: "The online registration portal is open from",
    day: "Day",
    hour: "Hour",
    minute: "Minute",
    second: "Second",
    viewTheEntryGallery: "View the entry gallery",
    directingAndOrganizingUnit: "Directing and organizing unit",
    implementationUnit: "Implementation unit",
    awardCategory: "Award category",
    contestTime: "Contest time",
    announcingTheContest: "Announcing the contest",
    screeningAndGradingExamEntries: "Screening and grading exam entries",
    finalGalaAwardsCeremony: "Final Gala awards ceremony",
    directingOrganizingUnit: "Directing & organizing unit",
    companionUnit: "Companion unit",
    sponsorRegistration: "Sponsor registration",
    communicationsSupportUnit: "Communications support unit",
    competitionNews: "Competition news",
    seeMoreNews: "See more news",
    uploadFromComputer: "Upload from computer",
    submitVideoUsingLink: "Submit video using link",
    uploadedVideosCannotExceed1gb: "Uploaded videos cannot exceed 1GB!",
    videoUploadFailedPleaseReUpload: "Video upload failed, please re-upload!",
    videoUploadSuccessful: "Video upload successful!",
    uploadVideo: "Upload video",
    pleaseSelectCategory: "Please select a category",
    pleaseEnterBrandNameIndividualNameOfNominee:
      "Please enter brand name / individual name of nominee",
    exceededAllowedCharacters: "Exceeded allowed characters",
    pleaseEnterEntryDescription: "Please enter entry description",
    pleaseSelectStartMonth: "Please select start month",
    pleaseSelectStartYear: "Please select start year",
    pleaseUploadEntryImages: "Please upload entry images",
    maximum12Images: "Maximum 12 images",
    pleaseUploadAtLeast1VietnameseOrEnglishIdeaIntroductionArticle:
      "Please upload at least 1 Vietnamese or English idea introduction article",
    pleaseUploadLegalCommitment: "Please upload legal commitment",
    pleaseUploadCopyrightOwnershipCommitment: "Please upload copyright ownership commitment",
    pleaseEnterEntryCode: "Please enter entry code",
    pleaseEnterCompanyName: "Please enter company name",
    pleaseEnterTaxCode: "Please enter tax code",
    pleaseEnterValidNumber: "Please enter valid number",
    pleaseEnterCompanyAddress: "Please enter company address",
    emailIsNotInCorrectFormat: "Email is not in correct format",
    pleaseEnterEmail: "Please enter email",
    portfolio: "Portfolio",
    slideIntroducesTheIdea: "Slide introduces the idea",
    videoAlreadyExists: "Video already exists!",
    notAVideoLinkFromYoutubeOrVimeo: "Not a video link from Youtube or Vimeo!",
    imageUploadFailedPleaseReUpload: "Image upload failed, please re-upload!",
    legalStatementUploadFailedPleaseReUpload: "Legal statement upload failed, please re-upload!",
    copyrightStatementUploadFailedPleaseReUpload:
      "Copyright statement upload failed, please re-upload!",
    submitEntry: "Submit entry",
    seeDetailedContestRules: "See detailed contest rules",
    contestProfile: "Contest profile",
    "truyen-hinh": "Television",
    "truc-tuyen": "Online",
    "quang-cao-ngoai-troi": "Outdoor advertising",
    "grand-prix": "Grand Prix",
    communication: "Communication",
    "event-activations": "Event / Activations",
    "creative-concepts": "Creative Concepts",
    company: "Company",
    talents: "Talents",
    selectUpTo1TournamentToParticipateIn: "Select up to 1 tournament to participate in",
    deselectAll: "Deselect all",
    selectAll: "Select all",
    descriptionOfEntry: "Description of entry",
    maximum: "Maximum",
    characters: "characters",
    briefIntroduction: "Brief introduction",
    brandNameLabel: "Brand name / Name of nominated individual",
    campaignNameLabel: "Campaign title / Project name / Artwork name",
    implementationTime: "Implementation time",
    from: "From",
    to: "To",
    chooseMonth: "Choose month",
    chooseYear: "Choose year",
    theStartMonthMustBeBeforeTheEndMonth: "The start month must be before the end month!",
    theEndMonthMustBeAfterTheStartMonth: "The end month must be after the start month!",
    imageOfContestEntry: "Image of contest entry",
    uploadedPhotosMustNotExceed20mb: "Uploaded photos must not exceed 20MB",
    uploadImage: "Upload image",
    videoOfContestEntry: "Video of contest entry",
    enterVideoLink: "Enter video link",
    sendVideo: "Send video",
    uploadingVideo: "Uploading video",
    clear: "Clear",
    commitmentOfTheSubmittedWork: "Commitment of the submitted work",
    downloadTheLegalCommitment: "Download the Legal Commitment",
    downloadTheCommitmentToCopyrightOwnershipOfContestProducts:
      "Download the Commitment to copyright ownership of contest products",
    contestCode: "Contest code",
    introducingThePrize: "Introducing the prize",
    participationCategory: "Participation category",
    participationRules: "Participation rules",
    million: "million",
    prizeStructure: "Prize structure",
    registerForTheContest: "Register for the contest",
    participationList: "Participation list",
    examProfile: "Exam profile",
    individual: "Individual",
    enterprise: "Enterprise",
    fullName: "Full name",
    phoneNumber: "Phone number",
    address: "Address",
    companyName: "Company name",
    businessRegistrationNumber: "Business registration number",
    businessRegistrationLicense: "Business registration license",
    upload: "Upload",
    contactInformation: "Contact information",
    fullNameOfRepresentative: "Full name of representative",
    pleaseEnterYourCompanyName: "Please enter your company name!",
    pleaseEnterYourBusinessRegistrationNumber: "Please enter your business registration number!",
    pleaseUploadYourBusinessRegistrationCertificate:
      "Please upload your business registration certificate!",
    pleaseEnterYourRepresentativesFullName: `Please enter your representative's full name!`,
    pleaseEnterYourPhoneNumber: "Please enter your phone number!",
    pleaseEnterYourEmail: "Please enter your email!",
    pleaseEnterYourAddress: "Please enter your address!",
    phoneNumberIsNotInCorrectFormat: "Phone number is not in correct format!",
    pleaseEnterYourFullName: "Please enter your full name",
    finalJury: "Final Jury",
    jury: "Jury",
    searchForEntries: "Search for entries",
    seeMoreExams: "See more",
    back: "Back",
    "van-xuan-classic": "Van Xuan Classic",
    "van-xuan-new": "Van Xuan",
    contestEntryCode: "Contest entry code",
    campaignName: "Campaign's name",
    brandName: "Brand's name",
    description: "Description",
    timeOfExecution: "Implement time",
    enterJudgeCode: "Enter judge code",
    see: "See it",
    scoringOfEntries: "Scoring of entries",
    forJudges: "For judges",
    scoring: "Scoring",
    scoringEntry: "Scoring entry",
    scoringSuccessful: "Scoring successful!",
    scoreUpdatedSuccessfully: "Score updated successfully!",
    deletePointSuccessfully: "Delete point successfully!",
    youHaveScoredThisExam: "You have scored this exam",
    totalPoints: "Total points",
    editPoints: "Edit points",
    confirm: "Confirm",
    deletePoints: "Delete points",
    deleteExamPoints: "Delete exam points",
    sure: "Sure",
    cancel: "Cancel",
    searchForNews: "Search for news",
    noPosts: "No posts",
    contestAnnouncement: "Contest annpuncement",
    shareOn: "Share on",
    relatedNews: "Related news",
    allAwards: "All awards",
    awardCategories: "Award Categories",

    ...awards,
  },
}

export default en
