import { createSlice } from "@reduxjs/toolkit"

const submissionSlice = createSlice({
  name: "submission",
  initialState: {
    submissionList: [],
    page: 1,
    total: 0,
    categoryData: [],

    subCategoriesSelected: [],
    categorySelected: null,
    searchKey: "",
  },
  reducers: {
    setSubmissionList: (state, action) => {
      state.submissionList = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
    setCategoryData: (state, action) => {
      state.categoryData = action.payload
    },
    setSubCategoriesSelected: (state, action) => {
      state.subCategoriesSelected = action.payload
    },
    setCategorySelected: (state, action) => {
      state.categorySelected = action.payload
    },
    setSearchKey: (state, action) => {
      state.searchKey = action.payload
    },
  },
})

export const {
  reducer: submissionReducer,
  actions: {
    setSubmissionList,
    setPage,
    setTotal,
    setCategoryData,
    setSubCategoriesSelected,
    setCategorySelected,
    setSearchKey,
  },
} = submissionSlice
