const awards = {
  "chien-dich-tet-an-tuong-nhat": "Chiến dịch Tết ấn tượng nhất",
  "chien-dich-tai-dinh-vi-thuong-hieu-an-tuong": "Chiến dịch tái định vị thương hiệu ấn tượng",
  "chien-dich-influencers-an-tuong-nhat": "Chiến dịch Influencers ấn tượng nhất",
  "chien-dich-ra-mat-san-pham-an-tuong": "Chiến dịch ra mắt sản phẩm ấn tượng",
  "chien-dich-csr-an-tuong-nhat": "Chiến dịch CSR ấn tượng nhất",
  "chien-dich-promotion-xuat-sac-nhat": "Chiến dịch Promotion xuất sắc nhất",
  "su-kien-cua-nam": "Sự kiện của năm",
  "su-kien-tet-an-tuong-nhat": "Sự kiện Tết ấn tượng nhất",
  "su-kien-truyen-cam-hung-cua-nam": "Sự kiện truyền cảm hứng của năm",
  "hoat-dong-activation-cua-nam": "Hoạt động activation của năm",
  "tvc-noi-bat-cua-nam": "TVC nổi bật của năm",
  "key-visual-xuat-sac-nhat": "Key Visual xuất sắc nhất",
  "music-video-quang-cao-an-tuong": "Music Video quảng cáo ấn tượng",
  "independent-agency-cua-nam": "Independent Agency của năm",
  "network-agency-cua-nam": "Network Agency của năm",
  "digital-agency-cua-nam": "Digital Agency của năm",
  "pr-agency-cua-nam": "PR Agency của năm",
  "event-agency-cua-nam": "Event Agency của năm",
  "production-house-cua-nam": "Creative Production House của năm",
  "nen-tang-quang-cao-cua-nam": "Nền tảng quảng cáo của năm",
  "thuong-hieu-duoc-yeu-thich-nhat": "Thương hiệu sáng tạo được yêu thích nhất",
  "thuong-hieu-my-pham-lam-dep-cua-nam": "Thương hiệu mỹ phẩm & làm đẹp sáng tạo của năm",
  "thuong-hieu-bao-hiem-cua-nam": "Thương hiệu bảo hiểm sáng tạo của năm",
  "thuong-hieu-ngan-hang-cua-nam": "Thương hiệu ngân hàng sáng tạo của năm",
  "thuong-hieu-o-to-cua-nam": "Thương hiệu ô tô sáng tạo của năm",
  "thuong-hieu-du-lich-lu-hanh-cua-nam": "Thương hiệu du lịch - lữ hành sáng tạo của năm",
  "thuong-hieu-do-uong-co-con-cua-nam": "Thương hiệu đồ uống có cồn sáng tạo của năm",
  "thuong-hieu-do-uong-khong-con-cua-nam": "Thương hiệu đồ uống không cồn sáng tạo của năm",
  "thuong-hieu-dinh-duong-cua-nam": "Thương hiệu dinh dưỡng sáng tạo của năm",
  "thuong-hieu-gia-vi-cua-nam": "Thương hiệu gia vị sáng tạo của năm",
  "thuong-hieu-thuc-pham-cua-nam": "Thương hiệu thực phẩm sáng tạo của năm",
  "thuong-hieu-banh-keo-cua-nam": "Thương hiệu bánh kẹo sáng tạo của năm",
  "thuong-hieu-bat-dong-san-cua-nam": "Thương hiệu Bất động sản sáng tạo của năm",
  "strategist-cua-nam": "Strategist của năm",
  "account-cua-nam": "Account của năm",
  "creative-director-cua-nam": "Creative Director của năm",
  "dao-dien-cua-nam": "Đạo diễn của năm",
  "dop-cua-nam": "D.O.P của năm",
  "art-director-cua-nam": "Art Director của năm",
  "music-composer-cua-nam": "Music Composer của năm",
  "photographer-cua-nam": "Photographer của năm",
  "kol-noi-bat-cua-nam": "KOL nổi bật của năm",
  "nha-sang-tao-noi-dung-an-tuong": "Nhà sáng tạo nội dung ấn tượng",
  "social-seller-cua-nam": "Social Seller của năm",
  "dai-su-quang-cao-truyen-cam-hung": "Đại sứ Quảng cáo truyền cảm hứng",
  "dai-su-quang-cao-an-tuong-nhat": "Đại sứ Quảng cáo ấn tượng nhất",
  "dai-su-quang-cao-duoc-yeu-thich-nhat": "Đại sứ Quảng cáo được yêu thích nhất",
  "dai-su-quang-cao-nhi-duoc-yeu-thich-nhat": "Đại sứ quảng cáo nhí được yêu thích nhất",

  "quang-cao-tren-truyen-hinh": "Quảng cáo trên truyền hình",
  "quang-cao-tren-bang-bien-ngoai-troi": "Quảng cáo trên bảng, biển ngoài trời",
  "quang-cao-tren-bao-dien-tu-va-nen-tang-xa-hoi": "Quảng cáo trên báo điện tử và nền tảng xã hội",
  "quang-cao-truyen-thong-tich-hop": "Quảng cáo truyền thông tích hợp",
  "kich-ban-tvc-xuat-sac-nhat": "Kịch bản TVC xuất sắc nhất",
  "dao-dien-tvc-xuat-sac-nhat": "Đạo diễn TVC xuất sắc nhất",
  "giam-doc-sang-tao-xuat-sac-nhat": "Giám đốc sáng tạo xuất sắc nhất",
  "giam-doc-hinh-anh-xuat-sac-nhat": "Giám đốc hình ảnh xuất sắc nhất",
  "thong-diep-quang-cao-an-tuong": "Thông điệp quảng cáo ấn tượng",
  "copcept-thiet-ke-sang-tao-nhat": "Concept thiết kế sáng tạo nhất",
  "key-visua-xuat-sac-nhat": "Key Visual xuất sắc nhất",
  "thiet-ke-ooh-don-an-tuong": "Thiết kế OOH (đơn) ấn tượng",
  "thiet-ke-chuoi-ooh-an-tuong": "Thiết kế chuỗi OOH ấn tượng",
  "thong-diep-quang-cao-ooh-an-tuong": "Thông điệp quảng cáo OOH ấn tượng",
  "kich-ban-xuat-sac-nhat": "Kịch bản xuất sắc nhất",
  "dao-dien-xuat-sac-nhat": "Đạo diễn xuất sắc nhất",
  "thiet-ke-hinh-anh-an-tuong": "Thiết kế hình ảnh ấn tượng",
  "thong-diep-quang-cao-online-an-tuong": "Thông điệp quảng cáo (online) ấn tượng",
  "hoat-dong-nang-cao-hinh-anh-thuong-hieu-an-tuong":
    "Hoạt động nâng cao hình ảnh thương hiệu ấn tượng",
  "thuong-hieu-co-hoat-dong-xa-hoi-y-nghia-thuong-hieu-vi-cong-dong-xuat-sac-nhat":
    "Thương hiệu có hoạt động xã hội ý nghĩa / Thương hiệu vì cộng đồng xuất sắc nhất",
  "thuong-hieu-co-nhieu-hoat-dong-sang-tao-nhat-thuong-hieu-co-gia-tri-cao-nhat":
    "Thương hiệu có nhiều hoạt động sáng tạo nhất / Thương hiệu có giá trị cao nhất",
  "chien-dich-truyen-hinh-truyen-thanh-xuat-sac-nhat":
    "Chiến dịch truyền hình / truyền thanh xuất sắc nhất",
  "chien-dich-ooh-xuat-sac-nhat": "Chiến dịch OOH xuất sắc nhất",
  "chien-dich-mang-xa-hoi-xuat-sac-nhat":
    "Chiến dịch mạng xã hội xuất sắc nhất (Zalo/FB/IG/TikTok)",
  "hoat-dong-tuong-tac-an-tuong": "Hoạt động tương tác ấn tượng",
  "dai-su-quang-cao-an-tuong": "Đại sứ quảng cáo ấn tượng",
  "dai-su-quang-cao-yeu-thich": "Đại sứ quảng cáo được yêu thích",
}

const vi = {
  translation: {
    grade: "điểm",
    introduction: "Giới thiệu",
    vanXuanAward2024: "Vạn Xuân Awards 2024",
    vanXuanClassic2024: "Vạn Xuân Classic 2024",
    vanXuan2024: "Vạn Xuân 2024",
    vanXuanStars2024: "Vạn Xuân Stars 2024",
    companionExperts: "Chuyên gia đồng hành",
    newsEvents: "Tin tức / sự kiện",
    library: "Thư viện bài thi",
    winnerList: "Danh sách thắng giải",
    registration: "Đăng ký tham gia",
    submit: "Nộp bài thi",
    vietnamCreativeAdvertisingAward2023: "Giải thưởng quảng cáo sáng tạo Việt Nam 2023",
    vanXuan: "Vạn Xuân",
    vanXuanClassic: "Vạn Xuân Classic",
    VanXuan: "Vạn Xuân",
    VanXuanClassic: "Vạn Xuân Classic",
    vanXuanStars: "Vạn Xuân Stars",
    detail: "Chi tiết",
    timeForSubmissionOfEntries: "Thời gian nộp bài dự thi",
    theOnlineRegistrationPortalIsOpenFrom: "Cổng đăng ký trực tuyến đang mở từ ngày",
    day: "Ngày",
    hour: "Giờ",
    minute: "Phút",
    second: "Giây",
    viewTheEntryGallery: "Xem thư viện bài dự thi",
    directingAndOrganizingUnit: "Đơn vị chỉ đạo, tổ chức",
    implementationUnit: "Đơn vị thực hiện",
    awardCategory: "Hạng mục giải thưởng",
    contestTime: "Thời gian cuộc thi",
    announcingTheContest: "Công bố cuộc thi",
    screeningAndGradingExamEntries: "Sàng lọc & chấm bài dự thi",
    finalGalaAwardsCeremony: "Gala chung kết trao giải",
    directingOrganizingUnit: "Đơn vị chỉ đạo & tổ chức",
    companionUnit: "Đơn vị đồng hành",
    sponsorRegistration: "Đăng ký Nhà tài trợ",
    communicationsSupportUnit: "Đơn vị hỗ trợ truyền thông",
    competitionNews: "Tin tức cuộc thi",
    seeMoreNews: "Xem thêm tin tức",
    uploadFromComputer: "Tải lên từ máy tính",
    submitVideoUsingLink: "Gửi video bằng liên kết",
    uploadedVideosCannotExceed1gb: "Video tải lên không quá 1GB!",
    videoUploadFailedPleaseReUpload: "Tải video lên bị lỗi, vui lòng tải lại!",
    videoUploadSuccessful: "Tải video thành công!",
    uploadVideo: "Tải video lên",
    pleaseSelectCategory: "Vui lòng chọn hạng mục!",
    pleaseEnterBrandNameIndividualNameOfNominee: "Vui lòng nhập tên nhãn hàng / tên cá nhân đề cử!",
    exceededAllowedCharacters: "Vượt quá ký tự cho phép!",
    pleaseEnterEntryDescription: "Vui lòng nhập mô tả bài dự thi!",
    pleaseSelectStartMonth: "Vui lòng chọn tháng bắt đầu!",
    pleaseSelectStartYear: "Vui lòng chọn năm bắt đầu!",
    pleaseUploadEntryImages: "Vui lòng tải lên hình ảnh dự thi!",
    maximum12Images: "Tối đa 12 hình!",
    pleaseUploadAtLeast1VietnameseOrEnglishIdeaIntroductionArticle:
      "Vui lòng tải lên ít nhất 1 bài bài giới thiệu ý tưởng tiếng Việt hoặc tiếng Anh!",
    pleaseUploadLegalCommitment: "Vui lòng tải lên bản cam kết pháp lý!",
    pleaseUploadCopyrightOwnershipCommitment: "Vui lòng tải lên bản cam kết sở hữu bản quyền!",
    pleaseEnterEntryCode: "Vui lòng nhập mã dự thi!",
    pleaseEnterCompanyName: "Vui lòng nhập tên công ty!",
    pleaseEnterTaxCode: "Vui lòng nhập mã số thuế!",
    pleaseEnterValidNumber: "Vui lòng nhập số hợp lệ!",
    pleaseEnterCompanyAddress: "Vui lòng nhập địa chỉ công ty!",
    emailIsNotInCorrectFormat: "Email không đúng định dạng!",
    pleaseEnterEmail: "Vui lòng nhập email!",
    portfolio: "Portfolio",
    slideIntroducesTheIdea: "Slide giới thiệu về ý tưởng",
    videoAlreadyExists: "Video đã tồn tại!",
    notAVideoLinkFromYoutubeOrVimeo: "Không phải đường dẫn video từ Youtube or Vimeo!",
    imageUploadFailedPleaseReUpload: "Tải lên hình ảnh bị lỗi, vui lòng tải lại!",
    legalStatementUploadFailedPleaseReUpload:
      "Tải lên bản cam kết pháp lý bị lỗi, vui lòng tải lại!",
    copyrightStatementUploadFailedPleaseReUpload:
      "Tải lên bản cam kết sở hữu bản quyền bị lỗi, vui lòng tải lại!",
    submitEntry: "Gửi bài dự thi",
    seeDetailedContestRules: "Xem thể lệ cuộc thi chi tiết",
    contestProfile: "Hồ sơ bài dự thi",
    "truyen-hinh": "Truyền hình",
    "truc-tuyen": "Trực tuyến",
    "quang-cao-ngoai-troi": "Quảng cáo ngoài trời",
    "grand-prix": "Grand Prix",
    communication: "Truyền thông",
    "event-activations": "Sự kiện / Kích hoạt",
    "creative-concepts": "Ý tưởng sáng tạo",
    company: "Công ty",
    talents: "Tài năng",
    selectUpTo1TournamentToParticipateIn: "Chọn tối đa 1 giải tham gia",
    deselectAll: "Bỏ chọn tất cả",
    selectAll: "Chọn tất cả",
    descriptionOfEntry: "Mô tả bài dự thi",
    maximum: "Tối đa",
    characters: "Ký tự",
    briefIntroduction: "Giới thiệu sơ lược",
    brandNameLabel: "Tên nhãn hàng / Tên cá nhân đề cử",
    campaignNameLabel: "Tiêu đề chiến dịch / Tên dự án / Tên tác phẩm",
    implementationTime: "Thời gian thực hiện",
    from: "Từ",
    to: "Đến",
    chooseMonth: "Chọn tháng",
    chooseYear: "Chọn năm",
    theStartMonthMustBeBeforeTheEndMonth: "Tháng bắt đầu phải trước tháng kết thúc!",
    theEndMonthMustBeAfterTheStartMonth: "Tháng kết thúc phải sau tháng bắt đầu!",
    imageOfContestEntry: "Hình ảnh bài dự thi",
    uploadedPhotosMustNotExceed20mb: "Ảnh tải lên không quá 20MB",
    uploadImage: "Tải ảnh lên",
    videoOfContestEntry: "Video bài dự thi",
    enterVideoLink: "Nhập đường dẫn video",
    sendVideo: "Gửi video",
    uploadingVideo: "Đang tải video",
    clear: "Xoá",
    commitmentOfTheSubmittedWork: "Bản cam kết của tác phẩm dự thi",
    downloadTheLegalCommitment: "Tải xuống Bản cam kết pháp lý",
    downloadTheCommitmentToCopyrightOwnershipOfContestProducts:
      "Tải xuống Bản cam kết sở hữu bản quyền sản phẩm dự thi",
    contestCode: "Mã dự thi",
    introducingThePrize: "Giới thiệu giải thưởng",
    participationCategory: "Hạng mục tham gia",
    participationRules: "Thể lệ tham gia",
    million: "triệu",
    prizeStructure: "Cơ cấu giải thưởng",
    registerForTheContest: "Đăng ký dự thi",
    participationList: "Danh mục tham gia",
    examProfile: "Hồ sơ dự thi",
    individual: "Cá nhân",
    enterprise: "Doanh nghiệp",
    fullName: "Họ và tên",
    phoneNumber: "Số điện thoại",
    address: "Địa chỉ",
    companyName: "Tên công ty",
    businessRegistrationNumber: "Số đăng ký kinh doanh",
    businessRegistrationLicense: "Giấy phép đăng ký kinh doanh",
    upload: "Tải lên",
    contactInformation: "Thông tin liên hệ",
    fullNameOfRepresentative: "Họ và tên người đại diện",
    pleaseEnterYourCompanyName: "Vui lòng nhập tên công ty!",
    pleaseEnterYourBusinessRegistrationNumber: "Vui lòng nhập mã số đăng ký kinh doanh!",
    pleaseUploadYourBusinessRegistrationCertificate:
      "Vui lòng tải lên giấy phép đăng ký kinh doanh!",
    pleaseEnterYourRepresentativesFullName: "Vui lòng nhập họ tên người đại diện!",
    pleaseEnterYourPhoneNumber: "Vui lòng nhập số điện thoại!",
    pleaseEnterYourEmail: "Vui lòng nhập email!",
    pleaseEnterYourAddress: "Vui lòng nhập địa chỉ!",
    phoneNumberIsNotInCorrectFormat: "Số điện thoại không đúng định dạng!",
    pleaseEnterYourFullName: "Vui lòng nhập họ tên!",
    finalJury: "Ban giám khảo chung cuộc",
    jury: "Ban giám khảo",
    searchForEntries: "Tìm kiếm bài dự thi",
    seeMoreExams: "Xem thêm bài dự thi",
    back: "Quay lại",
    "van-xuan-classic": "Vạn Xuân Classic",
    "van-xuan-new": "Vạn Xuân",
    contestEntryCode: "Mã bài dự thi",
    campaignName: "Tên chiến dịch",
    brandName: "Tên nhãn hàng",
    description: "Mô tả",
    timeOfExecution: "Thời gian thực hiện",
    enterJudgeCode: "Nhập mã giám khảo",
    see: "Xem",
    scoringOfEntries: "Chấm điểm bài dự thi",
    forJudges: "Dành cho Giám khảo",
    scoring: "Chấm điểm",
    scoringEntry: "Chấm điểm bài dự thi",
    scoringSuccessful: "Chấm điểm thành công!",
    scoreUpdatedSuccessfully: "Cập nhật điểm thành công!",
    deletePointSuccessfully: "Xoá điểm thành công!",
    youHaveScoredThisExam: "Bạn đã chấm điểm bài thi này",
    totalPoints: "Tổng điểm",
    editPoints: "Chỉnh sửa điểm",
    confirm: "Xác nhận",
    deletePoints: "Xoá điểm",
    deleteExamPoints: "Xoá điểm bài dự thi",
    sure: "Chắc chắn",
    cancel: "Huỷ bỏ",
    searchForNews: "Tìm kiếm tin tức",
    noPosts: "hông có bài viết nào",
    contestAnnouncement: "Thông báo cuộc thi",
    shareOn: "Chia sẻ",
    relatedNews: "TIN TỨC LIÊN QUAN",
    allAwards: "Tất cả giải thưởng",
    awardCategories: "Danh mục giải thưởng",

    ...awards,
  },
}
export default vi
