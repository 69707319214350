import { createSlice } from "@reduxjs/toolkit"

const winningSlice = createSlice({
  name: "winning",
  initialState: {
    winningList: [],
    page: 1,
    total: 0,
    categoriesSelected: [],
  },
  reducers: {
    setWinningList: (state, action) => {
      state.winningList = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
    setCategoriesSelected: (state, action) => {
      state.categoriesSelected = action.payload
    },
  },
})

export const {
  reducer: winningReducer,
  actions: { setWinningList, setPage, setTotal, setCategoriesSelected },
} = winningSlice
