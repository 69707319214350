export const RELEASE_DAY = "2024-11-06"

export const CATEGORY_TYPE = {
  TRUYEN_HINH: "truyen-hinh",
  NGOAI_TROI: "ngoai-troi",
  TRUC_TUYEN: "truc-tuyen",
}

export const CATEGORY_TYPE_NAME = {
  [CATEGORY_TYPE.TRUYEN_HINH]: "Truyền hình / truyền thanh",
  [CATEGORY_TYPE.NGOAI_TROI]: "Ngoài trời (OOH)",
  [CATEGORY_TYPE.TRUC_TUYEN]: "Trực tuyến (Online)",
}

export const EXAMINER_ROLE = {
  CHUYEN_GIA_DONG_HANH: "chuyen-gia-dong-hanh",
  GIAM_KHAO_BINH_CHON: "giam-khao-binh-chon",
  GIAM_KHAO_CHUNG_CUOC: "giam-khao-chung-cuoc",
}

export const SCORE_CATEGORIES = {
  "truyen-hinh": [
    {
      title: {
        en: "Section 1: Unique, Creative, and Impressive Idea (15 grade)",
        vi: "Mục 1: Ý tưởng sáng tạo mới lạ, độc đáo, ấn tượng (15 điểm)",
      },
      description: [
        {
          en: "- The advertisement grabs attention right from the start due to its uniqueness and impressiveness.",
          vi: "- Quảng cáo gây chú ý ngay ban đầu bởi sự độc đáo và ấn tượng.",
        },
        {
          en: "- The idea does not duplicate previous advertisements.",
          vi: "- Ý tưởng không trùng lặp với những quảng cáo trước đó.",
        },
        {
          en: "- It creates a deep connection with the audience.",
          vi: "- Tạo sự sâu sắc đối với người tiếp cận sản phẩm quảng cáo.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 2: Clear Message Conveyance (15 grade)",
        vi: "Mục 2: Chuyển tải thông điệp rõ ràng (15 điểm)",
      },
      description: [
        {
          en: "- The message is clearly communicated to the audience.",
          vi: "-  Chuyển tải thông điệp rõ ràng đến người tiếp cận sản phẩm quảng cáo.",
        },
        {
          en: "- The message is easy to understand and remember.",
          vi: "- Thông điệp dễ hiểu và dễ nhớ.",
        },
        {
          en: `- The message aligns with the brand's direction.`,
          vi: "- Thông điệp bám sát với định hướng của thương hiệu.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 3: Representation of Vietnamese Culture, Traditions, and Ethics (20 grade)",
        vi: "Mục 3: Thể hiện yếu tố văn hóa, thuần phong mỹ tục, đạo đức con người Việt Nam (20 điểm)",
      },
      points: 20,
    },
    {
      title: {
        en: "Section 4: Demonstrates Responsibility to the Environment and Community (15 grade)",
        vi: "Mục 4: Thể hiện trách nhiệm đối với môi trường và cộng đồng (15 điểm)",
      },
      points: 15,
    },
    {
      title: {
        en: "Section 5: Highlights and Reinforces Product and Brand Image (20 grade)",
        vi: "Mục 5: Làm nổi bật và gợi nhớ hình ảnh sản phẩm và thương hiệu (20 điểm)",
      },
      description: [
        {
          en: "- Clearly represents the brand image.",
          vi: "- Thể hiện rõ ràng hình ảnh thương hiệu.",
        },
        {
          en: "- Clearly showcases the product being promoted.",
          vi: "- Thể hiện rõ sản phẩm muốn thuyết phục.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 6: Technical Quality (15 grade)",
        vi: "Mục 6: Chất lượng kỹ thuật (15 điểm)",
      },
      description: [
        {
          en: "- High-quality visuals and sound.",
          vi: "- Chất lượng cao về hình ảnh, âm thanh.",
        },
        {
          en: "- Creates a strong sensory effect.",
          vi: "- Gây hiệu ứng cảm quan tốt.",
        },
      ],
      points: 15,
    },
  ],
  "truc-tuyen": [
    {
      title: {
        en: "Section 1: Unique, Creative, and Impressive Idea (15 grade)",
        vi: "Mục 1: Ý tưởng sáng tạo mới lạ, độc đáo, ấn tượng (15 điểm)",
      },
      description: [
        {
          en: "- The advertisement grabs attention right from the start due to its uniqueness and impressiveness.",
          vi: "- Quảng cáo gây chú ý ngay ban đầu bởi sự độc đáo và ấn tượng.",
        },
        {
          en: "- The idea does not duplicate previous advertisements.",
          vi: "- Ý tưởng không trùng lặp với những quảng cáo trước đó.",
        },
        {
          en: "- It creates a deep connection with the audience.",
          vi: "- Tạo sự sâu sắc đối với người tiếp cận sản phẩm quảng cáo.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 2: Clear Message Conveyance (15 grade)",
        vi: "Mục 2: Chuyển tải thông điệp rõ ràng (15 điểm)",
      },
      description: [
        {
          en: "- The message is clearly communicated to the audience.",
          vi: "-  Chuyển tải thông điệp rõ ràng đến người tiếp cận sản phẩm quảng cáo.",
        },
        {
          en: "- The message is easy to understand and remember.",
          vi: "- Thông điệp dễ hiểu và dễ nhớ.",
        },
        {
          en: `- The message aligns with the brand's direction.`,
          vi: "- Thông điệp bám sát với định hướng của thương hiệu.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 3: Representation of Vietnamese Culture, Traditions, and Ethics (20 grade)",
        vi: "Mục 3: Thể hiện yếu tố văn hóa, thuần phong mỹ tục, đạo đức con người Việt Nam (20 điểm)",
      },
      points: 20,
    },
    {
      title: {
        en: "Section 4: Demonstrates Responsibility to the Environment and Community (15 grade)",
        vi: "Mục 4: Thể hiện trách nhiệm đối với môi trường và cộng đồng (15 điểm)",
      },
      points: 15,
    },
    {
      title: {
        en: "Section 5: Highlights and Reinforces Product and Brand Image (20 grade)",
        vi: "Mục 5: Làm nổi bật và gợi nhớ hình ảnh sản phẩm và thương hiệu (20 điểm)",
      },
      description: [
        {
          en: "- Clearly represents the brand image.",
          vi: "- Thể hiện rõ ràng hình ảnh thương hiệu.",
        },
        {
          en: "- Clearly showcases the product being promoted.",
          vi: "- Thể hiện rõ sản phẩm muốn thuyết phục.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 6: Technical Quality (15 grade)",
        vi: "Mục 6: Chất lượng kỹ thuật (15 điểm)",
      },
      description: [
        {
          en: "- High-quality visuals and sound.",
          vi: "- Chất lượng cao về hình ảnh, âm thanh.",
        },
        {
          en: "- Creates a strong sensory effect.",
          vi: "- Gây hiệu ứng cảm quan tốt.",
        },
      ],
      points: 15,
    },
  ],
  "quang-cao-ngoai-troi": [
    {
      title: {
        en: "Section 1: Unique, Creative, and Impressive Idea (15 grade)",
        vi: "Mục 1: Ý tưởng sáng tạo mới lạ, độc đáo, ấn tượng (15 điểm)",
      },
      description: [
        {
          en: "- The advertisement grabs attention right from the start due to its uniqueness and impressiveness.",
          vi: "- Quảng cáo gây chú ý ngay ban đầu bởi sự độc đáo và ấn tượng.",
        },
        {
          en: "- The idea does not duplicate previous advertisements.",
          vi: "- Ý tưởng không trùng lặp với những quảng cáo trước đó.",
        },
        {
          en: "- It creates a deep connection with the audience.",
          vi: "- Tạo sự sâu sắc đối với người tiếp cận sản phẩm quảng cáo.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 2: Clear Message Conveyance (15 grade)",
        vi: "Mục 2: Chuyển tải thông điệp rõ ràng (15 điểm)",
      },
      description: [
        {
          en: "- The message is clearly communicated to the audience.",
          vi: "- Chuyển tải thông điệp rõ ràng đến người tiếp cận sản phẩm quảng cáo.",
        },
        {
          en: "- The message is easy to understand and remember.",
          vi: "- Thông điệp dễ hiểu và dễ nhớ.",
        },
        {
          en: `- The message aligns with the brand's direction.`,
          vi: "- Thông điệp bám sát với định hướng của thương hiệu.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 3: Representation of Vietnamese Culture, Traditions, and Ethics (20 grade)",
        vi: "Mục 3: Thể hiện yếu tố văn hóa, thuần phong mỹ tục, đạo đức con người Việt Nam (20 điểm)",
      },
      points: 20,
    },
    {
      title: {
        en: "Section 4: Demonstrates Responsibility to the Environment and Community (15 grade)",
        vi: "Mục 4: Thể hiện trách nhiệm đối với môi trường và cộng đồng (15 điểm)",
      },
      points: 15,
    },
    {
      title: {
        en: "Section 5: Highlights and Reinforces Product and Brand Image (20 grade)",
        vi: "Mục 5: Làm nổi bật và gợi nhớ hình ảnh sản phẩm và thương hiệu (20 điểm)",
      },
      description: [
        {
          en: "- Clearly represents the brand image.",
          vi: "- Thể hiện rõ ràng hình ảnh thương hiệu.",
        },
        {
          en: "- Clearly showcases the product being promoted.",
          vi: "- Thể hiện rõ sản phẩm muốn thuyết phục.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 6: Technical Quality (15 grade)",
        vi: "Mục 6: Chất lượng kỹ thuật (15 điểm)",
      },
      description: [
        {
          en: "- High-quality visuals and sound.",
          vi: "- Chất lượng cao về hình ảnh, âm thanh.",
        },
        {
          en: "- Creates a strong sensory effect.",
          vi: "- Gây hiệu ứng cảm quan tốt.",
        },
      ],
      points: 15,
    },
  ],
  "chien-dich-tet-an-tuong-nhat": [
    {
      title: {
        en: "Section 1: Objectives and Challenges (10 grade)",
        vi: "Mục 1: Mục tiêu và thách thức (10 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the campaign including clearly defined and measurable business objectives.  (KPI's)`,
          vi: `- Cung cấp cái nhìn tổng quan về chiến dịch bao gồm các mục tiêu kinh doanh được xác định rõ ràng và có thể đo lường được. (KPI's)`,
        },
        {
          en: `- What challenges were faced in carrying out the campaign if any, and how were they successfully overcome?`,
          vi: "- Những thách thức nào gặp phải khi thực hiện chiến dịch nếu có và họ đã vượt qua thành công như thế nào?",
        },
        {
          en: `- Provide a % breakdown of budgets (indicating currency/value) to demonstrate the campaigns' cost-effectiveness and key statistics to describe the scale of the campaign are essential.`,
          vi: `- Cung cấp tỷ lệ phần trăm ngân sách (cho biết đơn vị tiền tệ/giá trị) để chứng minh tính hiệu quả về chi phí của chiến dịch và số liệu thống kê chính để mô tả quy mô của chiến dịch là điều cần thiết.`,
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 2: Concept and Strategy (15 grade)",
        vi: "Mục 2: Khái niệm và chiến lược (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign demonstrates a cohesive, multi-channel, or single-channel strategy that aligned with the brand’s overall marketing objectives.",
          vi: "- Mô tả cách chiến dịch thể hiện chiến lược gắn kết, đa kênh hoặc đơn kênh phù hợp với mục tiêu tiếp thị tổng thể của thương hiệu.",
        },
        {
          en: "- Describe any unique research and insightful approach to the overall issue and target audience supported with evidence.",
          vi: "- Mô tả bất kỳ nghiên cứu độc đáo nào và cách tiếp cận sâu sắc đối với vấn đề tổng thể và đối tượng mục tiêu được hỗ trợ bằng bằng chứng.",
        },
        {
          en: `- Describe the campaign's overarching plan about the broader business needs.Explain how the tactics contribute to the overall strategic effectiveness and success.`,
          vi: "- Mô tả kế hoạch tổng thể của chiến dịch về nhu cầu kinh doanh rộng hơn. Giải thích cách các chiến thuật góp phần vào hiệu quả và thành công chiến lược tổng thể.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 3: Creativity and Innovation (30 grade)",
        vi: "Mục 3: Sáng tạo và đổi mới (30 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign demonstrates a unique, creative, and innovative approach that stands out from typical advertising. Explain the idea that led to the creative concept and its implementation.",
          vi: "- Mô tả cách chiến dịch thể hiện cách tiếp cận độc đáo, sáng tạo và đổi mới, nổi bật so với quảng cáo thông thường. Giải thích ý tưởng dẫn đến ý tưởng sáng tạo và cách thực hiện ý tưởng đó.",
        },
        {
          en: "- Describe how the campaign pushes the boundaries of the medium and challenges industry norms and how it captures attention through innovative storytelling, visuals, messaging, and use of media.",
          vi: "- Mô tả cách chiến dịch vượt qua các ranh giới của phương tiện truyền thông và thách thức các chuẩn mực của ngành cũng như cách chiến dịch thu hút sự chú ý thông qua cách kể chuyện, hình ảnh, thông điệp và cách sử dụng phương tiện truyền thông đổi mới.",
        },
        {
          en: "- Describe how the campaign aims for technical excellence and showcases flawless, high-quality execution across all creative assets and touchpoints.",
          vi: "- Mô tả cách chiến dịch hướng đến sự xuất sắc về mặt kỹ thuật và thể hiện khả năng thực thi hoàn hảo, chất lượng cao trên tất cả nội dung quảng cáo và điểm tiếp xúc.",
        },
        {
          en: "- Include details of the unique challenges faced by the campaign and explain how they were successfully overcome.",
          vi: "- Bao gồm chi tiết về những thách thức đặc biệt mà chiến dịch phải đối mặt và giải thích cách vượt qua chúng thành công.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 4: Culture (10 grade)",
        vi: "Mục 4: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- The campaign brings an inspirational message, spreading good cultural values and the spirit of Vietnamese Tet, contributing to creating a positive impact on the community.",
          vi: "- Chiến dịch mang đến thông điệp truyền cảm hứng, lan tỏa những giá trị văn hóa tốt đẹp và tinh thần Tết Việt, góp phần tạo tác động tích cực đến cộng đồng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Effectiveness and Impact (20 grade)",
        vi: "Mục 5: Hiệu quả và tác động (20 điểm)",
      },
      description: [
        {
          en: "- Describe the effectiveness and impact of the campaign including quantifiable results such as increased brand awareness, web traffic, engagement metrics, sales lift, etc.",
          vi: "- Mô tả hiệu quả và tác động của chiến dịch, bao gồm các kết quả có thể định lượng như tăng nhận thức về thương hiệu, lưu lượng truy cập web, số liệu tương tác, mức tăng doanh số bán hàng, v.v.",
        },
        {
          en: "- Provide a clear, measurable impact on the business including data-driven proof of the campaign’s success.",
          vi: "- Cung cấp tác động rõ ràng, có thể đo lường được đối với doanh nghiệp, bao gồm bằng chứng dựa trên dữ liệu về sự thành công của chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 6:  Industry Recognition (15 grade)",
        vi: "Mục 6: Sự công nhận của ngành (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign affects the industry and which partners were a part of the campaign from your team, this includes any 3rd parties that helped work on the project.",
          vi: "- Mô tả chiến dịch ảnh hưởng như thế nào đến ngành và đối tác nào là một phần của chiến dịch trong nhóm của bạn, bao gồm bất kỳ bên thứ 3 nào đã giúp thực hiện dự án.",
        },
      ],
      points: 15,
    },
  ],
  "chien-dich-ra-mat-san-pham-an-tuong": [
    {
      title: {
        en: "Section 1: Objectives and Challenges (10 grade)",
        vi: "Mục 1: Mục tiêu và thách thức (10 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of the campaign including clearly defined and measurable business objectives. (KPIs)",
          vi: "- Cung cấp cái nhìn tổng quan về chiến dịch bao gồm các mục tiêu kinh doanh được xác định rõ ràng và có thể đo lường được. (KPI)",
        },
        {
          en: "- What challenges were faced in carrying out the campaign, and how were they successfully overcome?",
          vi: "- Những thách thức nào đã gặp phải khi thực hiện chiến dịch và họ đã vượt qua thành công như thế nào?",
        },
        {
          en: "- Provide a % breakdown of budgets (indicating currency) to demonstrate the campaigns’ cost-effectiveness and key statistics to describe the scale of the campaign are essential.",
          vi: "- Cung cấp tỷ lệ phần trăm ngân sách (cho biết đơn vị tiền tệ) để chứng minh tính hiệu quả về mặt chi phí của chiến dịch và số liệu thống kê chính để mô tả quy mô của chiến dịch là điều cần thiết.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 2: Concept and Strategy (15 grade)",
        vi: "Mục 2: Khái niệm và chiến lược (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign demonstrates a cohesive, multi-channel strategy that aligned with the brand’s overall marketing objectives.",
          vi: "- Mô tả cách chiến dịch thể hiện chiến lược đa kênh, gắn kết phù hợp với mục tiêu tiếp thị tổng thể của thương hiệu.",
        },
        {
          en: "- Describe any unique research and insightful approach to the overall issue and target audience supported with evidence.",
          vi: "- Mô tả bất kỳ nghiên cứu độc đáo nào và cách tiếp cận sâu sắc đối với vấn đề tổng thể và đối tượng mục tiêu được hỗ trợ bằng bằng chứng.",
        },
        {
          en: `- Describe the campaign's overarching plan about the broader business needs.Explain how the tactics contribute to the overall strategic effectiveness and success.`,
          vi: "- Mô tả kế hoạch tổng thể của chiến dịch về nhu cầu kinh doanh rộng hơn.  Giải thích cách các chiến thuật đóng góp vào hiệu quả và thành công chiến lược tổng thể.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 3: Creativity and Innovation (30 grade)",
        vi: "Mục 3: Sáng tạo và đổi mới (30 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign demonstrates a unique, creative, and innovative approach that stands out from typical advertising. Explain the idea that led to the creative concept and its implementation.",
          vi: "- Mô tả cách chiến dịch thể hiện cách tiếp cận độc đáo, sáng tạo và đổi mới, nổi bật so với quảng cáo thông thường. Giải thích ý tưởng dẫn đến ý tưởng sáng tạo và cách thực hiện ý tưởng đó.",
        },
        {
          en: "- Describe how the campaign pushes the boundaries of the medium and challenges industry norms and how it captures attention through innovative storytelling, visuals, messaging, and use of media.",
          vi: "- Mô tả cách chiến dịch vượt qua các ranh giới của phương tiện truyền thông và thách thức các chuẩn mực của ngành cũng như cách chiến dịch thu hút sự chú ý thông qua cách kể chuyện, hình ảnh, thông điệp và cách sử dụng phương tiện truyền thông đổi mới.",
        },
        {
          en: "- Describe how the campaign aims for technical excellence and showcases flawless, high-quality execution across all creative assets and touchpoints.",
          vi: "- Mô tả cách chiến dịch hướng đến sự xuất sắc về mặt kỹ thuật và thể hiện khả năng thực thi hoàn hảo, chất lượng cao trên tất cả nội dung quảng cáo và điểm tiếp xúc.",
        },
        {
          en: "- Include details of the unique challenges faced by the campaign and explain how they were successfully overcome.",
          vi: "- Bao gồm chi tiết về những thách thức đặc biệt mà chiến dịch phải đối mặt và giải thích cách vượt qua chúng thành công.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 4: Culture (10 grade)",
        vi: "Mục 4: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- The campaign brings an inspirational message, spreading good cultural values, contributing to creating a positive impact on the community.",
          vi: "- Chiến dịch mang đến thông điệp truyền cảm hứng, lan tỏa những giá trị văn hóa tốt đẹp, góp phần tạo tác động tích cực đến cộng đồng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Effectiveness and Impact (20 grade)",
        vi: "Mục 5: Hiệu quả và tác động (20 điểm)",
      },
      description: [
        {
          en: "- Describe the effectiveness and impact of the campaign including quantifiable results such as increased brand awareness, web traffic, engagement metrics, sales lift, etc.",
          vi: "- Mô tả tính hiệu quả và tác động của chiến dịch bao gồm các kết quả có thể định lượng như tăng nhận thức về thương hiệu, lưu lượng truy cập vào trang web, số liệu tương tác, mức tăng doanh số bán hàng, v.v.",
        },
        {
          en: "- Provide a clear, measurable impact on the business including data-driven proof of the campaign’s success.",
          vi: "- Cung cấp tác động rõ ràng, có thể đo lường được đối với hoạt động kinh doanh, bao gồm bằng chứng dựa trên dữ liệu về sự thành công của chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 6:  Industry Recognition (15 grade)",
        vi: "Mục 6: Sự công nhận của ngành (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign affects the industry.",
          vi: "- Mô tả chiến dịch ảnh hưởng đến ngành như thế nào.",
        },
      ],
      points: 15,
    },
  ],
  "chien-dich-tai-dinh-vi-thuong-hieu-an-tuong": [
    {
      title: {
        en: "Section 1: Objectives and Challenges (10 grade)",
        vi: "Mục 1: Mục tiêu và thách thức (10 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of the campaign including clearly defined and measurable business objectives.",
          vi: "- Cung cấp cái nhìn tổng quan về chiến dịch bao gồm các mục tiêu kinh doanh được xác định rõ ràng và có thể đo lường được.",
        },
        {
          en: "- What challenges were faced in carrying out the campaign, and how were they successfully overcome?",
          vi: "- Những thách thức nào đã gặp phải khi thực hiện chiến dịch và họ đã vượt qua thành công như thế nào?",
        },
        {
          en: "- Provide a breakdown of budgets (indicating currency) to demonstrate the campaigns’ cost-effectiveness and key statistics to describe the scale of the campaign are essential.",
          vi: "- Cung cấp bảng phân tích ngân sách (cho biết loại tiền tệ) để chứng minh tính hiệu quả về mặt chi phí của chiến dịch và số liệu thống kê chính để mô tả quy mô của chiến dịch là điều cần thiết.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Concept and Strategy (30 grade)",
        vi: "Mục 2: Khái niệm và chiến lược (30 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign demonstrates a cohesive, multi-channel strategy that aligned with the brand’s overall marketing objectives.",
          vi: "- Mô tả cách chiến dịch thể hiện chiến lược đa kênh, gắn kết phù hợp với mục tiêu tiếp thị tổng thể của thương hiệu.",
        },
        {
          en: "- Describe any unique research and insightful approach to the overall issue and target audience supported with evidence.",
          vi: "- Mô tả bất kỳ nghiên cứu độc đáo nào và cách tiếp cận sâu sắc đối với vấn đề tổng thể và đối tượng mục tiêu được hỗ trợ bằng bằng chứng.",
        },
        {
          en: `- Describe the campaign's overarching plan about the broader business needs.Explain how the tactics contribute to the overall strategic effectiveness and success.`,
          vi: "- Mô tả kế hoạch tổng thể của chiến dịch về nhu cầu kinh doanh rộng hơn. Giải thích cách các chiến thuật góp phần vào hiệu quả và thành công chiến lược tổng thể.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 3: Creativity and Innovation (30 grade)",
        vi: "Mục 3: Sáng tạo và đổi mới(30 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign demonstrates a cohesive, multi-channel strategy that aligned with the brand’s overall marketing objectives.",
          vi: "- Mô tả cách chiến dịch thể hiện cách tiếp cận độc đáo, sáng tạo và đổi mới, nổi bật so với quảng cáo thông thường. Giải thích ý tưởng dẫn đến ý tưởng sáng tạo và cách thực hiện ý tưởng đó.",
        },
        {
          en: "- Describe any unique research and insightful approach to the overall issue and target audience supported with evidence.",
          vi: "- Mô tả cách chiến dịch vượt qua các ranh giới của phương tiện truyền thông và thách thức các chuẩn mực của ngành cũng như cách chiến dịch thu hút sự chú ý thông qua cách kể chuyện, hình ảnh, thông điệp và cách sử dụng phương tiện truyền thông đổi mới.",
        },
        {
          en: `- Describe the campaign's overarching plan about the broader business needs.Explain how the tactics contribute to the overall strategic effectiveness and success.`,
          vi: "- Mô tả cách chiến dịch hướng đến sự xuất sắc về mặt kỹ thuật và thể hiện khả năng thực thi hoàn hảo, chất lượng cao trên tất cả nội dung quảng cáo và điểm tiếp xúc.",
        },
        {
          en: `- Describe the campaign's overarching plan about the broader business needs.Explain how the tactics contribute to the overall strategic effectiveness and success.`,
          vi: "- Bao gồm chi tiết về những thách thức đặc biệt mà chiến dịch phải đối mặt và giải thích cách vượt qua chúng thành công.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 4: Culture (10 grade)",
        vi: "Mục 4: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- The campaign brings an inspirational message, spreading good cultural values, contributing to creating a positive impact on the community.",
          vi: "- Chiến dịch mang đến thông điệp truyền cảm hứng, lan tỏa những giá trị văn hóa tốt đẹp, góp phần tạo tác động tích cực đến cộng đồng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Effectiveness and Impact (10 grade)",
        vi: "Mục 5: Hiệu quả và tác động (10 điểm)",
      },
      description: [
        {
          en: "- Describe the effectiveness and impact of the campaign including quantifiable results such as increased brand awareness, brand position after the repositioning campaign, web traffic, engagement metrics, sales lift, etc.",
          vi: "- Mô tả hiệu quả và tác động của chiến dịch bao gồm các kết quả có thể định lượng được như tăng nhận thức về thương hiệu, định vị thương hiệu sau chiến dịch tái định vị, lưu lượng truy cập vào trang web, số liệu tương tác, mức tăng doanh số bán hàng, v.v",
        },
        {
          en: "- Describe the effectiveness of the communication strategy in attracting attention and conveying the brand repositioning message.",
          vi: "- Mô tả tính hiệu quả của chiến lược truyền thông trong việc thu hút sự chú ý và truyền tải thông điệp thông điệp tái định vị thương hiệu.",
        },
        {
          en: "- Provide a clear, measurable impact on the business including data-driven proof of the campaign’s success.",
          vi: "- Cung cấp tác động rõ ràng, có thể đo lường được đối với hoạt động kinh doanh, bao gồm bằng chứng dựa trên dữ liệu về sự thành công của chiến dịch.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 6:  Industry Recognition (10 grade)",
        vi: "Mục 6: Sự công nhận của ngành (10 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign affects the industry.",
          vi: "- Mô tả chiến dịch ảnh hưởng đến ngành như thế nào.",
        },
      ],
      points: 10,
    },
  ],
  "chien-dich-csr-an-tuong-nhat": [
    {
      title: {
        en: "Section 1: Background (5 grade)",
        vi: "Mục 1: Bối cảnh (5 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of the brand’s historic approach to CSR over the last three years to build a bit of context around the actual entry.",
          vi: "- Cung cấp một cái nhìn tổng quan về cách tiếp cận lịch sử của doanh nghiệp đối với CSR trong ba năm qua để xây dựng một chút bối cảnh xung quanh việc gia nhập thực tế.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 2: Objectives & Strategy (15 grade)",
        vi: "Mục 2: Mục tiêu & Chiến lược (15 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of the objectives set and how they align with the brand’s vision, purpose, and values on CSR.",
          vi: "- Cung cấp cái nhìn tổng quan về các mục tiêu đã đặt ra và cách chúng phù hợp với tầm nhìn, mục đích và giá trị của doanh nghiệp về CSR.",
        },
        {
          en: `- Describe the brand's strategy and approach for achieving its objectives.Include information on how these approaches and projects are measured for success, such as KPIs for CSR reporting, diversity and inclusion measures, and employee engagement.`,
          vi: "- Mô tả chiến lược và cách tiếp cận của doanh nghiệp để đạt được mục tiêu. Bao gồm thông tin về cách đo lường sự thành công của các phương pháp và dự án này, chẳng hạn như KPI cho báo cáo CSR, các biện pháp đa dạng và hòa nhập cũng như sự tham gia của nhân viên.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 3: Innovation & Sustainability (25 grade)",
        vi: "Mục 3: Đổi mới và bền vững (25 điểm)",
      },
      description: [
        {
          en: `- Showcase the brand's distinctive approach to corporate social responsibility and tackling social and environmental issues . Examples may involve utilizing state - of - the - art technology, collaborating across sectors, or implementing innovative business models to drive positive impact.`,
          vi: "- Thể hiện cách tiếp cận đặc biệt của doanh nghiệp đối với trách nhiệm xã hội của doanh nghiệp và giải quyết các vấn đề xã hội và môi trường. Các ví dụ có thể liên quan đến việc sử dụng công nghệ tiên tiến, hợp tác giữa các lĩnh vực hoặc triển khai các mô hình kinh doanh đổi mới để mang lại tác động tích cực.",
        },
        {
          en: `- Outline the brand's commitment to sustainability and CSR efforts . Provide evidence such as stakeholder and employee engagement programs, sustainable procurement methods, etc.`,
          vi: "- Nêu rõ cam kết của doanh nghiệp đối với các nỗ lực bền vững và CSR. Cung cấp bằng chứng như các chương trình gắn kết nhân viên và các bên liên quan, phương pháp mua sắm bền vững, v.v.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4:  Social Responsibility and Impact (25 grade)",
        vi: "Mục 4: Trách nhiệm và tác động xã hội (25 điểm)",
      },
      description: [
        {
          en: `- Describe the company's commitment to diversity, equity, and inclusion in its workforce and leadership as well as its involvement in community outreach, philanthropic initiatives, and employee volunteering programs.`,
          vi: "- Mô tả cam kết của công ty đối với sự đa dạng, công bằng và sự hòa nhập vào lực lượng lao động và lãnh đạo cũng như sự tham gia của công ty vào hoạt động tiếp cận cộng đồng, các sáng kiến từ thiện và các chương trình tình nguyện của nhân viên.",
        },
        {
          en: `- Explain the use of sustainable techniques, such as renewable energy, recycling, and resource conservation. How are these efforts measured, and what results have been achieved to reflect the brand's initial objectives and values?`,
          vi: "- Giải thích việc sử dụng các kỹ thuật bền vững như năng lượng tái tạo, tái chế và bảo tồn tài nguyên. Những nỗ lực này được đo lường như thế nào và kết quả nào đã đạt được để phản ánh các mục tiêu và giá trị ban đầu của doanh nghiệp?",
        },
        {
          en: `- Provide examples that illustrate the brand's CSR actions towards society while also creating a positive brand image in the sector.`,
          vi: "- Cung cấp các ví dụ minh họa các hành động CSR của doanh nghiệp đối với xã hội đồng thời tạo ra hình ảnh thương hiệu tích cực trong lĩnh vực này.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 5: Leadership and Employee Engagement (20 grade)",
        vi: "Mục 5: Lãnh đạo và sự gắn kết của nhân viên (20 điểm)",
      },
      description: [
        {
          en: "- Explain how the brand has demonstrated exemplary leadership and governance in operations, culture, and stakeholder engagement.",
          vi: "- Giải thích cách doanh nghiệp đã thể hiện sự lãnh đạo và quản trị mẫu mực trong hoạt động, văn hóa và sự tham gia của các bên liên quan.",
        },
        {
          en: `- Describe the company's efforts to create a positive and productive work environment and involve employees in CSR initiatives.  How has this altered your brand's culture or impacted employee retention/engagement?`,
          vi: "-  Mô tả những nỗ lực của công ty trong việc tạo ra một môi trường làm việc tích cực và hiệu quả cũng như thu hút nhân viên tham gia vào các sáng kiến CSR. Điều này đã thay đổi văn hóa doanh nghiệp của bạn hoặc ảnh hưởng đến việc giữ chân/mức độ gắn kết của nhân viên như thế nào?",
        },
        {
          en: "- Provide examples and evidence of success.",
          vi: "- Cung cấp các ví dụ và bằng chứng về sự thành công.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 6: Looking Ahead (5 grade)",
        vi: "Mục 6: Nhìn về phía trước (5 điểm)",
      },
      description: [
        {
          en: "- How do you plan to develop your strategy? What new goals are you aiming to achieve and how are you adjusting your strategy accordingly?",
          vi: "- Bạn dự định phát triển chiến lược của mình như thế nào? Bạn đang muốn đạt được những mục tiêu mới nào và bạn đang điều chỉnh chiến lược của mình như thế nào cho phù hợp?",
        },
        {
          en: `- Provide a summary of the brand's commitment to continuously evaluating and improving its CSR initiatives.How do you adapt and respond to evolving social and environmental needs.`,
          vi: "- Cung cấp bản tóm tắt về cam kết của doanh nghiệp trong việc liên tục đánh giá và cải thiện các sáng kiến CSR của mình. Làm thế nào để bạn thích ứng và đáp ứng các nhu cầu xã hội và môi trường đang phát triển.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 7: Industry Contribution (5 grade)",
        vi: "Mục 7: Đóng góp của ngành (5 điểm)",
      },
      description: [
        {
          en: "- Describe and provide examples of initiatives and programs that the brand supported to enhance its overall image. Include examples of any community activities in which the brand has been involved.",
          vi: "- Mô tả và cung cấp ví dụ về các sáng kiến và chương trình mà doanh nghiệp đã hỗ trợ để nâng cao hình ảnh tổng thể của mình. Bao gồm các ví dụ về bất kỳ hoạt động cộng đồng nào mà doanh nghiệp đã tham gia.",
        },
      ],
      points: 5,
    },
  ],
  "chien-dich-influencers-an-tuong-nhat": [
    {
      title: {
        en: "Section 1: Objectives and Challenges (10 grade)",
        vi: "Mục 1: Mục tiêu và thách thức (10 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of the campaign including clearly defined and measurable business objectives.",
          vi: "- Cung cấp cái nhìn tổng quan về chiến dịch bao gồm các mục tiêu kinh doanh được xác định rõ ràng và có thể đo lường được.",
        },
        {
          en: "- What challenges were faced in carrying out the campaign, and how were they successfully overcome?",
          vi: "- Những thách thức nào đã gặp phải khi thực hiện chiến dịch và họ đã vượt qua thành công như thế nào?",
        },
        {
          en: "- Provide a breakdown of budgets (indicating currency) to demonstrate the campaigns’ cost-effectiveness and key statistics to describe the scale of the campaign are essential.",
          vi: "- Cung cấp bảng phân tích ngân sách (cho biết loại tiền tệ) để chứng minh tính hiệu quả về mặt chi phí của chiến dịch và số liệu thống kê chính để mô tả quy mô của chiến dịch là điều cần thiết.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Concept and Strategy (15 grade)",
        vi: "Mục 2: Khái niệm và chiến lược (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign demonstrates a cohesive, multi-channel strategy that aligned with the brand’s overall marketing objectives.",
          vi: "- Mô tả cách chiến dịch thể hiện chiến lược đa kênh, gắn kết phù hợp với mục tiêu tiếp thị tổng thể của thương hiệu.",
        },
        {
          en: "- Describe any unique research and insightful approach to the overall issue and target audience supported with evidence.",
          vi: "- Mô tả bất kỳ nghiên cứu độc đáo nào và cách tiếp cận sâu sắc đối với vấn đề tổng thể và đối tượng mục tiêu được hỗ trợ bằng bằng chứng.",
        },
        {
          en: `- Describe the campaign's overarching plan about the broader business needs.Explain how the tactics contribute to the overall strategic effectiveness and success.`,
          vi: "- Mô tả kế hoạch tổng thể của chiến dịch về nhu cầu kinh doanh rộng hơn. Giải thích cách các chiến thuật góp phần vào hiệu quả và thành công chiến lược tổng thể.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 3: Creativity and Innovation (45 grade)",
        vi: "Mục 3: Sáng tạo và đổi mới (45 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign demonstrates a unique, creative, and innovative approach that stands out from typical advertising. Explain the idea that led to the creative concept and its implementation.",
          vi: "- Mô tả cách chiến dịch thể hiện cách tiếp cận độc đáo, sáng tạo và đổi mới, nổi bật so với quảng cáo thông thường. Giải thích ý tưởng dẫn đến ý tưởng sáng tạo và cách thực hiện ý tưởng đó.",
        },
        {
          en: "- Describe how the campaign pushes the boundaries of the medium and challenges industry norms and how it captures attention through innovative storytelling, visuals, messaging, and use of media.",
          vi: "- Mô tả cách chiến dịch vượt qua các ranh giới của phương tiện truyền thông và thách thức các chuẩn mực của ngành cũng như cách chiến dịch thu hút sự chú ý thông qua cách kể chuyện, hình ảnh, thông điệp và cách sử dụng phương tiện truyền thông đổi mới.",
        },
        {
          en: "- Describe how the campaign aims for technical excellence and showcases flawless, high-quality execution across all creative assets and touchpoints.",
          vi: "- Mô tả cách chiến dịch hướng đến sự xuất sắc về mặt kỹ thuật và thể hiện khả năng thực thi hoàn hảo, chất lượng cao trên tất cả nội dung quảng cáo và điểm tiếp xúc.",
        },
        {
          en: "- The relevance of the campaign idea to the nature of the product/service and characteristics of the target audience.",
          vi: "- Sự phù hợp của ý tưởng chiến dịch với bản chất của sản phẩm/dịch vụ và đặc điểm của đối tượng mục tiêu.",
        },
        {
          en: "- The relevance of selected Influencers to the brand image, products/services, and target audience.",
          vi: "- Mức độ phù hợp của Người ảnh hưởng được chọn với hình ảnh thương hiệu, sản phẩm/dịch vụ và đối tượng mục tiêu.",
        },
        {
          en: "- Include details of the unique challenges faced by the campaign and explain how they were successfully overcome.",
          vi: "- Bao gồm chi tiết về những thách thức đặc biệt mà chiến dịch phải đối mặt và giải thích cách vượt qua chúng thành công.",
        },
      ],
      points: 45,
    },
    {
      title: {
        en: "Section 4: Effectiveness and Impact (25 grade)",
        vi: "Mục 4: Hiệu quả và tác động(25 điểm)",
      },
      description: [
        {
          en: "- Describe the effectiveness and impact of the campaign including quantifiable results such as increased brand awareness, web traffic, engagement metrics, sales lift, number of people exposed to the campaign message through communication channels, and Influencer activities.  etc.",
          vi: "- Mô tả tính hiệu quả và tác động của chiến dịch, bao gồm các kết quả có thể định lượng như tăng nhận thức về thương hiệu, lưu lượng truy cập vào trang web, số liệu tương tác, mức tăng doanh số bán hàng, số người tiếp xúc với thông điệp của chiến dịch thông qua các kênh truyền thông và hoạt động của Người gây ảnh hưởng. v.v.",
        },
        {
          en: "- Provide a clear, measurable impact on the business including data-driven proof of the campaign’s success.",
          vi: "- Cung cấp tác động rõ ràng, có thể đo lường được đối với hoạt động kinh doanh, bao gồm bằng chứng dựa trên dữ liệu về sự thành công của chiến dịch.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 5:  Industry Recognition (5 grade)",
        vi: "Mục 5: Sự công nhận của ngành (5 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign affects the industry.",
          vi: "- Mô tả chiến dịch ảnh hưởng đến ngành như thế nào.",
        },
      ],
      points: 5,
    },
  ],
  "chien-dich-promotion-xuat-sac-nhat": [
    {
      title: {
        en: "Section 1: Objectives and Challenges (10 grade)",
        vi: "Mục 1: Mục tiêu và thách thức (10 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of the campaign including clearly defined and measurable business objectives.",
          vi: "- Cung cấp cái nhìn tổng quan về chiến dịch bao gồm các mục tiêu kinh doanh được xác định rõ ràng và có thể đo lường.",
        },
        {
          en: "- What challenges were faced in carrying out the campaign, and how were they successfully overcome?",
          vi: "- Những thách thức nào đã gặp phải khi thực hiện chiến dịch và họ đã vượt qua thành công như thế nào?",
        },
        {
          en: "- Provide a breakdown of budgets (indicating currency) to demonstrate the campaigns’ cost-effectiveness and key statistics to describe the scale of the campaign are essential.",
          vi: "- Cung cấp bảng phân tích ngân sách (cho biết loại tiền tệ) để chứng minh tính hiệu quả về mặt chi phí của chiến dịch và số liệu thống kê chính để mô tả quy mô của chiến dịch là điều cần thiết.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Concept and Strategy (15 grade)",
        vi: "Mục 2: Khái niệm và chiến lược (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign demonstrates a cohesive, multi-channel strategy that aligned with the brand’s overall marketing objectives.",
          vi: "- Mô tả cách chiến dịch thể hiện chiến lược đa kênh, gắn kết phù hợp với mục tiêu tiếp thị tổng thể của thương hiệu.",
        },
        {
          en: "- Describe any unique research and insightful approach to the overall issue and target audience supported with evidence.",
          vi: "- Mô tả bất kỳ nghiên cứu độc đáo nào và cách tiếp cận sâu sắc đối với vấn đề tổng thể và đối tượng mục tiêu được hỗ trợ bằng bằng chứng.",
        },
        {
          en: `- Describe the campaign's overarching plan about the broader business needs.Explain how the tactics contribute to the overall strategic effectiveness and success.`,
          vi: "- Mô tả kế hoạch tổng thể của chiến dịch về nhu cầu kinh doanh rộng hơn. Giải thích cách các chiến thuật góp phần vào hiệu quả và thành công chiến lược tổng thể.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 3: Creativity and Innovation (40 grade)",
        vi: "Mục 3: Sáng tạo và đổi mới (40 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign demonstrates a unique, creative, and innovative approach that stands out from typical advertising. Explain the idea that led to the creative concept and its implementation.",
          vi: "- Mô tả cách chiến dịch thể hiện cách tiếp cận độc đáo, sáng tạo và đổi mới, nổi bật so với quảng cáo thông thường. Giải thích ý tưởng dẫn đến ý tưởng sáng tạo và cách thực hiện ý tưởng đó.",
        },
        {
          en: "- Describe how the campaign pushes the boundaries of the medium and challenges industry norms and how it captures attention through innovative storytelling, visuals, messaging, and use of media.",
          vi: "- Mô tả cách chiến dịch vượt qua các ranh giới của phương tiện truyền thông và thách thức các chuẩn mực của ngành cũng như cách chiến dịch thu hút sự chú ý thông qua cách kể chuyện, hình ảnh, thông điệp và cách sử dụng phương tiện truyền thông đổi mới.",
        },
        {
          en: "- Describe how the campaign aims for technical excellence and showcases flawless, high-quality execution across all creative assets and touchpoints.",
          vi: "- Mô tả cách chiến dịch hướng đến sự xuất sắc về mặt kỹ thuật và thể hiện khả năng thực thi hoàn hảo, chất lượng cao trên tất cả nội dung quảng cáo và điểm tiếp xúc.",
        },
        {
          en: "- Include details of the unique challenges faced by the campaign and explain how they were successfully overcome.",
          vi: "- Bao gồm chi tiết về những thách thức đặc biệt mà chiến dịch phải đối mặt và giải thích cách vượt qua chúng thành công.",
        },
      ],
      points: 40,
    },
    {
      title: {
        en: "Section 4: Effectiveness and Impact (30 grade)",
        vi: "Mục 4: Hiệu quả và tác động (30 điểm)",
      },
      description: [
        {
          en: "-  Describe the effectiveness and impact of the campaign including quantifiable results such as increased brand awareness, web traffic, engagement metrics, sales lift, etc.",
          vi: "- Mô tả hiệu quả và tác động của chiến dịch, bao gồm các kết quả có thể định lượng như tăng nhận thức về thương hiệu, lưu lượng truy cập web, số liệu tương tác, mức tăng doanh số bán hàng, v.v.",
        },
        {
          en: "- Provide a clear, measurable impact on the business including data-driven proof of the campaign’s success.",
          vi: "- Cung cấp tác động rõ ràng, có thể đo lường được đối với doanh nghiệp, bao gồm bằng chứng dựa trên dữ liệu về sự thành công của chiến dịch.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 5: Industry Recognition (5 grade)",
        vi: "Mục 5: Sự công nhận của ngành (5 điểm)",
      },
      description: [
        {
          en: "- Describe how the campaign affects the industry.",
          vi: "- Mô tả chiến dịch ảnh hưởng như thế nào đến ngành.",
        },
      ],
      points: 5,
    },
  ],
  "su-kien-cua-nam": [
    {
      title: {
        en: "Section 1: Objectives and Challenges (10 grade)",
        vi: "Mục 1: Mục tiêu & Thử thách (10 điểm)",
      },
      description: [
        {
          en: `- What was the client's objective and challenges you face during the creation of the event ?`,
          vi: "- Mục tiêu và thách thức của khách hàng mà bạn gặp phải trong quá trình tổ chức sự kiện là gì?",
        },
        {
          en: "- How did you overcome the challenges and how did u achieve the objectives?",
          vi: "- Bạn đã vượt qua những thách thức và bạn đạt được mục tiêu như thế nào?",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Concept and Event Strategy (30 grade)",
        vi: "Mục 2: Chiến lược & Ý tưởng sự kiện (30 điểm)",
      },
      description: [
        {
          en: "- What strategy did you leverage to appeal to your target audience & why?",
          vi: "- Bạn đã tận dụng chiến lược nào để thu hút đối tượng mục tiêu của mình và tại sao?",
        },
        {
          en: "- What was the concept that you derived from the event or master strategy to attract people to the event?",
          vi: "- Ý tưởng mà bạn rút ra từ sự kiện hoặc chiến lược tổng thể để thu hút mọi người đến sự kiện là gì?",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 3: Creativity and Innovation (30 grade)",
        vi: "Mục 3: Sáng tạo/Thực thi sự kiện (30 điểm)",
      },
      description: [
        {
          en: "- In summary explain the event, showcase the look and feel of the event and show us why you feel your event should be the event of the year?",
          vi: "- Tóm lại, hãy giải thích sự kiện, giới thiệu giao diện của sự kiện và cho chúng tôi biết lý do tại sao bạn cảm thấy sự kiện của mình phải là sự kiện của năm?",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 4: Innovation (10 grade)",
        vi: "Mục 4: Đổi mới (10 điểm)",
      },
      description: [
        {
          en: "- Did you do any innovation within the event, if so what?",
          vi: "- Bạn có thực hiện bất kỳ đổi mới nào trong sự kiện không, nếu có thì sao?",
        },
        {
          en: "- Did you have a partner if so credit the partner, what do you feel makes it innovative?",
          vi: "- Bạn đã có đối tác chưa nếu ghi nhận đối tác đó, bạn cảm thấy điều gì khiến nó trở nên đổi mới?",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Effectiveness (10 grade)",
        vi: "Mục 5: Hiệu quả (10 điểm)",
      },
      description: [
        {
          en: "- What were the results? How many people attended? Did you achieve your KPIs? Define these accordingly",
          vi: "- Kết quả là gì? Có bao nhiêu người tham dự? Bạn đã đạt được KPI của mình chưa? Xác định những điều này cho phù hợp",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 6:  Culture (10 grade)",
        vi: "Mục 6: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- Did you incorporate anything specific to the culture of Vietnam in your event? If not what elements or trends did you incorporate from other cultures to bring something refreshing and new to Vietnam?",
          vi: "- Bạn có kết hợp điều gì cụ thể về văn hóa Việt Nam vào sự kiện của mình không? Nếu không thì bạn đã kết hợp những yếu tố hay xu hướng nào từ các nền văn hóa khác để mang lại điều gì đó mới mẻ và mới mẻ cho Việt Nam?",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Highlight any agency/creative partners if the idea was not directly from the event company.",
          vi: "Highlight các agency/đối tác sáng tạo nào nếu ý tưởng không đến trực tiếp từ chính công ty tổ chức sự kiện.",
        },
      ],
    },
  ],
  "hoat-dong-activation-cua-nam": [
    {
      title: {
        en: "Section 1: Objectives and Challenges (10 grade)",
        vi: "Mục 1: Mục tiêu và Thử thách (10 điểm)",
      },
      description: [
        {
          en: `Describe the client's objective of the activation activity`,
          vi: "Mô tả mục tiêu của khách hàng về hoạt động kích hoạt",
        },
        {
          en: "- What challenges did you face during the creation of the activation?",
          vi: "- Bạn gặp phải những thách thức gì trong quá trình tạo hoạt động kích hoạt?",
        },
        {
          en: "- How did you overcome the challenges and how did you achieve the objectives?",
          vi: "- Bạn đã vượt qua những thách thức như thế nào và đạt được mục tiêu như thế nào?",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Concept and Activation Strategy (20 grade)",
        vi: "Mục 2: Chiến lược & Khái niệm kích hoạt (20 điểm)",
      },
      description: [
        {
          en: "- What activation/onground strategy did you leverage to appeal to your target audience & why? What was the concept that you derived from the event or master strategy to attract people to the activity?",
          vi: "- Bạn đã tận dụng chiến lược kích hoạt/thực tế nào để thu hút đối tượng mục tiêu của mình và tại sao? Khái niệm mà bạn rút ra từ sự kiện hoặc chiến lược tổng thể để thu hút mọi người tham gia hoạt động là gì?",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 3: Activation Creative/Execution (30 grade)",
        vi: "Mục 3:  Quảng cáo kích hoạt/Thực thi (30 điểm)",
      },
      description: [
        {
          en: "- In summary, explain the activity, showcase the look and feel of the activity, and show us why you feel your activation activity should be the activation activity of the year.",
          vi: "- Tóm lại, hãy giải thích hoạt động, giới thiệu giao diện của hoạt động và cho chúng tôi biết lý do tại sao bạn cảm thấy hoạt động kích hoạt của mình phải là hoạt động kích hoạt của năm.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 4: Innovation (10 grade)",
        vi: "Mục 4: Đổi mới (10 điểm)",
      },
      description: [
        {
          en: "- Did you do any innovation within the activation, if so what? Did you have a partner if so credit the partner, what do you feel makes the activation innovative?",
          vi: "- Bạn có thực hiện bất kỳ đổi mới nào trong quá trình kích hoạt không, nếu có thì sao? Bạn có đối tác không nếu ghi nhận đối tác đó, bạn cảm thấy điều gì khiến việc kích hoạt trở nên đổi mới?",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Effectiveness (10 grade)",
        vi: "Mục 5: Hiệu quả (10 điểm)",
      },
      description: [
        {
          en: "- What were the results? How many people attended? Did you achieve your KPIs? Define these accordingly",
          vi: "- Kết quả là gì? Có bao nhiêu người tham dự? Bạn đã đạt được KPI của mình chưa? Xác định những điều này cho phù hợp",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 6:  Culture (10 grade)",
        vi: "Mục 6: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- Did you incorporate anything specific to the culture of Vietnam in your activation? If not what elements or trends did you incorporate from other cultures to bring something refreshing and new to Vietnam.",
          vi: "- Bạn có kết hợp điều gì cụ thể về văn hóa Việt Nam vào hoạt động của mình không? Nếu không phải bạn đã kết hợp những yếu tố hay xu hướng nào từ các nền văn hóa khác để mang lại điều gì đó mới mẻ và mới mẻ cho Việt Nam.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Highlight any agency/creative partners if the idea was not directly from the activation company.",
          vi: "Highlight các agency/đối tác sáng tạo nào nếu ý tưởng không đến trực tiếp từ chính công ty tổ chức.",
        },
      ],
    },
  ],
  "su-kien-tet-an-tuong-nhat": [
    {
      title: {
        en: "Section 1: Objectives and Challenges (10 grade)",
        vi: "Mục 1: Mục tiêu và Thách thức (10 điểm)",
      },
      description: [
        {
          en: `- What was your/client's objective and what challenges you faced during the creation of the event?`,
          vi: "- Mục tiêu và thách thức của bạn/khách hàng gặp phải trong quá trình tạo sự kiện là gì?",
        },
        {
          en: "- How did you overcome the challenges and how did you achieve the objectives?",
          vi: "- Bạn đã vượt qua những thách thức và đạt được mục tiêu như thế nào?",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Concept and Tet Event Strategy (30 grade)",
        vi: "Mục 2: Chiến lược & Concept sự kiện Tết (30 điểm)",
      },
      description: [
        {
          en: "- What strategy did you leverage to appeal to your target audience & why? What was the concept that you derived from the event or master strategy to attract people to the event?",
          vi: "- Bạn đã tận dụng chiến lược nào để thu hút đối tượng mục tiêu của mình và tại sao? Concept mà bạn rút ra từ sự kiện hoặc chiến lược tổng thể để thu hút mọi người đến với sự kiện là gì?",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 3: Tet Event Creative/Execution (30 grade)",
        vi: "Mục 3: Sáng tạo/Thực thi sự kiện Tết (30 điểm)",
      },
      description: [
        {
          en: "- In summary, explain the event, showcase the look and feel of the event, and show us why you feel your event should be the most impressive Tet event of the year.",
          vi: "- Tóm lại, hãy giải thích sự kiện, giới thiệu giao diện của sự kiện và cho chúng tôi biết lý do tại sao bạn cảm thấy sự kiện của mình phải là sự kiện Tết ấn tượng nhất trong năm?",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 4: Innovation (10 grade)",
        vi: "Mục 4: Đổi mới (10 điểm)",
      },
      description: [
        {
          en: "- Did you do any innovation within the event, if so what?",
          vi: "- Bạn có thực hiện bất kỳ đổi mới nào trong sự kiện không, nếu có thì sao?",
        },
        {
          en: "- Did you have a partner? If so, credit the partner, what do you feel makes it innovative?",
          vi: "- Bạn đã có đối tác chưa nếu ghi nhận đối tác đó, bạn cảm thấy điều gì khiến nó trở nên đổi mới?",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Culture (10 grade)",
        vi: "Mục 5: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- Did you incorporate anything specific to the culture of Vietnam in your event, especially since it was for Tet. In addition, please highlight any agency/creative partners if the idea was not directly from the event company itself.",
          vi: "- Bạn có kết hợp điều gì cụ thể về văn hóa Việt Nam vào sự kiện của mình không, đặc biệt là vì đó là dịp Tết. Ngoài ra, vui lòng nêu bật bất kỳ agency/đối tác sáng tạo nào nếu ý tưởng không đến trực tiếp từ chính công ty tổ chức sự kiện.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 6:  Effectiveness (10 grade)",
        vi: "Mục 6: Hiệu quả (10 điểm)",
      },
      description: [
        {
          en: "- The event is assessed to bring good quality and experience.",
          vi: "- Sự kiện được đánh giá mang lại chất lượng và trải nghiệm tốt.",
        },
        {
          en: "- Communication effectiveness information and feedback from the public.",
          vi: "- Thông tin và phản hồi từ công chúng về hiệu quả truyền thông.",
        },
      ],
      points: 10,
    },
  ],
  "su-kien-truyen-cam-hung-cua-nam": [
    {
      title: {
        en: "Section 1: Mission & Objective (10 grade)",
        vi: "Mục 1: Sứ mệnh & Mục tiêu (10 điểm)",
      },
      description: [
        {
          en: "- What was the clients/your mission and objective for this inspirational event?",
          vi: "- Khách hàng/sứ mệnh và mục tiêu của bạn cho sự kiện đầy cảm hứng này là gì?",
        },
        {
          en: "- How did you overcome any challenges (if any) how did you achieve the objectives and are you on the path of achieving your mission? ",
          vi: "- Bạn đã vượt qua mọi thử thách (nếu có) như thế nào và bạn đã đạt được mục tiêu cũng như đang trên con đường đạt được sứ mệnh của mình như thế nào?",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Concept and Event Strategy (20 grade)",
        vi: "Mục 2: Chiến lược & Ý tưởng sự kiện (20 điểm)",
      },
      description: [
        {
          en: "- What strategy did you leverage to appeal to your target audience & why?",
          vi: "- Bạn đã tận dụng chiến lược nào để thu hút đối tượng mục tiêu của mình và tại sao?",
        },
        {
          en: "- What was the concept that you derived from the event or master strategy to attract people to the event?",
          vi: "- Khái niệm mà bạn rút ra từ sự kiện hoặc chiến lược tổng thể để thu hút mọi người đến sự kiện là gì?",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 3: Event Creative/Execution (30 grade)",
        vi: "Mục 3: Sáng tạo/Thực thi sự kiện (30 điểm)",
      },
      description: [
        {
          en: "- In summary, explain the event, showcase the look and feel of the event and show us why you feel your event should be the event of the year?",
          vi: "- Tóm lại, hãy giải thích sự kiện, giới thiệu giao diện của sự kiện và cho chúng tôi biết lý do tại sao bạn cảm thấy sự kiện của mình phải là sự kiện của năm?",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 4: Innovation (10 grade)",
        vi: "Mục 4: Đổi mới (10 điểm)",
      },
      description: [
        {
          en: "- Did you do any innovation within the event, if so what?",
          vi: "- Bạn có thực hiện bất kỳ đổi mới nào trong sự kiện không, nếu có thì sao?",
        },
        {
          en: "- Did you have a partner if so credit the partner, what do you feel makes it innovative?",
          vi: "- Bạn đã có đối tác chưa nếu ghi nhận đối tác đó, bạn cảm thấy điều gì khiến nó trở nên đổi mới?",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Culture (10 grade)",
        vi: "Mục 5: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- Did you incorporate anything specific to the culture of Vietnam in your event? If not what elements or trends did you incorporate from other cultures to bring something refreshing and new for Vietnam.",
          vi: "- Bạn có kết hợp điều gì cụ thể về văn hóa Việt Nam vào sự kiện của mình không? Nếu không phải bạn đã kết hợp những yếu tố hay xu hướng nào từ các nền văn hóa khác để mang lại điều gì đó mới mẻ và mới mẻ cho Việt Nam.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 6: Social responsibility & Impact (20 grade)",
        vi: "Mục 6: Trách nhiệm xã hội và tác động (20 điểm)",
      },
      description: [
        {
          en: "- Define why you feel this event is inspirational and how it impacts the society of Vietnam at large? What is the positive impact you feel this will build for the collective of all rather than just for brand recognition etc.?",
          vi: "- Xác định lý do tại sao bạn cảm thấy sự kiện này truyền cảm hứng và nó tác động như thế nào đến xã hội Việt Nam nói chung? Tác động tích cực mà bạn cảm thấy điều này sẽ mang lại cho tập thể thay vì chỉ để nhận diện thương hiệu, v.v. là gì?",
        },
        {
          en: "- Have you been able to measure the impact? If so how is it? Is the idea long-term or short-term? Please define a topline statement on your ambitions that meet with the positive impact of the event.",
          vi: "- Bạn đã có thể đo lường được tác động chưa? Nếu vậy thì nó thế nào? Ý tưởng đó mang tính dài hạn hay ngắn hạn? Vui lòng xác định một tuyên bố cơ bản về tham vọng của bạn đáp ứng được tác động tích cực của sự kiện.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Highlight any agency/creative partners if the idea was not directly from the event company.",
          vi: "Làm nổi bật bất kỳ agency/đối tác sáng tạo nào nếu ý tưởng không đến trực tiếp từ chính công ty tổ chức sự kiện.",
        },
      ],
    },
  ],
  "tvc-noi-bat-cua-nam": [
    {
      title: {
        en: "Section 1: Objectives and Challenges (10 grade)",
        vi: "Mục 1: Mục tiêu và thách thức (10 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the campaign including clearly defined and measurable business objectives. (KPI's)`,
          vi: `- Cung cấp cái nhìn tổng quan về chiến dịch bao gồm các mục tiêu kinh doanh được xác định rõ ràng và có thể đo lường được. (KPI's)`,
        },
        {
          en: "- What challenges were faced in carrying out the TVC if any, and how were they successfully overcome?",
          vi: "- Những thách thức nào khi thực hiện TVC nếu có và họ đã vượt qua thành công như thế nào?",
        },
        {
          en: "- Provide a % breakdown of budgets (indicating currency/value) to demonstrate the TVC’s cost-effectiveness and key statistics to describe the scale of the campaign are essential.",
          vi: "- Cung cấp tỷ lệ phần trăm ngân sách (cho biết đơn vị tiền tệ/giá trị) để chứng minh tính hiệu quả về mặt chi phí của TVC và số liệu thống kê chính để mô tả quy mô của chiến dịch là điều cần thiết.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Concept and Strategy (15 grade)",
        vi: "Mục 2: Khái niệm và chiến lược (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the TVC demonstrates a cohesive, multi-channel, or single-channel strategy aligned with the brand’s overall marketing objectives.",
          vi: "- Mô tả cách TVC thể hiện chiến lược gắn kết, đa kênh hoặc đơn kênh phù hợp với mục tiêu tiếp thị tổng thể của thương hiệu.",
        },
        {
          en: "- Describe any unique research and insightful approach to the overall issue and target audience supported with evidence.",
          vi: "- Mô tả bất kỳ nghiên cứu độc đáo nào và cách tiếp cận sâu sắc đối với vấn đề tổng thể và đối tượng mục tiêu được hỗ trợ bằng bằng chứng.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 3: Creativity and Innovation (30 grade)",
        vi: "Mục 3: Sáng tạo và đổi mới (30 điểm)",
      },
      description: [
        {
          en: "- Describe how the TVC demonstrates a unique, creative, and innovative approach that stands out from others. Explain the idea that led to the creative concept and its implementation.",
          vi: "- Mô tả cách TVC thể hiện cách tiếp cận độc đáo, sáng tạo và đổi mới, nổi bật so với những TVC khác. Giải thích nguồn gốc dẫn đến ý tưởng sáng tạo và cách thực hiện ý tưởng đó.",
        },
        {
          en: "- Describe how the TVC pushes the boundaries of the medium and challenges industry norms and how it captures attention through innovative storytelling, visuals, messaging, and use of media.",
          vi: "- Mô tả cách TVC vượt qua ranh giới của phương tiện và thách thức các tiêu chuẩn của ngành cũng như cách TVC thu hút sự chú ý thông qua cách kể chuyện, hình ảnh, thông điệp và cách sử dụng phương tiện truyền thông đổi mới.",
        },
        {
          en: "- Describe how the TVC aims for technical excellence and showcases flawless, high-quality execution across all creative assets and touchpoints.",
          vi: "- Mô tả cách TVC hướng tới sự xuất sắc về mặt kỹ thuật và thể hiện khả năng thực thi hoàn hảo, chất lượng cao trên tất cả nội dung sáng tạo và các điểm tiếp xúc.",
        },
        {
          en: "- Include details of the unique challenges faced by the campaign and explain how they were successfully overcome.",
          vi: "- Bao gồm chi tiết về những thách thức đặc biệt mà chiến dịch phải đối mặt và giải thích cách vượt qua chúng thành công.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 4: Culture (10 grade)",
        vi: "Mục 4: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- The TVC brings an inspirational message, spreading good Vietnamese cultural values, and contributing to creating a positive impact on the community.",
          vi: "- TVC mang đến thông điệp truyền cảm hứng, lan tỏa những giá trị văn hóa tốt đẹp của Việt Nam, góp phần tạo tác động tích cực đến cộng đồng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5:  Effectiveness and Impact (20 grade)",
        vi: "Mục 5: Hiệu quả và tác động (20 điểm)",
      },
      description: [
        {
          en: "- Describe the effectiveness and impact of the TVC including quantifiable results such as increased brand awareness, web traffic, engagement metrics, sales lift, etc.",
          vi: "- Mô tả hiệu quả và tác động của TVC, bao gồm các kết quả có thể định lượng như tăng nhận thức về thương hiệu, lưu lượng truy cập web, số liệu tương tác, mức tăng doanh số bán hàng, v.v.",
        },
        {
          en: "- Provide a clear, measurable impact on the business including data-driven proof of the campaign’s success.",
          vi: "- Cung cấp tác động rõ ràng, có thể đo lường được đối với doanh nghiệp, bao gồm bằng chứng dựa trên dữ liệu về sự thành công của chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 6:  Industry Recognition (15 grade)",
        vi: "Mục 6: Sự công nhận của ngành (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the TVC affects the industry and which partners were a part of the TVC from your team, this includes any 3rd parties that helped work on the project.",
          vi: "- Mô tả TVC ảnh hưởng như thế nào đến ngành và đối tác nào là một phần của TVC trong nhóm của bạn, bao gồm bất kỳ bên thứ 3 nào đã giúp thực hiện dự án.",
        },
      ],
      points: 15,
    },
  ],
  "key-visual-xuat-sac-nhat": [
    {
      title: {
        en: "Section 1: Objectives and Challenges (10 grade)",
        vi: "Mục 1: Mục tiêu và Thách thức (10 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the campaign including clearly defined and measurable business objectives. (KPI's)`,
          vi: `- Cung cấp cái nhìn tổng quan về chiến dịch bao gồm các mục tiêu kinh doanh được xác định rõ ràng và có thể đo lường được. (KPI's)`,
        },
        {
          en: "- What challenges were faced in carrying out the KV if any, and how were they successfully overcome?",
          vi: "- Những thách thức nào gặp phải khi thực hiện KV nếu có và họ đã vượt qua thành công như thế nào?",
        },
        {
          en: "- Provide a % breakdown of budgets (indicating currency/value) to demonstrate the KV’s cost-effectiveness and key statistics to describe the scale of the campaign are essential.",
          vi: "- Cung cấp tỷ lệ phần trăm ngân sách (cho biết tiền tệ/giá trị) để chứng minh tính hiệu quả về mặt chi phí của KV và số liệu thống kê chính để mô tả quy mô của chiến dịch là rất cần thiết.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Concept and Strategy (15 grade)",
        vi: "Mục 2: Khái niệm và chiến lược (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the KV demonstrates a cohesive, multi-channel, or single-channel strategy aligned with the brand’s overall marketing objectives.",
          vi: "- Mô tả cách KV thể hiện chiến lược gắn kết, đa kênh hoặc đơn kênh phù hợp với mục tiêu tiếp thị tổng thể của thương hiệu.",
        },
        {
          en: "- Describe any unique research and insightful approach to the overall issue and target audience supported with evidence.",
          vi: "- Mô tả bất kỳ nghiên cứu độc đáo nào và cách tiếp cận sâu sắc đối với vấn đề tổng thể và đối tượng mục tiêu được hỗ trợ bằng bằng chứng.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 3: Creativity and Innovation (30 grade)",
        vi: "Mục 3: Sáng tạo và đổi mới (30 điểm)",
      },
      description: [
        {
          en: "- Describe how the KV demonstrates a unique, creative, and innovative approach that stands out from others. Explain the idea that led to the creative concept and its implementation.",
          vi: "- Mô tả cách KV thể hiện cách tiếp cận độc đáo, sáng tạo và đổi mới, nổi bật so với những cách tiếp cận khác. Giải thích ý tưởng dẫn đến ý tưởng sáng tạo và cách thực hiện ý tưởng đó.",
        },
        {
          en: "- Describe how the KV pushes the boundaries of the medium and challenges industry norms and how it captures attention through innovative storytelling, visuals, messaging, and use of media.",
          vi: "- Mô tả cách KV vượt qua các ranh giới của phương tiện truyền thông và thách thức các chuẩn mực của ngành cũng như cách nó thu hút sự chú ý thông qua cách kể chuyện, hình ảnh, thông điệp và cách sử dụng phương tiện truyền thông đổi mới.",
        },
        {
          en: "- Describe how the KV aims for technical excellence and showcases flawless, high-quality execution across all creative assets and touchpoints.",
          vi: "- Mô tả cách KV hướng tới sự xuất sắc về mặt kỹ thuật và thể hiện khả năng thực thi hoàn hảo, chất lượng cao trên tất cả nội dung sáng tạo và điểm tiếp xúc.",
        },
        {
          en: "- Include details of the unique challenges faced by the campaign and explain how they were successfully overcome.",
          vi: "- Bao gồm chi tiết về những thách thức đặc biệt mà chiến dịch phải đối mặt và giải thích cách vượt qua chúng thành công.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 4: Culture (10 grade)",
        vi: "Mục 4: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- The KV brings an inspirational message, spreading good Vietnamese cultural values, and contributing to creating a positive impact on the community.",
          vi: "- KV mang đến thông điệp truyền cảm hứng, lan tỏa những giá trị văn hóa tốt đẹp của Việt Nam, góp phần tạo tác động tích cực đến cộng đồng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5:  Effectiveness and Impact (20 grade)",
        vi: "Mục 5: Hiệu quả và tác động (20 điểm)",
      },
      description: [
        {
          en: "- Describe the effectiveness and impact of the KV including quantifiable results such as increased brand awareness, web traffic, engagement metrics, sales lift, etc.",
          vi: "- Mô tả hiệu quả và tác động của KV bao gồm các kết quả có thể định lượng như tăng nhận thức về thương hiệu, lưu lượng truy cập web, số liệu tương tác, mức tăng doanh số bán hàng, v.v.",
        },
        {
          en: "- Provide a clear, measurable impact on the business including data-driven proof of the campaign’s success.",
          vi: "- Cung cấp tác động rõ ràng, có thể đo lường được đối với doanh nghiệp, bao gồm bằng chứng dựa trên dữ liệu về sự thành công của chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 6:  Industry Recognition (15 grade)",
        vi: "Mục 6: Sự công nhận của ngành (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the KV affects the industry and which partners were a part of the KV from your team, this includes any 3rd parties that helped work on the project.",
          vi: "- Mô tả KV ảnh hưởng như thế nào đến ngành và đối tác nào là một phần của KV trong nhóm của bạn, bao gồm bất kỳ bên thứ 3 nào đã giúp thực hiện dự án.",
        },
      ],
      points: 15,
    },
  ],
  "music-video-quang-cao-an-tuong": [
    {
      title: {
        en: "Section 1: Objectives and Challenges (10 grade)",
        vi: "Mục 1: Mục tiêu và Thách thức (10 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the campaign including clearly defined and measurable business objectives. (KPI's)`,
          vi: `- Cung cấp cái nhìn tổng quan về chiến dịch bao gồm các mục tiêu kinh doanh được xác định rõ ràng và có thể đo lường được. (KPI's)`,
        },
        {
          en: "- DWhat challenges were faced in carrying out the MV if any, and how were they successfully overcome?",
          vi: "- Trong quá trình thực hiện MV, những thách thức nào gặp phải và cách vượt qua thành công?",
        },
        {
          en: "- Provide a % breakdown of budgets (indicating currency/value) to demonstrate the MV’s cost-effectiveness and key statistics to describe the scale of the campaign are essential.",
          vi: "- Cung cấp tỷ lệ phần trăm ngân sách (cho biết đơn vị tiền tệ/giá trị) để chứng minh tính hiệu quả về mặt chi phí của MV và số liệu thống kê chính để mô tả quy mô của chiến dịch là điều cần thiết.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Concept and Strategy (15 grade)",
        vi: "Mục 2: Khái niệm và Chiến Lược (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the MV demonstrates a cohesive, multi-channel, or single-channel strategy aligned with the brand’s overall marketing objectives.",
          vi: "- Mô tả cách MV thể hiện chiến lược gắn kết, đa kênh hoặc đơn kênh phù hợp với mục tiêu tiếp thị tổng thể của thương hiệu.",
        },
        {
          en: "- Describe any unique research and insightful approach to the overall issue and target audience supported with evidence.",
          vi: "- Mô tả bất kỳ nghiên cứu độc đáo nào và cách tiếp cận sâu sắc đối với vấn đề tổng thể và đối tượng mục tiêu được hỗ trợ bằng bằng chứng.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 3: Creativity and Innovation (30 grade)",
        vi: "Mục 3: Sáng tạo và Đổi mới (30 điểm)",
      },
      description: [
        {
          en: "- Describe how the MV demonstrates a unique, creative, and innovative approach that stands out from others. Explain the idea that led to the creative concept and its implementation.",
          vi: "- Mô tả cách MV thể hiện cách tiếp cận độc đáo, sáng tạo và đổi mới, nổi bật so với các MV khác. Giải thích ý tưởng dẫn đến ý tưởng sáng tạo và cách thực hiện ý tưởng đó.",
        },
        {
          en: "- Describe how the MV pushes the boundaries of the medium and challenges industry norms and how it captures attention through innovative storytelling, visuals, messaging, and use of media.",
          vi: "- Mô tả cách MV vượt qua các ranh giới của phương tiện truyền thông và thách thức các tiêu chuẩn của ngành cũng như cách MV thu hút sự chú ý thông qua cách kể chuyện, hình ảnh, thông điệp và cách sử dụng phương tiện truyền thông đổi mới.",
        },
        {
          en: "- Describe how the MV aims for technical excellence and showcases flawless, high-quality execution across all creative assets and touchpoints.",
          vi: "- Mô tả cách MV hướng đến sự xuất sắc về mặt kỹ thuật và thể hiện khả năng thực hiện hoàn hảo, chất lượng cao trên tất cả nội dung sáng tạo và các điểm tiếp xúc.",
        },
        {
          en: "- Include details of the unique challenges faced by the campaign and explain how they were successfully overcome.",
          vi: "- Bao gồm chi tiết về những thách thức đặc biệt mà chiến dịch phải đối mặt và giải thích cách vượt qua chúng thành công.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 4: Culture (10 grade)",
        vi: "Mục 4: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- The MV brings an inspirational message, spreading good Vietnamese cultural values, and contributing to creating a positive impact on the community.",
          vi: "- MV mang đến thông điệp truyền cảm hứng, lan tỏa những giá trị văn hóa tốt đẹp Việt Nam, góp phần tạo tác động tích cực đến cộng đồng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5:  Effectiveness and Impact (20 grade)",
        vi: "Mục 5: Hiệu quả và Tác động (20 điểm)",
      },
      description: [
        {
          en: "- Describe the effectiveness and impact of the MV including quantifiable results such as increased brand awareness, web traffic, engagement metrics, sales lift, etc.",
          vi: "- Mô tả hiệu quả và tác động của MV, bao gồm các kết quả có thể định lượng như tăng nhận thức về thương hiệu, lưu lượng truy cập web, số liệu tương tác, mức tăng doanh số bán hàng, v.v.",
        },
        {
          en: "- Provide a clear, measurable impact on the business including data-driven proof of the campaign’s success.",
          vi: "- Cung cấp tác động rõ ràng, có thể đo lường được đối với doanh nghiệp, bao gồm bằng chứng dựa trên dữ liệu về sự thành công của chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 6:  Industry Recognition (15 grade)",
        vi: "Mục 6: Sự công nhận của ngành (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the MV affects the industry and which were a part of the MV from your team, this includes any 3rd parties that helped work on the project.",
          vi: "- Mô tả MV ảnh hưởng như thế nào đến ngành và đối tác nào là một phần của MV từ nhóm của bạn, điều này bao gồm bất kỳ bên thứ 3 nào đã giúp thực hiện dự án.",
        },
      ],
      points: 15,
    },
  ],
  "independent-agency-cua-nam": [
    {
      title: {
        en: "Section 1: Mission Statement (10 grade)",
        vi: "Mục 1: Tuyên bố sứ mệnh (10 điểm)",
      },
      description: [
        {
          en: "- Describe the agency’s vision and objectives. What was the strategy and how did the agency accomplish its objectives with evidence of your success and achievements.",
          vi: "- Mô tả tầm nhìn và mục tiêu của Agency. Chiến lược là gì và Agency đã hoàn thành mục tiêu của mình như thế nào với bằng chứng về sự thành công và thành tích của bạn.",
        },
        {
          en: "- Describe the challenges, both internally as a business, employees, and externally for client accounts.",
          vi: "- Mô tả những thách thức, cả trong nội bộ doanh nghiệp, nhân viên và bên ngoài đối với tài khoản khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Innovation (35 grade)",
        vi: "Mục 2: Đổi mới (35 điểm)",
      },
      description: [
        {
          en: "- Describe any creative thinking that inspired the industry or other businesses as a whole to think more innovatively.",
          vi: "- Mô tả bất kỳ tư duy sáng tạo nào đã truyền cảm hứng cho toàn ngành hoặc các doanh nghiệp khác suy nghĩ đổi mới hơn.",
        },
        {
          en: "- Provide any new initiatives/thought leadership that the agency introduced and the impact and success of how these have led the industry.",
          vi: "- Cung cấp bất kỳ sáng kiến/tư tưởng lãnh đạo mới nào mà Agency đã đưa ra cũng như tác động và thành công của cách những sáng kiến/tư tưởng lãnh đạo này đã dẫn dắt ngành.",
        },
      ],
      points: 35,
    },
    {
      title: {
        en: "Section 3: Creativity (25 grade)",
        vi: "Mục 3: Sáng tạo (25 điểm)",
      },
      description: [
        {
          en: "- Highlights of some best work produced by the agency, and how it has been conducted (solely or in partnership).",
          vi: "- Điểm nổi bật của một số công việc tốt nhất do Agency thực hiện và cách nó được thực hiện (riêng hoặc hợp tác).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Culture (10 grade)",
        vi: "Mục 4: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- Describe how your company applies the culture to that artwork, and how to convey the message that is relevant to the culture.",
          vi: "- Mô tả cách công ty của bạn áp dụng văn hóa vào tác phẩm nghệ thuật đó và cách truyền tải thông điệp phù hợp với văn hóa đó.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Talent Management (10 grade)",
        vi: "Mục 5: Quản lý nhân tài (10 điểm)",
      },
      description: [
        {
          en: "- Key hires and promotions, staff turnover percentage change, and diversity.",
          vi: "- Tuyển dụng và thăng chức chủ chốt, thay đổi tỷ lệ luân chuyển nhân viên và sự đa dạng.",
        },
        {
          en: "- Provide a brief overview of staff development programs, inclusion, and diversity and its implementation and success.",
          vi: "- Cung cấp một cái nhìn tổng quan ngắn gọn về các chương trình phát triển nhân viên, sự hòa nhập và tính đa dạng cũng như việc thực hiện và thành công của chương trình đó.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 6: Contribution (10 grade)",
        vi: "Mục 6: Đóng góp (10 điểm)",
      },
      description: [
        {
          en: "- What industry initiatives and programs did you support and how? What industry and other community activities are the agency involved in?",
          vi: "- Bạn đã hỗ trợ những sáng kiến và chương trình nào trong ngành và bằng cách nào? Agency này tham gia vào ngành nào và các hoạt động cộng đồng nào?",
        },
        {
          en: "- Other contributions to industry advancement and provide examples of how your agency has contributed to improving the image of advertising in your marketplace and the quality of creativity.",
          vi: "- Những đóng góp khác cho sự phát triển của ngành và cung cấp các ví dụ về cách agency của bạn đã góp phần cải thiện hình ảnh quảng cáo trên thị trường của bạn cũng như chất lượng của sự sáng tạo.",
        },
      ],
      points: 10,
    },
  ],
  "network-agency-cua-nam": [
    {
      title: {
        en: "Section 1: Mission Statement (10 grade)",
        vi: "Mục 1: Tuyên bố sứ mệnh (10 điểm)",
      },
      description: [
        {
          en: "- Describe the agency’s vision and objectives. What was the strategy and how did the agency accomplish its objectives with evidence of your success and achievements.",
          vi: "- Mô tả tầm nhìn và mục tiêu của Agency. Chiến lược là gì và Agency đã hoàn thành mục tiêu của mình như thế nào với bằng chứng về sự thành công và thành tích của bạn.",
        },
        {
          en: "- Describe the challenges, both internally as a business, employees, and externally for client accounts.",
          vi: "- Mô tả những thách thức, cả trong nội bộ doanh nghiệp, nhân viên và bên ngoài đối với tài khoản khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Innovation (20 grade)",
        vi: "Mục 2: Đổi mới (20 điểm)",
      },
      description: [
        {
          en: "- Describe any creative thinking that inspired the industry or other businesses as a whole to think more innovatively.",
          vi: "- Mô tả bất kỳ tư duy sáng tạo nào đã truyền cảm hứng cho toàn ngành hoặc các doanh nghiệp khác suy nghĩ đổi mới hơn.",
        },
        {
          en: "- Provide any new initiatives/thought leadership that the agency introduced and the impact and success of how these have led the industry.",
          vi: "- Cung cấp bất kỳ sáng kiến/tư tưởng lãnh đạo mới nào mà Agency đã đưa ra cũng như tác động và thành công của cách những sáng kiến/tư tưởng lãnh đạo này đã dẫn dắt ngành.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 3: Creativity (15 grade)",
        vi: "Mục 3: Sáng tạo (15 điểm)",
      },
      description: [
        {
          en: "- Highlights of some best work produced by the agency, and how it has been conducted (solely or in partnership).",
          vi: "- Điểm nổi bật của một số công việc tốt nhất do Agency thực hiện và cách nó được thực hiện (riêng hoặc hợp tác).",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 4: Culture (10 grade)",
        vi: "Mục 4: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- Describe how your company applies the culture to that artwork, and how to convey the message that is relevant to the culture.",
          vi: "- Mô tả cách công ty của bạn áp dụng văn hóa vào tác phẩm nghệ thuật đó và cách truyền tải thông điệp phù hợp với văn hóa đó.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Talent Management (10 grade)",
        vi: "Mục 5: Quản lý nhân tài (10 điểm)",
      },
      description: [
        {
          en: "- Key hires and promotions, staff turnover percentage change, and diversity.",
          vi: "- Tuyển dụng và thăng chức chủ chốt, thay đổi tỷ lệ luân chuyển nhân viên và sự đa dạng.",
        },
        {
          en: "- Provide a brief overview of staff development programs, inclusion, and diversity and its implementation and success.",
          vi: "- Cung cấp một cái nhìn tổng quan ngắn gọn về các chương trình phát triển nhân viên, sự hòa nhập và tính đa dạng cũng như việc thực hiện và thành công của chương trình đó.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 6: Contribution (5 grade)",
        vi: "Mục 6: Đóng góp (5 điểm)",
      },
      description: [
        {
          en: "- What industry initiatives and programs did you support and how? What industry and other community activities are the agency involved in?",
          vi: "- Bạn đã hỗ trợ những sáng kiến và chương trình nào trong ngành và bằng cách nào? Agency này tham gia vào ngành nào và các hoạt động cộng đồng nào?",
        },
        {
          en: "- Other contributions to industry advancement and provide examples of how your agency has contributed to improving the image of advertising in your marketplace and the quality of creativity.",
          vi: "- Những đóng góp khác cho sự phát triển của ngành và cung cấp các ví dụ về cách agency của bạn đã góp phần cải thiện hình ảnh quảng cáo trên thị trường của bạn cũng như chất lượng của sự sáng tạo.",
        },
      ],
      points: 5,
    },
  ],
  "digital-agency-cua-nam": [
    {
      title: {
        en: "Section 1: Mission Statement (10 grade)",
        vi: "Mục 1: Tuyên bố sứ mệnh (10 điểm)",
      },
      description: [
        {
          en: "- Describe the agency’s vision and objectives. What was the strategy and how did the agency accomplish its objectives with evidence of your success and achievements.",
          vi: "- Mô tả tầm nhìn và mục tiêu của Agency. Chiến lược là gì và Agency đã hoàn thành mục tiêu của mình như thế nào với bằng chứng về sự thành công và thành tích của bạn. Tầm nhìn và mục tiêu được mô tả của cơ sở. Chiến lược là gì và Agency đã hoàn thành mục tiêu của mình như thế nào với bằng chứng về sự thành công và thành công của bạn.",
        },
        {
          en: "- Describe the challenges, both internally as a business, employees, and externally for client accounts.",
          vi: "- Mô tả những thách thức, cả trong nội bộ doanh nghiệp, nhân viên và bên ngoài đối với tài khoản khách hàng. Mô tả các công thức, chi tiết trong nội bộ doanh nghiệp, nhân viên và bên ngoài đối với đồng khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Innovation (20 grade)",
        vi: "Mục 2: Đổi mới (20 điểm)",
      },
      description: [
        {
          en: "- Describe any creative thinking that inspired the industry or other businesses as a whole to think more innovatively.",
          vi: "- Mô tả bất kỳ tư duy sáng tạo nào đã truyền cảm hứng cho toàn ngành hoặc các doanh nghiệp khác suy nghĩ đổi mới hơn. Mô tả bất kỳ tư duy sáng tạo nào đã truyền cảm hứng cho toàn ngành hoặc các doanh nghiệp khác suy nghĩ đổi mới hơn.",
        },
        {
          en: "- Provide any new initiatives/thought leadership that the agency introduced and the impact and success of how these have led the industry.",
          vi: "- Cung cấp bất kỳ sáng kiến/tư tưởng lãnh đạo mới nào mà Agency đã đưa ra cũng như tác động và thành công của cách những sáng kiến/tư tưởng lãnh đạo này đã dẫn dắt ngành. Cung cấp bất kỳ sáng kiến/tư tưởng lãnh đạo đạo mới nào mà Agency đã đưa ra cũng như tác động và thành công và những sáng kiến/tư tưởng tượng lãnh đạo đạo này đã dẫn dắt ngành.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 3: Creativity (15 grade)",
        vi: "Mục 3: Sáng tạo (15 điểm)",
      },
      description: [
        {
          en: "- Highlights of some best work produced by the agency, and how it has been conducted (solely or in partnership).",
          vi: "- Điểm nổi bật của một số công việc tốt nhất do Agency thực hiện và cách nó được thực hiện (riêng hoặc hợp tác). Điểm nổi bật của một số công việc tốt nhất do Agency thực hiện và cách nó được thực hiện (riêng hoặc hợp tác).",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 4: Culture (10 grade)",
        vi: "Mục 4: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- Describe how your company applies the culture to that artwork, and how to convey the message that is relevant to the culture.",
          vi: "- Mô tả cách công ty của bạn áp dụng văn hóa vào tác phẩm nghệ thuật đó và cách truyền tải thông điệp phù hợp với văn hóa đó. Mô tả cách bạn áp dụng văn hóa vào sản phẩm nghệ thuật và cách truyền tải thông điệp phù hợp với văn hóa đó.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Talent Management (10 grade)",
        vi: "Mục 5: Quản lý nhân tài (10 điểm)",
      },
      description: [
        {
          en: "- Key hires and promotions, staff turnover percentage change, and diversity.",
          vi: "- Tuyển dụng và thăng chức chủ chốt, thay đổi tỷ lệ luân chuyển nhân viên và sự đa dạng. Tuyển dụng và tăng cường chức năng, thay đổi tỷ lệ chuyển đổi nhân viên và đa dạng.",
        },
        {
          en: "- Provide a brief overview of staff development programs, inclusion, and diversity and its implementation and success.",
          vi: "- Cung cấp một cái nhìn tổng quan ngắn gọn về các chương trình phát triển nhân viên, sự hòa nhập và tính đa dạng cũng như việc thực hiện và thành công của chương trình đó. Cung cấp một cái nhìn tổng thể ngắn gọn về các chương trình phát triển nhân viên, sự hòa nhập và tính đa dạng cũng như việc thực hiện và thành công của chương trình đó.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 6: Contribution (5 grade)",
        vi: "Mục 6: Đóng góp (5 điểm)",
      },
      description: [
        {
          en: "- What industry initiatives and programs did you support and how? What industry and other community activities are the agency involved in?",
          vi: "- Agency đã hỗ trợ những sáng kiến và chương trình nào trong ngành và bằng cách nào? Agency tham gia vào ngành nào và các hoạt động cộng đồng nào? Bạn đã hỗ trợ những sáng kiến, chương trình nào trong chuyên ngành và bằng cách nào?",
        },
        {
          en: "- Other contributions to industry advancement and provide examples of how your agency has contributed to improving the image of advertising in your marketplace and the quality of creativity.",
          vi: "- Những đóng góp khác cho sự phát triển của ngành và cung cấp các ví dụ về cách agency của bạn đã góp phần cải thiện hình ảnh quảng cáo trên thị trường của bạn cũng như chất lượng của sự sáng tạo. Những người đóng góp khác cho sự phát triển của ngành và cung cấp các ví dụ về cách Agency của bạn đã góp phần cải thiện hình ảnh quảng cáo trên thị trường của bạn cũng như chất lượng của sự sáng tạo.",
        },
      ],
      points: 5,
    },
  ],
  "pr-agency-cua-nam": [
    {
      title: {
        en: "Section 1: Mission Statement (10 grade)",
        vi: "Mục 1: Tuyên bố sứ mệnh (10 điểm)",
      },
      description: [
        {
          en: "- Describe the agency’s vision and objectives. What was the strategy and how did the agency accomplish its objectives with evidence of your success and achievements.",
          vi: "- Mô tả tầm nhìn và mục tiêu của Agency. Chiến lược là gì và Agency đã hoàn thành mục tiêu của mình như thế nào với bằng chứng về sự thành công và thành tích của bạn.",
        },
        {
          en: "- Describe the challenges, both internally as a business, employees, and externally for client accounts.",
          vi: "- Mô tả những thách thức, cả trong nội bộ doanh nghiệp, nhân viên và bên ngoài đối với tài khoản khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Innovation (20 grade)",
        vi: "Mục 2: Đổi mới (20 điểm)",
      },
      description: [
        {
          en: "- Describe any creative thinking that inspired the industry or other businesses as a whole to think more innovatively.",
          vi: "- Mô tả bất kỳ tư duy sáng tạo nào đã truyền cảm hứng cho toàn ngành hoặc các doanh nghiệp khác suy nghĩ đổi mới hơn.",
        },
        {
          en: "- Provide any new initiatives/thought leadership that the agency introduced and the impact and success of how these have led the industry.",
          vi: "- Cung cấp bất kỳ sáng kiến/tư tưởng lãnh đạo mới nào mà Agency đã đưa ra cũng như tác động và thành công của cách những sáng kiến/tư tưởng lãnh đạo này đã dẫn dắt ngành.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 3: Creativity (15 grade)",
        vi: "Mục 3: Sáng tạo (15 điểm)",
      },
      description: [
        {
          en: "- Highlights of some best work produced by the agency, and how it has been conducted (solely or in partnership).",
          vi: "- Điểm nổi bật của một số công việc tốt nhất do Agency thực hiện và cách nó được thực hiện (riêng hoặc hợp tác).",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 4: Culture (10 grade)",
        vi: "Mục 4: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- Describe how your company applies the culture to that artwork, and how to convey the message that is relevant to the culture.",
          vi: "- Mô tả cách công ty của bạn áp dụng văn hóa vào tác phẩm nghệ thuật đó và cách truyền tải thông điệp phù hợp với văn hóa đó.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Talent Management (10 grade)",
        vi: "Mục 5: Quản lý nhân tài (10 điểm)",
      },
      description: [
        {
          en: "- Key hires and promotions, staff turnover percentage change, and diversity.",
          vi: "- Tuyển dụng và thăng chức chủ chốt, thay đổi tỷ lệ luân chuyển nhân viên và sự đa dạng.",
        },
        {
          en: "- Provide a brief overview of staff development programs, inclusion, and diversity and its implementation and success.",
          vi: "- Cung cấp một cái nhìn tổng quan ngắn gọn về các chương trình phát triển nhân viên, sự hòa nhập và tính đa dạng cũng như việc thực hiện và thành công của chương trình đó.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 6: Contribution (5 grade)",
        vi: "Mục 6: Đóng góp (5 điểm)",
      },
      description: [
        {
          en: "- What industry initiatives and programs did you support and how? What industry and other community activities are the agency involved in?",
          vi: "- Bạn đã hỗ trợ những sáng kiến và chương trình nào trong ngành và bằng cách nào? Agency này tham gia vào ngành nào và các hoạt động cộng đồng nào?",
        },
        {
          en: "- Other contributions to industry advancement and provide examples of how your agency has contributed to improving the image of advertising in your marketplace and the quality of creativity.",
          vi: "- Những đóng góp khác cho sự phát triển của ngành và cung cấp các ví dụ về cách agency của bạn đã góp phần cải thiện hình ảnh quảng cáo trên thị trường của bạn cũng như chất lượng của sự sáng tạo.",
        },
      ],
      points: 5,
    },
  ],
  "event-agency-cua-nam": [
    {
      title: {
        en: "Section 1: Mission Statement (10 grade)",
        vi: "Mục 1: Tuyên bố sứ mệnh (10 điểm)",
      },
      description: [
        {
          en: "- Describe the agency’s vision and objectives. What was the strategy and how did the agency accomplish its objectives with evidence of your success and achievements.",
          vi: "- Mô tả tầm nhìn và mục tiêu của Agency. Chiến lược là gì và Agency đã hoàn thành mục tiêu của mình như thế nào với bằng chứng về sự thành công và thành tích của bạn.",
        },
        {
          en: "- Describe the challenges, both internally as a business, employees, and externally for client accounts.",
          vi: "- Mô tả những thách thức, cả trong nội bộ doanh nghiệp, nhân viên và bên ngoài đối với tài khoản khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Innovation (20 grade)",
        vi: "Mục 2: Đổi mới (20 điểm)",
      },
      description: [
        {
          en: "- Describe any creative thinking that inspired the industry or other businesses as a whole to think more innovatively.",
          vi: "- Mô tả bất kỳ tư duy sáng tạo nào đã truyền cảm hứng cho toàn ngành hoặc các doanh nghiệp khác suy nghĩ đổi mới hơn.",
        },
        {
          en: "- Provide any new initiatives/thought leadership that the agency introduced and the impact and success of how these have led the industry.",
          vi: "- Cung cấp bất kỳ sáng kiến/tư tưởng lãnh đạo mới nào mà Agency đã đưa ra cũng như tác động và thành công của cách những sáng kiến/tư tưởng lãnh đạo này đã dẫn dắt ngành.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 3: Creativity (15 grade)",
        vi: "Mục 3: Sáng tạo (15 điểm)",
      },
      description: [
        {
          en: "- Highlights of some best work produced by the agency, and how it has been conducted (solely or in partnership).",
          vi: "- Điểm nổi bật của một số công việc tốt nhất do Agency thực hiện và cách nó được thực hiện (riêng hoặc hợp tác).",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 4: Culture (10 grade)",
        vi: "Mục 4: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- Describe how your company applies the culture to that artwork, and how to convey the message that is relevant to the culture.",
          vi: "- Mô tả cách công ty của bạn áp dụng văn hóa vào tác phẩm nghệ thuật đó và cách truyền tải thông điệp phù hợp với văn hóa đó.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Talent Management (10 grade)",
        vi: "Mục 5: Quản lý nhân tài (10 điểm)",
      },
      description: [
        {
          en: "- Key hires and promotions, staff turnover percentage change, and diversity.",
          vi: "- Tuyển dụng và thăng chức chủ chốt, thay đổi tỷ lệ luân chuyển nhân viên và sự đa dạng.",
        },
        {
          en: "- Provide a brief overview of staff development programs, inclusion, and diversity and its implementation and success.",
          vi: "- Cung cấp một cái nhìn tổng quan ngắn gọn về các chương trình phát triển nhân viên, sự hòa nhập và tính đa dạng cũng như việc thực hiện và thành công của chương trình đó.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 6: Contribution (5 grade)",
        vi: "Mục 6: Đóng góp (5 điểm)",
      },
      description: [
        {
          en: "- What industry initiatives and programs did you support and how? What industry and other community activities are the agency involved in?",
          vi: "- Bạn đã hỗ trợ những sáng kiến và chương trình nào trong ngành và bằng cách nào? Agency này tham gia vào ngành nào và các hoạt động cộng đồng nào?",
        },
        {
          en: "- Other contributions to industry advancement and provide examples of how your agency has contributed to improving the image of advertising in your marketplace and the quality of creativity.",
          vi: "- Những đóng góp khác cho sự phát triển của ngành và cung cấp các ví dụ về cách agency của bạn đã góp phần cải thiện hình ảnh quảng cáo trên thị trường của bạn cũng như chất lượng của sự sáng tạo.",
        },
      ],
      points: 5,
    },
  ],
  "production-house-cua-nam": [
    {
      title: {
        en: "Section 1: Mission Statement (10 grade)",
        vi: "Mục 1: Tuyên bố sứ mệnh (10 điểm)",
      },
      description: [
        {
          en: "- Describe the agency’s vision and objectives. What was the strategy and how did the agency accomplish its objectives with evidence of your success and achievements.",
          vi: "- Mô tả tầm nhìn và mục tiêu của Agency. Chiến lược là gì và Agency đã hoàn thành mục tiêu của mình như thế nào với bằng chứng về sự thành công và thành tích của bạn.",
        },
        {
          en: "- Describe the challenges, both internally as a business, employees, and externally for client accounts.",
          vi: "- Mô tả những thách thức, cả trong nội bộ doanh nghiệp, nhân viên và bên ngoài đối với tài khoản khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Innovation (20 grade)",
        vi: "Mục 2: Đổi mới (20 điểm)",
      },
      description: [
        {
          en: "- Describe any creative thinking that inspired the industry or other businesses as a whole to think more innovatively.",
          vi: "- Mô tả bất kỳ tư duy sáng tạo nào đã truyền cảm hứng cho toàn ngành hoặc các doanh nghiệp khác suy nghĩ đổi mới hơn.",
        },
        {
          en: "- Provide any new initiatives/thought leadership that the agency introduced and the impact and success of how these have led the industry.",
          vi: "- Cung cấp bất kỳ sáng kiến/tư tưởng lãnh đạo mới nào mà Agency đã đưa ra cũng như tác động và thành công của cách những sáng kiến/tư tưởng lãnh đạo này đã dẫn dắt ngành.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 3: Creativity (30 grade)",
        vi: "Mục 3: Sáng tạo (30 điểm)",
      },
      description: [
        {
          en: "- Highlights of some best work produced by the agency, and how it has been conducted (solely or in partnership). Showreel for production house for the year and your top 7-10 productions. Please clearly list agency partners. If production house supplies creative please highlight clearly in your submission.",
          vi: "- Điểm nổi bật của một số công việc tốt nhất do Agency thực hiện và cách nó được thực hiện (riêng hoặc hợp tác). Hiển thị cuộn phim cho ngôi nhà tự hào trong năm và 7-10 tác phẩm hàng đầu của bạn. Hãy liệt kê rõ ràng các đối tác agency. Nếu nhà sản xuất cung cấp sáng tạo, vui lòng đánh dấu rõ ràng trong bài gửi của bạn.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 4: Culture (10 grade)",
        vi: "Mục 4: Văn hóa (10 điểm)",
      },
      description: [
        {
          en: "- Describe how your company applies the culture to that artwork, and how to convey the message that is relevant to the culture.",
          vi: "- Mô tả cách công ty của bạn áp dụng văn hóa vào tác phẩm nghệ thuật đó và cách truyền tải thông điệp phù hợp với văn hóa đó.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Talent Management (10 grade)",
        vi: "Mục 5: Quản lý nhân tài (10 điểm)",
      },
      description: [
        {
          en: "- Key hires and promotions, staff turnover percentage change, and diversity.",
          vi: "- Tuyển dụng và thăng chức chủ chốt, thay đổi tỷ lệ luân chuyển nhân viên và sự đa dạng.",
        },
        {
          en: "- Provide a brief overview of staff development programs, inclusion, and diversity and its implementation and success.",
          vi: "- Cung cấp một cái nhìn tổng quan ngắn gọn về các chương trình phát triển nhân viên, sự hòa nhập và tính đa dạng cũng như việc thực hiện và thành công của chương trình đó.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 6: Contribution (20 grade)",
        vi: "Mục 6: Đóng góp (20 điểm)",
      },
      description: [
        {
          en: "- What industry initiatives and programs did you support and how? What industry and other community activities are the agency involved in?",
          vi: "- Bạn đã hỗ trợ những sáng kiến và chương trình nào trong ngành và bằng cách nào? Agency này tham gia vào ngành nào và các hoạt động cộng đồng nào?",
        },
        {
          en: "- Other contributions to industry advancement and provide examples of how your agency has contributed to improving the image of advertising in your marketplace and the quality of creativity.",
          vi: "- Những đóng góp khác cho sự phát triển của ngành và cung cấp các ví dụ về cách agency của bạn đã góp phần cải thiện hình ảnh quảng cáo trên thị trường của bạn cũng như chất lượng của sự sáng tạo. Ngoài ra, hãy nêu bật sự hợp tác của bạn với các nhà cung cấp địa phương, điều này sẽ được coi là đóng góp cho ngành và nêu rõ lý do tại sao?",
        },
      ],
      points: 20,
    },
  ],
  "nen-tang-quang-cao-cua-nam": [
    {
      title: {
        en: "Section 1: Background (5 grade)",
        vi: "Mục 1: Bối cảnh (5 điểm)",
      },
      description: [
        {
          en: "- Provide career history (should be team history) of last 5 senior roles including company name, location, title, and dates.",
          vi: "- Cung cấp lịch sử nghề nghiệp (nên là lịch sử nhóm) của 5 vai trò cấp cao gần đây nhất bao gồm tên công ty, địa điểm, chức danh và ngày tháng.",
        },
        {
          en: "- Current responsibilities to include the size of operation, number of direct reports, etc.",
          vi: "- Trách nhiệm hiện tại bao gồm quy mô hoạt động, số lượng báo cáo trực tiếp, v.v.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 2: Business Development (10 grade)",
        vi: "Mục 2: Phát triển kinh doanh (10 điểm)",
      },
      description: [
        {
          en: "- Please provide details of existing clients serviced, details of new business wins, and examples of how the individual has contributed to both the client and the agency.",
          vi: "- Vui lòng cung cấp thông tin chi tiết về các khách hàng hiện tại đã được phục vụ, chi tiết về những thắng lợi kinh doanh mới và ví dụ về cách cá nhân đó đã đóng góp cho cả khách hàng và agency.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 3: Strategic Skills (20 grade)",
        vi: "Mục 3: Kỹ năng chiến lược (20 điểm)",
      },
      description: [
        {
          en: "- Please demonstrate insights into the core business issues and how they relate to the marketing and communications subsequently developed.",
          vi: "- Vui lòng chứng minh những hiểu biết sâu sắc về các vấn đề kinh doanh cốt lõi và cách chúng liên quan đến hoạt động tiếp thị và truyền thông được phát triển sau đó.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Innovation (20 grade)",
        vi: "Mục 4: Đổi mới (20 điểm)",
      },
      description: [
        {
          en: "- Provide examples of problem-solving approaches and the ability to lead in the production of and/or develop creative solutions that drive results.",
          vi: "- Cung cấp ví dụ về các phương pháp giải quyết vấn đề và khả năng lãnh đạo trong việc tạo ra và/hoặc phát triển các giải pháp sáng tạo mang lại kết quả.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Media Neutrality (15 grade)",
        vi: "Mục 5: Tính trung lập của phương tiện truyền thông (15 điểm)",
      },
      description: [
        {
          en: `- Demonstrate examples of the nominee's ability to intelligently recommend and explore new and diverse platform options.`,
          vi: "- Thể hiện các ví dụ về khả năng của người được đề cử trong việc đề xuất và khám phá các lựa chọn nền tảng mới và đa dạng một cách thông minh.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 6: Performance (20 grade)",
        vi: "Mục 6: Hiệu suất (20 điểm)",
      },
      description: [
        {
          en: `- Describe the campaign's statistics that have a successful result(over KPIs)`,
          vi: "- Mô tả số liệu thống kê của chiến dịch có kết quả thành công (trên KPI)",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 7: Client's Testimonial (10 grade)",
        vi: "Mục 7: Công nhận từ khách hàng (10 điểm)",
      },
      description: [
        {
          en: "- Client testimonials will be required as part of supporting material. (with signature or provide a copy of the email and title of the client)",
          vi: "- Công nhận của khách hàng sẽ được yêu cầu như một phần của tài liệu hỗ trợ. (có chữ ký hoặc cung cấp bản sao email và chức danh của khách hàng)",
        },
      ],
      points: 10,
    },
  ],
  "thuong-hieu-duoc-yeu-thich-nhat": [
    {
      title: {
        en: "Section 1: Vision (10 grade)",
        vi: "Mục 1: Tầm nhìn (10 điểm)",
      },
      description: [
        {
          en: `- Describe the company's brand vision and mission and how these align with the values and goals of the business.`,
          vi: "- Mô tả tầm nhìn và sứ mệnh thương hiệu của công ty cũng như cách chúng phù hợp với các giá trị và mục tiêu của doanh nghiệp.",
        },
        {
          en: "- What objectives were set and how were they achieved?",
          vi: "- Những mục tiêu nào đã được đặt ra và chúng đã đạt được chúng như thế nào.",
        },
        {
          en: "- Describe the challenges and how the company overcame them, both internally as a business, for its employees, and externally for its customers.",
          vi: "- Mô tả những thách thức và cách công ty vượt qua chúng, cả trong nội bộ doanh nghiệp, đối với nhân viên và bên ngoài đối với khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Insight & Strategy (25 grade)",
        vi: "Mục 2: Cái nhìn sâu sắc & Chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Describe the business strategy and challenges faced including examples of where the business strategy and the brand and marketing contribution had led to its success.",
          vi: "- Mô tả chiến lược kinh doanh và những thách thức phải đối mặt, bao gồm các ví dụ về chiến lược kinh doanh cũng như sự đóng góp của thương hiệu và tiếp thị đã dẫn đến thành công.",
        },
        {
          en: "- What strategies have been implemented to enhance brand-building efforts to achieve the desired brand experience. Provide examples that demonstrate measures in place to evaluate effectiveness, and how the brand can sustain the efforts to meet its objectives.",
          vi: "- Những chiến lược nào đã được thực hiện để nâng cao nỗ lực xây dựng thương hiệu nhằm đạt được trải nghiệm thương hiệu mong muốn. Cung cấp các ví dụ minh họa các biện pháp được áp dụng để đánh giá hiệu quả và cách thương hiệu có thể duy trì những nỗ lực để đạt được mục tiêu của mình.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Creativity & Innovation (25 grade)",
        vi: "Mục 3: Sáng tạo & Đổi mới (25 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of brand-building activities, or initiatives during the eligibility period.",
          vi: "- Cung cấp cái nhìn tổng quan về các hoạt động hoặc sáng kiến xây dựng thương hiệu trong thời gian đủ điều kiện.",
        },
        {
          en: "- Describe any innovative initiatives that had driven business growth and performance such as new growth opportunities (new customer segments, new market, new channels, etc.).",
          vi: "- Mô tả bất kỳ sáng kiến đổi mới nào đã thúc đẩy tăng trưởng và hiệu quả hoạt động kinh doanh, chẳng hạn như các cơ hội tăng trưởng mới (phân khúc khách hàng mới, thị trường mới, kênh mới, v.v.).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Success (20 grade)",
        vi: "Mục 4: Thành công (20 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the brand's success including brand awareness, increased sales, and sustainable competitive advantage.`,
          vi: "- Cung cấp cái nhìn tổng quan về sự thành công của thương hiệu bao gồm nhận thức về thương hiệu, tăng doanh số bán hàng và lợi thế cạnh tranh bền vững.",
        },
        {
          en: "- Provide evidence of marketing successes across brand planning, consumer insight, communication strategy, innovation, and campaign activation, as well as great use of all elements of the marketing mix across brands and campaigns.",
          vi: "- Cung cấp bằng chứng về sự thành công của hoạt động tiếp thị trong quá trình lập kế hoạch thương hiệu, hiểu biết sâu sắc về người tiêu dùng, chiến lược truyền thông, đổi mới và kích hoạt chiến dịch cũng như việc sử dụng hiệu quả tất cả các yếu tố của tổ hợp tiếp thị giữa các thương hiệu và chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Culture (5 grade)",
        vi: "Mục 5: Văn hóa (5 điểm)",
      },
      description: [
        {
          en: `- The brand's image or message has a cultural combination.`,
          vi: "- Hình ảnh hoặc thông điệp của thương hiệu có sự kết hợp văn hóa.",
        },
        {
          en: "- The application of Vietnamese culture, elements of Vietnamese traditions and customs, and morals of Vietnamese people in communication and advertising activities.",
          vi: "- Ứng dụng văn hóa Việt Nam, các yếu tố truyền thống, phong tục Việt Nam và đạo đức của người Việt Nam trong hoạt động truyền thông, quảng cáo.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 6: Contribution (15 grade)",
        vi: "Mục 6: Sự đóng góp (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the company had contributed to the wider public including any CSR activities that the brand was involved with to show leadership and contribution.",
          vi: "- Mô tả cách công ty đã đóng góp cho công chúng rộng rãi hơn, bao gồm mọi hoạt động CSR mà thương hiệu tham gia để thể hiện khả năng lãnh đạo và đóng góp.",
        },
        {
          en: "- Provide examples where the company has shown exceptional commitment to deliver the very best in their sector, demonstrate best practices, and show an innovative and creative approach to the work they deliver.",
          vi: "- Đưa ra những ví dụ trong đó công ty đã thể hiện cam kết đặc biệt trong việc mang lại những điều tốt nhất trong lĩnh vực của họ, thể hiện phương pháp thực hành tốt nhất và thể hiện cách tiếp cận đổi mới và sáng tạo đối với công việc mà họ thực hiện.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Case videos created to inspire the country to vote for you are encouraged. In addition to the written submission, any items that are required to be kept confidential please highlight in yellow.",
          vi: "Khuyến khích sử dụng video trường hợp được tạo ra để truyền cảm hứng cho đất nước bỏ phiếu cho bạn. Ngoài bài nộp bằng văn bản, vui lòng đánh dấu màu vàng bất kỳ mục nào cần được giữ bí mật.",
        },
      ],
    },
  ],
  "thuong-hieu-my-pham-lam-dep-cua-nam": [
    {
      title: {
        en: "Section 1: Vision (10 grade)",
        vi: "Mục 1: Tầm nhìn (10 điểm)",
      },
      description: [
        {
          en: `- Describe the company's brand vision and mission and how these align with the values and goals of the business.`,
          vi: "- Mô tả tầm nhìn và sứ mệnh thương hiệu của công ty cũng như cách chúng phù hợp với các giá trị và mục tiêu của doanh nghiệp.",
        },
        {
          en: "- What objectives were set and how were they achieved?",
          vi: "- Những mục tiêu nào đã được đặt ra và chúng đã đạt được chúng như thế nào.",
        },
        {
          en: "- Describe the challenges and how the company overcame them, both internally as a business, for its employees, and externally for its customers.",
          vi: "- Mô tả những thách thức và cách công ty vượt qua chúng, cả trong nội bộ doanh nghiệp, đối với nhân viên và bên ngoài đối với khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Insight & Strategy (25 grade)",
        vi: "Mục 2: Cái nhìn sâu sắc & Chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Describe the business strategy and challenges faced including examples of where the business strategy and the brand and marketing contribution had led to its success.",
          vi: "- Mô tả chiến lược kinh doanh và những thách thức phải đối mặt, bao gồm các ví dụ về chiến lược kinh doanh cũng như sự đóng góp của thương hiệu và tiếp thị đã dẫn đến thành công.",
        },
        {
          en: "- What strategies have been implemented to enhance brand-building efforts to achieve the desired brand experience. Provide examples that demonstrate measures in place to evaluate effectiveness, and how the brand can sustain the efforts to meet its objectives.",
          vi: "- Những chiến lược nào đã được thực hiện để nâng cao nỗ lực xây dựng thương hiệu nhằm đạt được trải nghiệm thương hiệu mong muốn. Cung cấp các ví dụ minh họa các biện pháp được áp dụng để đánh giá hiệu quả và cách thương hiệu có thể duy trì những nỗ lực để đạt được mục tiêu của mình.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Creativity & Innovation (25 grade)",
        vi: "Mục 3: Sáng tạo & Đổi mới (25 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of brand-building activities, or initiatives during the eligibility period.",
          vi: "- Cung cấp cái nhìn tổng quan về các hoạt động hoặc sáng kiến xây dựng thương hiệu trong thời gian đủ điều kiện.",
        },
        {
          en: "- Describe any innovative initiatives that had driven business growth and performance such as new growth opportunities (new customer segments, new market, new channels, etc.).",
          vi: "- Mô tả bất kỳ sáng kiến đổi mới nào đã thúc đẩy tăng trưởng và hiệu quả hoạt động kinh doanh, chẳng hạn như các cơ hội tăng trưởng mới (phân khúc khách hàng mới, thị trường mới, kênh mới, v.v.).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Success (20 grade)",
        vi: "Mục 4: Thành công (20 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the brand's success including brand awareness, increased sales, and sustainable competitive advantage.`,
          vi: "- Cung cấp cái nhìn tổng quan về sự thành công của thương hiệu bao gồm nhận thức về thương hiệu, tăng doanh số bán hàng và lợi thế cạnh tranh bền vững.",
        },
        {
          en: "- Provide evidence of marketing successes across brand planning, consumer insight, communication strategy, innovation, and campaign activation, as well as great use of all elements of the marketing mix across brands and campaigns.",
          vi: "- Cung cấp bằng chứng về sự thành công của hoạt động tiếp thị trong quá trình lập kế hoạch thương hiệu, hiểu biết sâu sắc về người tiêu dùng, chiến lược truyền thông, đổi mới và kích hoạt chiến dịch cũng như việc sử dụng hiệu quả tất cả các yếu tố của tổ hợp tiếp thị giữa các thương hiệu và chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Culture (5 grade)",
        vi: "Mục 5: Văn hóa (5 điểm)",
      },
      description: [
        {
          en: `- The brand's image or message has a cultural combination.`,
          vi: "- Hình ảnh hoặc thông điệp của thương hiệu có sự kết hợp văn hóa.",
        },
        {
          en: "- The application of Vietnamese culture, elements of Vietnamese traditions and customs, and morals of Vietnamese people in communication and advertising activities.",
          vi: "- Ứng dụng văn hóa Việt Nam, các yếu tố truyền thống, phong tục Việt Nam và đạo đức của người Việt Nam trong hoạt động truyền thông, quảng cáo.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 6: Contribution (15 grade)",
        vi: "Mục 6: Sự đóng góp (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the company had contributed to the wider public including any CSR activities that the brand was involved with to show leadership and contribution.",
          vi: "- Mô tả cách công ty đã đóng góp cho công chúng rộng rãi hơn, bao gồm mọi hoạt động CSR mà thương hiệu tham gia để thể hiện khả năng lãnh đạo và đóng góp.",
        },
        {
          en: "- Provide examples where the company has shown exceptional commitment to deliver the very best in their sector, demonstrate best practices, and show an innovative and creative approach to the work they deliver.",
          vi: "- Đưa ra những ví dụ trong đó công ty đã thể hiện cam kết đặc biệt trong việc mang lại những điều tốt nhất trong lĩnh vực của họ, thể hiện phương pháp thực hành tốt nhất và thể hiện cách tiếp cận đổi mới và sáng tạo đối với công việc mà họ thực hiện.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Case videos created to inspire the country to vote for you are encouraged. In addition to the written submission, any items that are required to be kept confidential please highlight in yellow.",
          vi: "Khuyến khích sử dụng video trường hợp được tạo ra để truyền cảm hứng cho đất nước bỏ phiếu cho bạn. Ngoài bài nộp bằng văn bản, vui lòng đánh dấu màu vàng bất kỳ mục nào cần được giữ bí mật.",
        },
      ],
    },
  ],
  "thuong-hieu-bao-hiem-cua-nam": [
    {
      title: {
        en: "Section 1: Vision (10 grade)",
        vi: "Mục 1: Tầm nhìn (10 điểm)",
      },
      description: [
        {
          en: `- Describe the company's brand vision and mission and how these align with the values and goals of the business.`,
          vi: "- Mô tả tầm nhìn và sứ mệnh thương hiệu của công ty cũng như cách chúng phù hợp với các giá trị và mục tiêu của doanh nghiệp.",
        },
        {
          en: "- What objectives were set and how were they achieved?",
          vi: "- Những mục tiêu nào đã được đặt ra và chúng đã đạt được chúng như thế nào.",
        },
        {
          en: "- Describe the challenges and how the company overcame them, both internally as a business, for its employees, and externally for its customers.",
          vi: "- Mô tả những thách thức và cách công ty vượt qua chúng, cả trong nội bộ doanh nghiệp, đối với nhân viên và bên ngoài đối với khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Insight & Strategy (25 grade)",
        vi: "Mục 2: Cái nhìn sâu sắc & Chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Describe the business strategy and challenges faced including examples of where the business strategy and the brand and marketing contribution had led to its success.",
          vi: "- Mô tả chiến lược kinh doanh và những thách thức phải đối mặt, bao gồm các ví dụ về chiến lược kinh doanh cũng như sự đóng góp của thương hiệu và tiếp thị đã dẫn đến thành công.",
        },
        {
          en: "- What strategies have been implemented to enhance brand-building efforts to achieve the desired brand experience. Provide examples that demonstrate measures in place to evaluate effectiveness, and how the brand can sustain the efforts to meet its objectives.",
          vi: "- Những chiến lược nào đã được thực hiện để nâng cao nỗ lực xây dựng thương hiệu nhằm đạt được trải nghiệm thương hiệu mong muốn. Cung cấp các ví dụ minh họa các biện pháp được áp dụng để đánh giá hiệu quả và cách thương hiệu có thể duy trì những nỗ lực để đạt được mục tiêu của mình.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Creativity & Innovation (25 grade)",
        vi: "Mục 3: Sáng tạo & Đổi mới (25 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of brand-building activities, or initiatives during the eligibility period.",
          vi: "- Cung cấp cái nhìn tổng quan về các hoạt động hoặc sáng kiến xây dựng thương hiệu trong thời gian đủ điều kiện.",
        },
        {
          en: "- Describe any innovative initiatives that had driven business growth and performance such as new growth opportunities (new customer segments, new market, new channels, etc.).",
          vi: "- Mô tả bất kỳ sáng kiến đổi mới nào đã thúc đẩy tăng trưởng và hiệu quả hoạt động kinh doanh, chẳng hạn như các cơ hội tăng trưởng mới (phân khúc khách hàng mới, thị trường mới, kênh mới, v.v.).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Success (20 grade)",
        vi: "Mục 4: Thành công (20 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the brand's success including brand awareness, increased sales, and sustainable competitive advantage.`,
          vi: "- Cung cấp cái nhìn tổng quan về sự thành công của thương hiệu bao gồm nhận thức về thương hiệu, tăng doanh số bán hàng và lợi thế cạnh tranh bền vững.",
        },
        {
          en: "- Provide evidence of marketing successes across brand planning, consumer insight, communication strategy, innovation, and campaign activation, as well as great use of all elements of the marketing mix across brands and campaigns.",
          vi: "- Cung cấp bằng chứng về sự thành công của hoạt động tiếp thị trong quá trình lập kế hoạch thương hiệu, hiểu biết sâu sắc về người tiêu dùng, chiến lược truyền thông, đổi mới và kích hoạt chiến dịch cũng như việc sử dụng hiệu quả tất cả các yếu tố của tổ hợp tiếp thị giữa các thương hiệu và chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Culture (5 grade)",
        vi: "Mục 5: Văn hóa (5 điểm)",
      },
      description: [
        {
          en: `- The brand's image or message has a cultural combination.`,
          vi: "- Hình ảnh hoặc thông điệp của thương hiệu có sự kết hợp văn hóa.",
        },
        {
          en: "- The application of Vietnamese culture, elements of Vietnamese traditions and customs, and morals of Vietnamese people in communication and advertising activities.",
          vi: "- Ứng dụng văn hóa Việt Nam, các yếu tố truyền thống, phong tục Việt Nam và đạo đức của người Việt Nam trong hoạt động truyền thông, quảng cáo.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 6: Contribution (15 grade)",
        vi: "Mục 6: Sự đóng góp (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the company had contributed to the wider public including any CSR activities that the brand was involved with to show leadership and contribution.",
          vi: "- Mô tả cách công ty đã đóng góp cho công chúng rộng rãi hơn, bao gồm mọi hoạt động CSR mà thương hiệu tham gia để thể hiện khả năng lãnh đạo và đóng góp.",
        },
        {
          en: "- Provide examples where the company has shown exceptional commitment to deliver the very best in their sector, demonstrate best practices, and show an innovative and creative approach to the work they deliver.",
          vi: "- Đưa ra những ví dụ trong đó công ty đã thể hiện cam kết đặc biệt trong việc mang lại những điều tốt nhất trong lĩnh vực của họ, thể hiện phương pháp thực hành tốt nhất và thể hiện cách tiếp cận đổi mới và sáng tạo đối với công việc mà họ thực hiện.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Case videos created to inspire the country to vote for you are encouraged. In addition to the written submission, any items that are required to be kept confidential please highlight in yellow.",
          vi: "Khuyến khích sử dụng video trường hợp được tạo ra để truyền cảm hứng cho đất nước bỏ phiếu cho bạn. Ngoài bài nộp bằng văn bản, vui lòng đánh dấu màu vàng bất kỳ mục nào cần được giữ bí mật.",
        },
      ],
    },
  ],
  "thuong-hieu-ngan-hang-cua-nam": [
    {
      title: {
        en: "Section 1: Vision (10 grade)",
        vi: "Mục 1: Tầm nhìn (10 điểm)",
      },
      description: [
        {
          en: `- Describe the company's brand vision and mission and how these align with the values and goals of the business.`,
          vi: "- Mô tả tầm nhìn và sứ mệnh thương hiệu của công ty cũng như cách chúng phù hợp với các giá trị và mục tiêu của doanh nghiệp.",
        },
        {
          en: "- What objectives were set and how were they achieved?",
          vi: "- Những mục tiêu nào đã được đặt ra và chúng đã đạt được chúng như thế nào.",
        },
        {
          en: "- Describe the challenges and how the company overcame them, both internally as a business, for its employees, and externally for its customers.",
          vi: "- Mô tả những thách thức và cách công ty vượt qua chúng, cả trong nội bộ doanh nghiệp, đối với nhân viên và bên ngoài đối với khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Insight & Strategy (25 grade)",
        vi: "Mục 2: Cái nhìn sâu sắc & Chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Describe the business strategy and challenges faced including examples of where the business strategy and the brand and marketing contribution had led to its success.",
          vi: "- Mô tả chiến lược kinh doanh và những thách thức phải đối mặt, bao gồm các ví dụ về chiến lược kinh doanh cũng như sự đóng góp của thương hiệu và tiếp thị đã dẫn đến thành công.",
        },
        {
          en: "- What strategies have been implemented to enhance brand-building efforts to achieve the desired brand experience. Provide examples that demonstrate measures in place to evaluate effectiveness, and how the brand can sustain the efforts to meet its objectives.",
          vi: "- Những chiến lược nào đã được thực hiện để nâng cao nỗ lực xây dựng thương hiệu nhằm đạt được trải nghiệm thương hiệu mong muốn. Cung cấp các ví dụ minh họa các biện pháp được áp dụng để đánh giá hiệu quả và cách thương hiệu có thể duy trì những nỗ lực để đạt được mục tiêu của mình.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Creativity & Innovation (25 grade)",
        vi: "Mục 3: Sáng tạo & Đổi mới (25 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of brand-building activities, or initiatives during the eligibility period.",
          vi: "- Cung cấp cái nhìn tổng quan về các hoạt động hoặc sáng kiến xây dựng thương hiệu trong thời gian đủ điều kiện.",
        },
        {
          en: "- Describe any innovative initiatives that had driven business growth and performance such as new growth opportunities (new customer segments, new market, new channels, etc.).",
          vi: "- Mô tả bất kỳ sáng kiến đổi mới nào đã thúc đẩy tăng trưởng và hiệu quả hoạt động kinh doanh, chẳng hạn như các cơ hội tăng trưởng mới (phân khúc khách hàng mới, thị trường mới, kênh mới, v.v.).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Success (20 grade)",
        vi: "Mục 4: Thành công (20 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the brand's success including brand awareness, increased sales, and sustainable competitive advantage.`,
          vi: "- Cung cấp cái nhìn tổng quan về sự thành công của thương hiệu bao gồm nhận thức về thương hiệu, tăng doanh số bán hàng và lợi thế cạnh tranh bền vững.",
        },
        {
          en: "- Provide evidence of marketing successes across brand planning, consumer insight, communication strategy, innovation, and campaign activation, as well as great use of all elements of the marketing mix across brands and campaigns.",
          vi: "- Cung cấp bằng chứng về sự thành công của hoạt động tiếp thị trong quá trình lập kế hoạch thương hiệu, hiểu biết sâu sắc về người tiêu dùng, chiến lược truyền thông, đổi mới và kích hoạt chiến dịch cũng như việc sử dụng hiệu quả tất cả các yếu tố của tổ hợp tiếp thị giữa các thương hiệu và chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Culture (5 grade)",
        vi: "Mục 5: Văn hóa (5 điểm)",
      },
      description: [
        {
          en: `- The brand's image or message has a cultural combination.`,
          vi: "- Hình ảnh hoặc thông điệp của thương hiệu có sự kết hợp văn hóa.",
        },
        {
          en: "- The application of Vietnamese culture, elements of Vietnamese traditions and customs, and morals of Vietnamese people in communication and advertising activities.",
          vi: "- Ứng dụng văn hóa Việt Nam, các yếu tố truyền thống, phong tục Việt Nam và đạo đức của người Việt Nam trong hoạt động truyền thông, quảng cáo.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 6: Contribution (15 grade)",
        vi: "Mục 6: Sự đóng góp (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the company had contributed to the wider public including any CSR activities that the brand was involved with to show leadership and contribution.",
          vi: "- Mô tả cách công ty đã đóng góp cho công chúng rộng rãi hơn, bao gồm mọi hoạt động CSR mà thương hiệu tham gia để thể hiện khả năng lãnh đạo và đóng góp.",
        },
        {
          en: "- Provide examples where the company has shown exceptional commitment to deliver the very best in their sector, demonstrate best practices, and show an innovative and creative approach to the work they deliver.",
          vi: "- Đưa ra những ví dụ trong đó công ty đã thể hiện cam kết đặc biệt trong việc mang lại những điều tốt nhất trong lĩnh vực của họ, thể hiện phương pháp thực hành tốt nhất và thể hiện cách tiếp cận đổi mới và sáng tạo đối với công việc mà họ thực hiện.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Case videos created to inspire the country to vote for you are encouraged. In addition to the written submission, any items that are required to be kept confidential please highlight in yellow.",
          vi: "Khuyến khích sử dụng video trường hợp được tạo ra để truyền cảm hứng cho đất nước bỏ phiếu cho bạn. Ngoài bài nộp bằng văn bản, vui lòng đánh dấu màu vàng bất kỳ mục nào cần được giữ bí mật.",
        },
      ],
    },
  ],
  "thuong-hieu-o-to-cua-nam": [
    {
      title: {
        en: "Section 1: Vision (10 grade)",
        vi: "Mục 1: Tầm nhìn (10 điểm)",
      },
      description: [
        {
          en: `- Describe the company's brand vision and mission and how these align with the values and goals of the business.`,
          vi: "- Mô tả tầm nhìn và sứ mệnh thương hiệu của công ty cũng như cách chúng phù hợp với các giá trị và mục tiêu của doanh nghiệp.",
        },
        {
          en: "- What objectives were set and how were they achieved?",
          vi: "- Những mục tiêu nào đã được đặt ra và chúng đã đạt được chúng như thế nào.",
        },
        {
          en: "- Describe the challenges and how the company overcame them, both internally as a business, for its employees, and externally for its customers.",
          vi: "- Mô tả những thách thức và cách công ty vượt qua chúng, cả trong nội bộ doanh nghiệp, đối với nhân viên và bên ngoài đối với khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Insight & Strategy (25 grade)",
        vi: "Mục 2: Cái nhìn sâu sắc & Chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Describe the business strategy and challenges faced including examples of where the business strategy and the brand and marketing contribution had led to its success.",
          vi: "- Mô tả chiến lược kinh doanh và những thách thức phải đối mặt, bao gồm các ví dụ về chiến lược kinh doanh cũng như sự đóng góp của thương hiệu và tiếp thị đã dẫn đến thành công.",
        },
        {
          en: "- What strategies have been implemented to enhance brand-building efforts to achieve the desired brand experience. Provide examples that demonstrate measures in place to evaluate effectiveness, and how the brand can sustain the efforts to meet its objectives.",
          vi: "- Những chiến lược nào đã được thực hiện để nâng cao nỗ lực xây dựng thương hiệu nhằm đạt được trải nghiệm thương hiệu mong muốn. Cung cấp các ví dụ minh họa các biện pháp được áp dụng để đánh giá hiệu quả và cách thương hiệu có thể duy trì những nỗ lực để đạt được mục tiêu của mình.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Creativity & Innovation (25 grade)",
        vi: "Mục 3: Sáng tạo & Đổi mới (25 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of brand-building activities, or initiatives during the eligibility period.",
          vi: "- Cung cấp cái nhìn tổng quan về các hoạt động hoặc sáng kiến xây dựng thương hiệu trong thời gian đủ điều kiện.",
        },
        {
          en: "- Describe any innovative initiatives that had driven business growth and performance such as new growth opportunities (new customer segments, new market, new channels, etc.).",
          vi: "- Mô tả bất kỳ sáng kiến đổi mới nào đã thúc đẩy tăng trưởng và hiệu quả hoạt động kinh doanh, chẳng hạn như các cơ hội tăng trưởng mới (phân khúc khách hàng mới, thị trường mới, kênh mới, v.v.).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Success (20 grade)",
        vi: "Mục 4: Thành công (20 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the brand's success including brand awareness, increased sales, and sustainable competitive advantage.`,
          vi: "- Cung cấp cái nhìn tổng quan về sự thành công của thương hiệu bao gồm nhận thức về thương hiệu, tăng doanh số bán hàng và lợi thế cạnh tranh bền vững.",
        },
        {
          en: "- Provide evidence of marketing successes across brand planning, consumer insight, communication strategy, innovation, and campaign activation, as well as great use of all elements of the marketing mix across brands and campaigns.",
          vi: "- Cung cấp bằng chứng về sự thành công của hoạt động tiếp thị trong quá trình lập kế hoạch thương hiệu, hiểu biết sâu sắc về người tiêu dùng, chiến lược truyền thông, đổi mới và kích hoạt chiến dịch cũng như việc sử dụng hiệu quả tất cả các yếu tố của tổ hợp tiếp thị giữa các thương hiệu và chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Culture (5 grade)",
        vi: "Mục 5: Văn hóa (5 điểm)",
      },
      description: [
        {
          en: `- The brand's image or message has a cultural combination.`,
          vi: "- Hình ảnh hoặc thông điệp của thương hiệu có sự kết hợp văn hóa.",
        },
        {
          en: "- The application of Vietnamese culture, elements of Vietnamese traditions and customs, and morals of Vietnamese people in communication and advertising activities.",
          vi: "- Ứng dụng văn hóa Việt Nam, các yếu tố truyền thống, phong tục Việt Nam và đạo đức của người Việt Nam trong hoạt động truyền thông, quảng cáo.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 6: Contribution (15 grade)",
        vi: "Mục 6: Sự đóng góp (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the company had contributed to the wider public including any CSR activities that the brand was involved with to show leadership and contribution.",
          vi: "- Mô tả cách công ty đã đóng góp cho công chúng rộng rãi hơn, bao gồm mọi hoạt động CSR mà thương hiệu tham gia để thể hiện khả năng lãnh đạo và đóng góp.",
        },
        {
          en: "- Provide examples where the company has shown exceptional commitment to deliver the very best in their sector, demonstrate best practices, and show an innovative and creative approach to the work they deliver.",
          vi: "- Đưa ra những ví dụ trong đó công ty đã thể hiện cam kết đặc biệt trong việc mang lại những điều tốt nhất trong lĩnh vực của họ, thể hiện phương pháp thực hành tốt nhất và thể hiện cách tiếp cận đổi mới và sáng tạo đối với công việc mà họ thực hiện.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Case videos created to inspire the country to vote for you are encouraged. In addition to the written submission, any items that are required to be kept confidential please highlight in yellow.",
          vi: "Khuyến khích sử dụng video trường hợp được tạo ra để truyền cảm hứng cho đất nước bỏ phiếu cho bạn. Ngoài bài nộp bằng văn bản, vui lòng đánh dấu màu vàng bất kỳ mục nào cần được giữ bí mật.",
        },
      ],
    },
  ],
  "thuong-hieu-du-lich-lu-hanh-cua-nam": [
    {
      title: {
        en: "Section 1: Vision (10 grade)",
        vi: "Mục 1: Tầm nhìn (10 điểm)",
      },
      description: [
        {
          en: `- Describe the company's brand vision and mission and how these align with the values and goals of the business.`,
          vi: "- Mô tả tầm nhìn và sứ mệnh thương hiệu của công ty cũng như cách chúng phù hợp với các giá trị và mục tiêu của doanh nghiệp.",
        },
        {
          en: "- What objectives were set and how were they achieved?",
          vi: "- Những mục tiêu nào đã được đặt ra và chúng đã đạt được chúng như thế nào.",
        },
        {
          en: "- Describe the challenges and how the company overcame them, both internally as a business, for its employees, and externally for its customers.",
          vi: "- Mô tả những thách thức và cách công ty vượt qua chúng, cả trong nội bộ doanh nghiệp, đối với nhân viên và bên ngoài đối với khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Insight & Strategy (25 grade)",
        vi: "Mục 2: Cái nhìn sâu sắc & Chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Describe the business strategy and challenges faced including examples of where the business strategy and the brand and marketing contribution had led to its success.",
          vi: "- Mô tả chiến lược kinh doanh và những thách thức phải đối mặt, bao gồm các ví dụ về chiến lược kinh doanh cũng như sự đóng góp của thương hiệu và tiếp thị đã dẫn đến thành công.",
        },
        {
          en: "- What strategies have been implemented to enhance brand-building efforts to achieve the desired brand experience. Provide examples that demonstrate measures in place to evaluate effectiveness, and how the brand can sustain the efforts to meet its objectives.",
          vi: "- Những chiến lược nào đã được thực hiện để nâng cao nỗ lực xây dựng thương hiệu nhằm đạt được trải nghiệm thương hiệu mong muốn. Cung cấp các ví dụ minh họa các biện pháp được áp dụng để đánh giá hiệu quả và cách thương hiệu có thể duy trì những nỗ lực để đạt được mục tiêu của mình.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Creativity & Innovation (25 grade)",
        vi: "Mục 3: Sáng tạo & Đổi mới (25 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of brand-building activities, or initiatives during the eligibility period.",
          vi: "- Cung cấp cái nhìn tổng quan về các hoạt động hoặc sáng kiến xây dựng thương hiệu trong thời gian đủ điều kiện.",
        },
        {
          en: "- Describe any innovative initiatives that had driven business growth and performance such as new growth opportunities (new customer segments, new market, new channels, etc.).",
          vi: "- Mô tả bất kỳ sáng kiến đổi mới nào đã thúc đẩy tăng trưởng và hiệu quả hoạt động kinh doanh, chẳng hạn như các cơ hội tăng trưởng mới (phân khúc khách hàng mới, thị trường mới, kênh mới, v.v.).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Success (20 grade)",
        vi: "Mục 4: Thành công (20 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the brand's success including brand awareness, increased sales, and sustainable competitive advantage.`,
          vi: "- Cung cấp cái nhìn tổng quan về sự thành công của thương hiệu bao gồm nhận thức về thương hiệu, tăng doanh số bán hàng và lợi thế cạnh tranh bền vững.",
        },
        {
          en: "- Provide evidence of marketing successes across brand planning, consumer insight, communication strategy, innovation, and campaign activation, as well as great use of all elements of the marketing mix across brands and campaigns.",
          vi: "- Cung cấp bằng chứng về sự thành công của hoạt động tiếp thị trong quá trình lập kế hoạch thương hiệu, hiểu biết sâu sắc về người tiêu dùng, chiến lược truyền thông, đổi mới và kích hoạt chiến dịch cũng như việc sử dụng hiệu quả tất cả các yếu tố của tổ hợp tiếp thị giữa các thương hiệu và chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Culture (5 grade)",
        vi: "Mục 5: Văn hóa (5 điểm)",
      },
      description: [
        {
          en: `- The brand's image or message has a cultural combination.`,
          vi: "- Hình ảnh hoặc thông điệp của thương hiệu có sự kết hợp văn hóa.",
        },
        {
          en: "- The application of Vietnamese culture, elements of Vietnamese traditions and customs, and morals of Vietnamese people in communication and advertising activities.",
          vi: "- Ứng dụng văn hóa Việt Nam, các yếu tố truyền thống, phong tục Việt Nam và đạo đức của người Việt Nam trong hoạt động truyền thông, quảng cáo.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 6: Contribution (15 grade)",
        vi: "Mục 6: Sự đóng góp (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the company had contributed to the wider public including any CSR activities that the brand was involved with to show leadership and contribution.",
          vi: "- Mô tả cách công ty đã đóng góp cho công chúng rộng rãi hơn, bao gồm mọi hoạt động CSR mà thương hiệu tham gia để thể hiện khả năng lãnh đạo và đóng góp.",
        },
        {
          en: "- Provide examples where the company has shown exceptional commitment to deliver the very best in their sector, demonstrate best practices, and show an innovative and creative approach to the work they deliver.",
          vi: "- Đưa ra những ví dụ trong đó công ty đã thể hiện cam kết đặc biệt trong việc mang lại những điều tốt nhất trong lĩnh vực của họ, thể hiện phương pháp thực hành tốt nhất và thể hiện cách tiếp cận đổi mới và sáng tạo đối với công việc mà họ thực hiện.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Case videos created to inspire the country to vote for you are encouraged. In addition to the written submission, any items that are required to be kept confidential please highlight in yellow.",
          vi: "Khuyến khích sử dụng video trường hợp được tạo ra để truyền cảm hứng cho đất nước bỏ phiếu cho bạn. Ngoài bài nộp bằng văn bản, vui lòng đánh dấu màu vàng bất kỳ mục nào cần được giữ bí mật.",
        },
      ],
    },
  ],
  "thuong-hieu-do-uong-co-con-cua-nam": [
    {
      title: {
        en: "Section 1: Vision (10 grade)",
        vi: "Mục 1: Tầm nhìn (10 điểm)",
      },
      description: [
        {
          en: `- Describe the company's brand vision and mission and how these align with the values and goals of the business.`,
          vi: "- Mô tả tầm nhìn và sứ mệnh thương hiệu của công ty cũng như cách chúng phù hợp với các giá trị và mục tiêu của doanh nghiệp.",
        },
        {
          en: "- What objectives were set and how were they achieved?",
          vi: "- Những mục tiêu nào đã được đặt ra và chúng đã đạt được chúng như thế nào.",
        },
        {
          en: "- Describe the challenges and how the company overcame them, both internally as a business, for its employees, and externally for its customers.",
          vi: "- Mô tả những thách thức và cách công ty vượt qua chúng, cả trong nội bộ doanh nghiệp, đối với nhân viên và bên ngoài đối với khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Insight & Strategy (25 grade)",
        vi: "Mục 2: Cái nhìn sâu sắc & Chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Describe the business strategy and challenges faced including examples of where the business strategy and the brand and marketing contribution had led to its success.",
          vi: "- Mô tả chiến lược kinh doanh và những thách thức phải đối mặt, bao gồm các ví dụ về chiến lược kinh doanh cũng như sự đóng góp của thương hiệu và tiếp thị đã dẫn đến thành công.",
        },
        {
          en: "- What strategies have been implemented to enhance brand-building efforts to achieve the desired brand experience. Provide examples that demonstrate measures in place to evaluate effectiveness, and how the brand can sustain the efforts to meet its objectives.",
          vi: "- Những chiến lược nào đã được thực hiện để nâng cao nỗ lực xây dựng thương hiệu nhằm đạt được trải nghiệm thương hiệu mong muốn. Cung cấp các ví dụ minh họa các biện pháp được áp dụng để đánh giá hiệu quả và cách thương hiệu có thể duy trì những nỗ lực để đạt được mục tiêu của mình.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Creativity & Innovation (25 grade)",
        vi: "Mục 3: Sáng tạo & Đổi mới (25 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of brand-building activities, or initiatives during the eligibility period.",
          vi: "- Cung cấp cái nhìn tổng quan về các hoạt động hoặc sáng kiến xây dựng thương hiệu trong thời gian đủ điều kiện.",
        },
        {
          en: "- Describe any innovative initiatives that had driven business growth and performance such as new growth opportunities (new customer segments, new market, new channels, etc.).",
          vi: "- Mô tả bất kỳ sáng kiến đổi mới nào đã thúc đẩy tăng trưởng và hiệu quả hoạt động kinh doanh, chẳng hạn như các cơ hội tăng trưởng mới (phân khúc khách hàng mới, thị trường mới, kênh mới, v.v.).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Success (20 grade)",
        vi: "Mục 4: Thành công (20 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the brand's success including brand awareness, increased sales, and sustainable competitive advantage.`,
          vi: "- Cung cấp cái nhìn tổng quan về sự thành công của thương hiệu bao gồm nhận thức về thương hiệu, tăng doanh số bán hàng và lợi thế cạnh tranh bền vững.",
        },
        {
          en: "- Provide evidence of marketing successes across brand planning, consumer insight, communication strategy, innovation, and campaign activation, as well as great use of all elements of the marketing mix across brands and campaigns.",
          vi: "- Cung cấp bằng chứng về sự thành công của hoạt động tiếp thị trong quá trình lập kế hoạch thương hiệu, hiểu biết sâu sắc về người tiêu dùng, chiến lược truyền thông, đổi mới và kích hoạt chiến dịch cũng như việc sử dụng hiệu quả tất cả các yếu tố của tổ hợp tiếp thị giữa các thương hiệu và chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Culture (5 grade)",
        vi: "Mục 5: Văn hóa (5 điểm)",
      },
      description: [
        {
          en: `- The brand's image or message has a cultural combination.`,
          vi: "- Hình ảnh hoặc thông điệp của thương hiệu có sự kết hợp văn hóa.",
        },
        {
          en: "- The application of Vietnamese culture, elements of Vietnamese traditions and customs, and morals of Vietnamese people in communication and advertising activities.",
          vi: "- Ứng dụng văn hóa Việt Nam, các yếu tố truyền thống, phong tục Việt Nam và đạo đức của người Việt Nam trong hoạt động truyền thông, quảng cáo.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 6: Contribution (15 grade)",
        vi: "Mục 6: Sự đóng góp (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the company had contributed to the wider public including any CSR activities that the brand was involved with to show leadership and contribution.",
          vi: "- Mô tả cách công ty đã đóng góp cho công chúng rộng rãi hơn, bao gồm mọi hoạt động CSR mà thương hiệu tham gia để thể hiện khả năng lãnh đạo và đóng góp.",
        },
        {
          en: "- Provide examples where the company has shown exceptional commitment to deliver the very best in their sector, demonstrate best practices, and show an innovative and creative approach to the work they deliver.",
          vi: "- Đưa ra những ví dụ trong đó công ty đã thể hiện cam kết đặc biệt trong việc mang lại những điều tốt nhất trong lĩnh vực của họ, thể hiện phương pháp thực hành tốt nhất và thể hiện cách tiếp cận đổi mới và sáng tạo đối với công việc mà họ thực hiện.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Case videos created to inspire the country to vote for you are encouraged. In addition to the written submission, any items that are required to be kept confidential please highlight in yellow.",
          vi: "Khuyến khích sử dụng video trường hợp được tạo ra để truyền cảm hứng cho đất nước bỏ phiếu cho bạn. Ngoài bài nộp bằng văn bản, vui lòng đánh dấu màu vàng bất kỳ mục nào cần được giữ bí mật.",
        },
      ],
    },
  ],
  "thuong-hieu-do-uong-khong-con-cua-nam": [
    {
      title: {
        en: "Section 1: Vision (10 grade)",
        vi: "Mục 1: Tầm nhìn (10 điểm)",
      },
      description: [
        {
          en: `- Describe the company's brand vision and mission and how these align with the values and goals of the business.`,
          vi: "- Mô tả tầm nhìn và sứ mệnh thương hiệu của công ty cũng như cách chúng phù hợp với các giá trị và mục tiêu của doanh nghiệp.",
        },
        {
          en: "- What objectives were set and how were they achieved?",
          vi: "- Những mục tiêu nào đã được đặt ra và chúng đã đạt được chúng như thế nào.",
        },
        {
          en: "- Describe the challenges and how the company overcame them, both internally as a business, for its employees, and externally for its customers.",
          vi: "- Mô tả những thách thức và cách công ty vượt qua chúng, cả trong nội bộ doanh nghiệp, đối với nhân viên và bên ngoài đối với khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Insight & Strategy (25 grade)",
        vi: "Mục 2: Cái nhìn sâu sắc & Chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Describe the business strategy and challenges faced including examples of where the business strategy and the brand and marketing contribution had led to its success.",
          vi: "- Mô tả chiến lược kinh doanh và những thách thức phải đối mặt, bao gồm các ví dụ về chiến lược kinh doanh cũng như sự đóng góp của thương hiệu và tiếp thị đã dẫn đến thành công.",
        },
        {
          en: "- What strategies have been implemented to enhance brand-building efforts to achieve the desired brand experience. Provide examples that demonstrate measures in place to evaluate effectiveness, and how the brand can sustain the efforts to meet its objectives.",
          vi: "- Những chiến lược nào đã được thực hiện để nâng cao nỗ lực xây dựng thương hiệu nhằm đạt được trải nghiệm thương hiệu mong muốn. Cung cấp các ví dụ minh họa các biện pháp được áp dụng để đánh giá hiệu quả và cách thương hiệu có thể duy trì những nỗ lực để đạt được mục tiêu của mình.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Creativity & Innovation (25 grade)",
        vi: "Mục 3: Sáng tạo & Đổi mới (25 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of brand-building activities, or initiatives during the eligibility period.",
          vi: "- Cung cấp cái nhìn tổng quan về các hoạt động hoặc sáng kiến xây dựng thương hiệu trong thời gian đủ điều kiện.",
        },
        {
          en: "- Describe any innovative initiatives that had driven business growth and performance such as new growth opportunities (new customer segments, new market, new channels, etc.).",
          vi: "- Mô tả bất kỳ sáng kiến đổi mới nào đã thúc đẩy tăng trưởng và hiệu quả hoạt động kinh doanh, chẳng hạn như các cơ hội tăng trưởng mới (phân khúc khách hàng mới, thị trường mới, kênh mới, v.v.).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Success (20 grade)",
        vi: "Mục 4: Thành công (20 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the brand's success including brand awareness, increased sales, and sustainable competitive advantage.`,
          vi: "- Cung cấp cái nhìn tổng quan về sự thành công của thương hiệu bao gồm nhận thức về thương hiệu, tăng doanh số bán hàng và lợi thế cạnh tranh bền vững.",
        },
        {
          en: "- Provide evidence of marketing successes across brand planning, consumer insight, communication strategy, innovation, and campaign activation, as well as great use of all elements of the marketing mix across brands and campaigns.",
          vi: "- Cung cấp bằng chứng về sự thành công của hoạt động tiếp thị trong quá trình lập kế hoạch thương hiệu, hiểu biết sâu sắc về người tiêu dùng, chiến lược truyền thông, đổi mới và kích hoạt chiến dịch cũng như việc sử dụng hiệu quả tất cả các yếu tố của tổ hợp tiếp thị giữa các thương hiệu và chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Culture (5 grade)",
        vi: "Mục 5: Văn hóa (5 điểm)",
      },
      description: [
        {
          en: `- The brand's image or message has a cultural combination.`,
          vi: "- Hình ảnh hoặc thông điệp của thương hiệu có sự kết hợp văn hóa.",
        },
        {
          en: "- The application of Vietnamese culture, elements of Vietnamese traditions and customs, and morals of Vietnamese people in communication and advertising activities.",
          vi: "- Ứng dụng văn hóa Việt Nam, các yếu tố truyền thống, phong tục Việt Nam và đạo đức của người Việt Nam trong hoạt động truyền thông, quảng cáo.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 6: Contribution (15 grade)",
        vi: "Mục 6: Sự đóng góp (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the company had contributed to the wider public including any CSR activities that the brand was involved with to show leadership and contribution.",
          vi: "- Mô tả cách công ty đã đóng góp cho công chúng rộng rãi hơn, bao gồm mọi hoạt động CSR mà thương hiệu tham gia để thể hiện khả năng lãnh đạo và đóng góp.",
        },
        {
          en: "- Provide examples where the company has shown exceptional commitment to deliver the very best in their sector, demonstrate best practices, and show an innovative and creative approach to the work they deliver.",
          vi: "- Đưa ra những ví dụ trong đó công ty đã thể hiện cam kết đặc biệt trong việc mang lại những điều tốt nhất trong lĩnh vực của họ, thể hiện phương pháp thực hành tốt nhất và thể hiện cách tiếp cận đổi mới và sáng tạo đối với công việc mà họ thực hiện.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Case videos created to inspire the country to vote for you are encouraged. In addition to the written submission, any items that are required to be kept confidential please highlight in yellow.",
          vi: "Khuyến khích sử dụng video trường hợp được tạo ra để truyền cảm hứng cho đất nước bỏ phiếu cho bạn. Ngoài bài nộp bằng văn bản, vui lòng đánh dấu màu vàng bất kỳ mục nào cần được giữ bí mật.",
        },
      ],
    },
  ],
  "thuong-hieu-dinh-duong-cua-nam": [
    {
      title: {
        en: "Section 1: Vision (10 grade)",
        vi: "Mục 1: Tầm nhìn (10 điểm)",
      },
      description: [
        {
          en: `- Describe the company's brand vision and mission and how these align with the values and goals of the business.`,
          vi: "- Mô tả tầm nhìn và sứ mệnh thương hiệu của công ty cũng như cách chúng phù hợp với các giá trị và mục tiêu của doanh nghiệp.",
        },
        {
          en: "- What objectives were set and how were they achieved?",
          vi: "- Những mục tiêu nào đã được đặt ra và chúng đã đạt được chúng như thế nào.",
        },
        {
          en: "- Describe the challenges and how the company overcame them, both internally as a business, for its employees, and externally for its customers.",
          vi: "- Mô tả những thách thức và cách công ty vượt qua chúng, cả trong nội bộ doanh nghiệp, đối với nhân viên và bên ngoài đối với khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Insight & Strategy (25 grade)",
        vi: "Mục 2: Cái nhìn sâu sắc & Chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Describe the business strategy and challenges faced including examples of where the business strategy and the brand and marketing contribution had led to its success.",
          vi: "- Mô tả chiến lược kinh doanh và những thách thức phải đối mặt, bao gồm các ví dụ về chiến lược kinh doanh cũng như sự đóng góp của thương hiệu và tiếp thị đã dẫn đến thành công.",
        },
        {
          en: "- What strategies have been implemented to enhance brand-building efforts to achieve the desired brand experience. Provide examples that demonstrate measures in place to evaluate effectiveness, and how the brand can sustain the efforts to meet its objectives.",
          vi: "- Những chiến lược nào đã được thực hiện để nâng cao nỗ lực xây dựng thương hiệu nhằm đạt được trải nghiệm thương hiệu mong muốn. Cung cấp các ví dụ minh họa các biện pháp được áp dụng để đánh giá hiệu quả và cách thương hiệu có thể duy trì những nỗ lực để đạt được mục tiêu của mình.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Creativity & Innovation (25 grade)",
        vi: "Mục 3: Sáng tạo & Đổi mới (25 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of brand-building activities, or initiatives during the eligibility period.",
          vi: "- Cung cấp cái nhìn tổng quan về các hoạt động hoặc sáng kiến xây dựng thương hiệu trong thời gian đủ điều kiện.",
        },
        {
          en: "- Describe any innovative initiatives that had driven business growth and performance such as new growth opportunities (new customer segments, new market, new channels, etc.).",
          vi: "- Mô tả bất kỳ sáng kiến đổi mới nào đã thúc đẩy tăng trưởng và hiệu quả hoạt động kinh doanh, chẳng hạn như các cơ hội tăng trưởng mới (phân khúc khách hàng mới, thị trường mới, kênh mới, v.v.).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Success (20 grade)",
        vi: "Mục 4: Thành công (20 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the brand's success including brand awareness, increased sales, and sustainable competitive advantage.`,
          vi: "- Cung cấp cái nhìn tổng quan về sự thành công của thương hiệu bao gồm nhận thức về thương hiệu, tăng doanh số bán hàng và lợi thế cạnh tranh bền vững.",
        },
        {
          en: "- Provide evidence of marketing successes across brand planning, consumer insight, communication strategy, innovation, and campaign activation, as well as great use of all elements of the marketing mix across brands and campaigns.",
          vi: "- Cung cấp bằng chứng về sự thành công của hoạt động tiếp thị trong quá trình lập kế hoạch thương hiệu, hiểu biết sâu sắc về người tiêu dùng, chiến lược truyền thông, đổi mới và kích hoạt chiến dịch cũng như việc sử dụng hiệu quả tất cả các yếu tố của tổ hợp tiếp thị giữa các thương hiệu và chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Culture (5 grade)",
        vi: "Mục 5: Văn hóa (5 điểm)",
      },
      description: [
        {
          en: `- The brand's image or message has a cultural combination.`,
          vi: "- Hình ảnh hoặc thông điệp của thương hiệu có sự kết hợp văn hóa.",
        },
        {
          en: "- The application of Vietnamese culture, elements of Vietnamese traditions and customs, and morals of Vietnamese people in communication and advertising activities.",
          vi: "- Ứng dụng văn hóa Việt Nam, các yếu tố truyền thống, phong tục Việt Nam và đạo đức của người Việt Nam trong hoạt động truyền thông, quảng cáo.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 6: Contribution (15 grade)",
        vi: "Mục 6: Sự đóng góp (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the company had contributed to the wider public including any CSR activities that the brand was involved with to show leadership and contribution.",
          vi: "- Mô tả cách công ty đã đóng góp cho công chúng rộng rãi hơn, bao gồm mọi hoạt động CSR mà thương hiệu tham gia để thể hiện khả năng lãnh đạo và đóng góp.",
        },
        {
          en: "- Provide examples where the company has shown exceptional commitment to deliver the very best in their sector, demonstrate best practices, and show an innovative and creative approach to the work they deliver.",
          vi: "- Đưa ra những ví dụ trong đó công ty đã thể hiện cam kết đặc biệt trong việc mang lại những điều tốt nhất trong lĩnh vực của họ, thể hiện phương pháp thực hành tốt nhất và thể hiện cách tiếp cận đổi mới và sáng tạo đối với công việc mà họ thực hiện.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Case videos created to inspire the country to vote for you are encouraged. In addition to the written submission, any items that are required to be kept confidential please highlight in yellow.",
          vi: "Khuyến khích sử dụng video trường hợp được tạo ra để truyền cảm hứng cho đất nước bỏ phiếu cho bạn. Ngoài bài nộp bằng văn bản, vui lòng đánh dấu màu vàng bất kỳ mục nào cần được giữ bí mật.",
        },
      ],
    },
  ],
  "thuong-hieu-gia-vi-cua-nam": [
    {
      title: {
        en: "Section 1: Vision (10 grade)",
        vi: "Mục 1: Tầm nhìn (10 điểm)",
      },
      description: [
        {
          en: `- Describe the company's brand vision and mission and how these align with the values and goals of the business.`,
          vi: "- Mô tả tầm nhìn và sứ mệnh thương hiệu của công ty cũng như cách chúng phù hợp với các giá trị và mục tiêu của doanh nghiệp.",
        },
        {
          en: "- What objectives were set and how were they achieved?",
          vi: "- Những mục tiêu nào đã được đặt ra và chúng đã đạt được chúng như thế nào.",
        },
        {
          en: "- Describe the challenges and how the company overcame them, both internally as a business, for its employees, and externally for its customers.",
          vi: "- Mô tả những thách thức và cách công ty vượt qua chúng, cả trong nội bộ doanh nghiệp, đối với nhân viên và bên ngoài đối với khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Insight & Strategy (25 grade)",
        vi: "Mục 2: Cái nhìn sâu sắc & Chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Describe the business strategy and challenges faced including examples of where the business strategy and the brand and marketing contribution had led to its success.",
          vi: "- Mô tả chiến lược kinh doanh và những thách thức phải đối mặt, bao gồm các ví dụ về chiến lược kinh doanh cũng như sự đóng góp của thương hiệu và tiếp thị đã dẫn đến thành công.",
        },
        {
          en: "- What strategies have been implemented to enhance brand-building efforts to achieve the desired brand experience. Provide examples that demonstrate measures in place to evaluate effectiveness, and how the brand can sustain the efforts to meet its objectives.",
          vi: "- Những chiến lược nào đã được thực hiện để nâng cao nỗ lực xây dựng thương hiệu nhằm đạt được trải nghiệm thương hiệu mong muốn. Cung cấp các ví dụ minh họa các biện pháp được áp dụng để đánh giá hiệu quả và cách thương hiệu có thể duy trì những nỗ lực để đạt được mục tiêu của mình.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Creativity & Innovation (25 grade)",
        vi: "Mục 3: Sáng tạo & Đổi mới (25 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of brand-building activities, or initiatives during the eligibility period.",
          vi: "- Cung cấp cái nhìn tổng quan về các hoạt động hoặc sáng kiến xây dựng thương hiệu trong thời gian đủ điều kiện.",
        },
        {
          en: "- Describe any innovative initiatives that had driven business growth and performance such as new growth opportunities (new customer segments, new market, new channels, etc.).",
          vi: "- Mô tả bất kỳ sáng kiến đổi mới nào đã thúc đẩy tăng trưởng và hiệu quả hoạt động kinh doanh, chẳng hạn như các cơ hội tăng trưởng mới (phân khúc khách hàng mới, thị trường mới, kênh mới, v.v.).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Success (20 grade)",
        vi: "Mục 4: Thành công (20 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the brand's success including brand awareness, increased sales, and sustainable competitive advantage.`,
          vi: "- Cung cấp cái nhìn tổng quan về sự thành công của thương hiệu bao gồm nhận thức về thương hiệu, tăng doanh số bán hàng và lợi thế cạnh tranh bền vững.",
        },
        {
          en: "- Provide evidence of marketing successes across brand planning, consumer insight, communication strategy, innovation, and campaign activation, as well as great use of all elements of the marketing mix across brands and campaigns.",
          vi: "- Cung cấp bằng chứng về sự thành công của hoạt động tiếp thị trong quá trình lập kế hoạch thương hiệu, hiểu biết sâu sắc về người tiêu dùng, chiến lược truyền thông, đổi mới và kích hoạt chiến dịch cũng như việc sử dụng hiệu quả tất cả các yếu tố của tổ hợp tiếp thị giữa các thương hiệu và chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Culture (5 grade)",
        vi: "Mục 5: Văn hóa (5 điểm)",
      },
      description: [
        {
          en: `- The brand's image or message has a cultural combination.`,
          vi: "- Hình ảnh hoặc thông điệp của thương hiệu có sự kết hợp văn hóa.",
        },
        {
          en: "- The application of Vietnamese culture, elements of Vietnamese traditions and customs, and morals of Vietnamese people in communication and advertising activities.",
          vi: "- Ứng dụng văn hóa Việt Nam, các yếu tố truyền thống, phong tục Việt Nam và đạo đức của người Việt Nam trong hoạt động truyền thông, quảng cáo.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 6: Contribution (15 grade)",
        vi: "Mục 6: Sự đóng góp (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the company had contributed to the wider public including any CSR activities that the brand was involved with to show leadership and contribution.",
          vi: "- Mô tả cách công ty đã đóng góp cho công chúng rộng rãi hơn, bao gồm mọi hoạt động CSR mà thương hiệu tham gia để thể hiện khả năng lãnh đạo và đóng góp.",
        },
        {
          en: "- Provide examples where the company has shown exceptional commitment to deliver the very best in their sector, demonstrate best practices, and show an innovative and creative approach to the work they deliver.",
          vi: "- Đưa ra những ví dụ trong đó công ty đã thể hiện cam kết đặc biệt trong việc mang lại những điều tốt nhất trong lĩnh vực của họ, thể hiện phương pháp thực hành tốt nhất và thể hiện cách tiếp cận đổi mới và sáng tạo đối với công việc mà họ thực hiện.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Case videos created to inspire the country to vote for you are encouraged. In addition to the written submission, any items that are required to be kept confidential please highlight in yellow.",
          vi: "Khuyến khích sử dụng video trường hợp được tạo ra để truyền cảm hứng cho đất nước bỏ phiếu cho bạn. Ngoài bài nộp bằng văn bản, vui lòng đánh dấu màu vàng bất kỳ mục nào cần được giữ bí mật.",
        },
      ],
    },
  ],
  "thuong-hieu-thuc-pham-cua-nam": [
    {
      title: {
        en: "Section 1: Vision (10 grade)",
        vi: "Mục 1: Tầm nhìn (10 điểm)",
      },
      description: [
        {
          en: `- Describe the company's brand vision and mission and how these align with the values and goals of the business.`,
          vi: "- Mô tả tầm nhìn và sứ mệnh thương hiệu của công ty cũng như cách chúng phù hợp với các giá trị và mục tiêu của doanh nghiệp.",
        },
        {
          en: "- What objectives were set and how were they achieved?",
          vi: "- Những mục tiêu nào đã được đặt ra và chúng đã đạt được chúng như thế nào.",
        },
        {
          en: "- Describe the challenges and how the company overcame them, both internally as a business, for its employees, and externally for its customers.",
          vi: "- Mô tả những thách thức và cách công ty vượt qua chúng, cả trong nội bộ doanh nghiệp, đối với nhân viên và bên ngoài đối với khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Insight & Strategy (25 grade)",
        vi: "Mục 2: Cái nhìn sâu sắc & Chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Describe the business strategy and challenges faced including examples of where the business strategy and the brand and marketing contribution had led to its success.",
          vi: "- Mô tả chiến lược kinh doanh và những thách thức phải đối mặt, bao gồm các ví dụ về chiến lược kinh doanh cũng như sự đóng góp của thương hiệu và tiếp thị đã dẫn đến thành công.",
        },
        {
          en: "- What strategies have been implemented to enhance brand-building efforts to achieve the desired brand experience. Provide examples that demonstrate measures in place to evaluate effectiveness, and how the brand can sustain the efforts to meet its objectives.",
          vi: "- Những chiến lược nào đã được thực hiện để nâng cao nỗ lực xây dựng thương hiệu nhằm đạt được trải nghiệm thương hiệu mong muốn. Cung cấp các ví dụ minh họa các biện pháp được áp dụng để đánh giá hiệu quả và cách thương hiệu có thể duy trì những nỗ lực để đạt được mục tiêu của mình.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Creativity & Innovation (25 grade)",
        vi: "Mục 3: Sáng tạo & Đổi mới (25 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of brand-building activities, or initiatives during the eligibility period.",
          vi: "- Cung cấp cái nhìn tổng quan về các hoạt động hoặc sáng kiến xây dựng thương hiệu trong thời gian đủ điều kiện.",
        },
        {
          en: "- Describe any innovative initiatives that had driven business growth and performance such as new growth opportunities (new customer segments, new market, new channels, etc.).",
          vi: "- Mô tả bất kỳ sáng kiến đổi mới nào đã thúc đẩy tăng trưởng và hiệu quả hoạt động kinh doanh, chẳng hạn như các cơ hội tăng trưởng mới (phân khúc khách hàng mới, thị trường mới, kênh mới, v.v.).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Success (20 grade)",
        vi: "Mục 4: Thành công (20 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the brand's success including brand awareness, increased sales, and sustainable competitive advantage.`,
          vi: "- Cung cấp cái nhìn tổng quan về sự thành công của thương hiệu bao gồm nhận thức về thương hiệu, tăng doanh số bán hàng và lợi thế cạnh tranh bền vững.",
        },
        {
          en: "- Provide evidence of marketing successes across brand planning, consumer insight, communication strategy, innovation, and campaign activation, as well as great use of all elements of the marketing mix across brands and campaigns.",
          vi: "- Cung cấp bằng chứng về sự thành công của hoạt động tiếp thị trong quá trình lập kế hoạch thương hiệu, hiểu biết sâu sắc về người tiêu dùng, chiến lược truyền thông, đổi mới và kích hoạt chiến dịch cũng như việc sử dụng hiệu quả tất cả các yếu tố của tổ hợp tiếp thị giữa các thương hiệu và chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Culture (5 grade)",
        vi: "Mục 5: Văn hóa (5 điểm)",
      },
      description: [
        {
          en: `- The brand's image or message has a cultural combination.`,
          vi: "- Hình ảnh hoặc thông điệp của thương hiệu có sự kết hợp văn hóa.",
        },
        {
          en: "- The application of Vietnamese culture, elements of Vietnamese traditions and customs, and morals of Vietnamese people in communication and advertising activities.",
          vi: "- Ứng dụng văn hóa Việt Nam, các yếu tố truyền thống, phong tục Việt Nam và đạo đức của người Việt Nam trong hoạt động truyền thông, quảng cáo.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 6: Contribution (15 grade)",
        vi: "Mục 6: Sự đóng góp (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the company had contributed to the wider public including any CSR activities that the brand was involved with to show leadership and contribution.",
          vi: "- Mô tả cách công ty đã đóng góp cho công chúng rộng rãi hơn, bao gồm mọi hoạt động CSR mà thương hiệu tham gia để thể hiện khả năng lãnh đạo và đóng góp.",
        },
        {
          en: "- Provide examples where the company has shown exceptional commitment to deliver the very best in their sector, demonstrate best practices, and show an innovative and creative approach to the work they deliver.",
          vi: "- Đưa ra những ví dụ trong đó công ty đã thể hiện cam kết đặc biệt trong việc mang lại những điều tốt nhất trong lĩnh vực của họ, thể hiện phương pháp thực hành tốt nhất và thể hiện cách tiếp cận đổi mới và sáng tạo đối với công việc mà họ thực hiện.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Case videos created to inspire the country to vote for you are encouraged. In addition to the written submission, any items that are required to be kept confidential please highlight in yellow.",
          vi: "Khuyến khích sử dụng video trường hợp được tạo ra để truyền cảm hứng cho đất nước bỏ phiếu cho bạn. Ngoài bài nộp bằng văn bản, vui lòng đánh dấu màu vàng bất kỳ mục nào cần được giữ bí mật.",
        },
      ],
    },
  ],
  "thuong-hieu-banh-keo-cua-nam": [
    {
      title: {
        en: "Section 1: Vision (10 grade)",
        vi: "Mục 1: Tầm nhìn (10 điểm)",
      },
      description: [
        {
          en: `- Describe the company's brand vision and mission and how these align with the values and goals of the business.`,
          vi: "- Mô tả tầm nhìn và sứ mệnh thương hiệu của công ty cũng như cách chúng phù hợp với các giá trị và mục tiêu của doanh nghiệp.",
        },
        {
          en: "- What objectives were set and how were they achieved?",
          vi: "- Những mục tiêu nào đã được đặt ra và chúng đã đạt được chúng như thế nào.",
        },
        {
          en: "- Describe the challenges and how the company overcame them, both internally as a business, for its employees, and externally for its customers.",
          vi: "- Mô tả những thách thức và cách công ty vượt qua chúng, cả trong nội bộ doanh nghiệp, đối với nhân viên và bên ngoài đối với khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Insight & Strategy (25 grade)",
        vi: "Mục 2: Cái nhìn sâu sắc & Chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Describe the business strategy and challenges faced including examples of where the business strategy and the brand and marketing contribution had led to its success.",
          vi: "- Mô tả chiến lược kinh doanh và những thách thức phải đối mặt, bao gồm các ví dụ về chiến lược kinh doanh cũng như sự đóng góp của thương hiệu và tiếp thị đã dẫn đến thành công.",
        },
        {
          en: "- What strategies have been implemented to enhance brand-building efforts to achieve the desired brand experience. Provide examples that demonstrate measures in place to evaluate effectiveness, and how the brand can sustain the efforts to meet its objectives.",
          vi: "- Những chiến lược nào đã được thực hiện để nâng cao nỗ lực xây dựng thương hiệu nhằm đạt được trải nghiệm thương hiệu mong muốn. Cung cấp các ví dụ minh họa các biện pháp được áp dụng để đánh giá hiệu quả và cách thương hiệu có thể duy trì những nỗ lực để đạt được mục tiêu của mình.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Creativity & Innovation (25 grade)",
        vi: "Mục 3: Sáng tạo & Đổi mới (25 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of brand-building activities, or initiatives during the eligibility period.",
          vi: "- Cung cấp cái nhìn tổng quan về các hoạt động hoặc sáng kiến xây dựng thương hiệu trong thời gian đủ điều kiện.",
        },
        {
          en: "- Describe any innovative initiatives that had driven business growth and performance such as new growth opportunities (new customer segments, new market, new channels, etc.).",
          vi: "- Mô tả bất kỳ sáng kiến đổi mới nào đã thúc đẩy tăng trưởng và hiệu quả hoạt động kinh doanh, chẳng hạn như các cơ hội tăng trưởng mới (phân khúc khách hàng mới, thị trường mới, kênh mới, v.v.).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Success (20 grade)",
        vi: "Mục 4: Thành công (20 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the brand's success including brand awareness, increased sales, and sustainable competitive advantage.`,
          vi: "- Cung cấp cái nhìn tổng quan về sự thành công của thương hiệu bao gồm nhận thức về thương hiệu, tăng doanh số bán hàng và lợi thế cạnh tranh bền vững.",
        },
        {
          en: "- Provide evidence of marketing successes across brand planning, consumer insight, communication strategy, innovation, and campaign activation, as well as great use of all elements of the marketing mix across brands and campaigns.",
          vi: "- Cung cấp bằng chứng về sự thành công của hoạt động tiếp thị trong quá trình lập kế hoạch thương hiệu, hiểu biết sâu sắc về người tiêu dùng, chiến lược truyền thông, đổi mới và kích hoạt chiến dịch cũng như việc sử dụng hiệu quả tất cả các yếu tố của tổ hợp tiếp thị giữa các thương hiệu và chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Culture (5 grade)",
        vi: "Mục 5: Văn hóa (5 điểm)",
      },
      description: [
        {
          en: `- The brand's image or message has a cultural combination.`,
          vi: "- Hình ảnh hoặc thông điệp của thương hiệu có sự kết hợp văn hóa.",
        },
        {
          en: "- The application of Vietnamese culture, elements of Vietnamese traditions and customs, and morals of Vietnamese people in communication and advertising activities.",
          vi: "- Ứng dụng văn hóa Việt Nam, các yếu tố truyền thống, phong tục Việt Nam và đạo đức của người Việt Nam trong hoạt động truyền thông, quảng cáo.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 6: Contribution (15 grade)",
        vi: "Mục 6: Sự đóng góp (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the company had contributed to the wider public including any CSR activities that the brand was involved with to show leadership and contribution.",
          vi: "- Mô tả cách công ty đã đóng góp cho công chúng rộng rãi hơn, bao gồm mọi hoạt động CSR mà thương hiệu tham gia để thể hiện khả năng lãnh đạo và đóng góp.",
        },
        {
          en: "- Provide examples where the company has shown exceptional commitment to deliver the very best in their sector, demonstrate best practices, and show an innovative and creative approach to the work they deliver.",
          vi: "- Đưa ra những ví dụ trong đó công ty đã thể hiện cam kết đặc biệt trong việc mang lại những điều tốt nhất trong lĩnh vực của họ, thể hiện phương pháp thực hành tốt nhất và thể hiện cách tiếp cận đổi mới và sáng tạo đối với công việc mà họ thực hiện.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Case videos created to inspire the country to vote for you are encouraged. In addition to the written submission, any items that are required to be kept confidential please highlight in yellow.",
          vi: "Khuyến khích sử dụng video trường hợp được tạo ra để truyền cảm hứng cho đất nước bỏ phiếu cho bạn. Ngoài bài nộp bằng văn bản, vui lòng đánh dấu màu vàng bất kỳ mục nào cần được giữ bí mật.",
        },
      ],

    },
  ],
  "thuong-hieu-bat-dong-san-cua-nam": [
    {
      title: {
        en: "Section 1: Vision (10 grade)",
        vi: "Mục 1: Tầm nhìn (10 điểm)",
      },
      description: [
        {
          en: `- Describe the company's brand vision and mission and how these align with the values and goals of the business.`,
          vi: "- Mô tả tầm nhìn và sứ mệnh thương hiệu của công ty cũng như cách chúng phù hợp với các giá trị và mục tiêu của doanh nghiệp.",
        },
        {
          en: "- What objectives were set and how were they achieved?",
          vi: "- Những mục tiêu nào đã được đặt ra và chúng đã đạt được chúng như thế nào.",
        },
        {
          en: "- Describe the challenges and how the company overcame them, both internally as a business, for its employees, and externally for its customers.",
          vi: "- Mô tả những thách thức và cách công ty vượt qua chúng, cả trong nội bộ doanh nghiệp, đối với nhân viên và bên ngoài đối với khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 2: Insight & Strategy (25 grade)",
        vi: "Mục 2: Cái nhìn sâu sắc & Chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Describe the business strategy and challenges faced including examples of where the business strategy and the brand and marketing contribution had led to its success.",
          vi: "- Mô tả chiến lược kinh doanh và những thách thức phải đối mặt, bao gồm các ví dụ về chiến lược kinh doanh cũng như sự đóng góp của thương hiệu và tiếp thị đã dẫn đến thành công.",
        },
        {
          en: "- What strategies have been implemented to enhance brand-building efforts to achieve the desired brand experience. Provide examples that demonstrate measures in place to evaluate effectiveness, and how the brand can sustain the efforts to meet its objectives.",
          vi: "- Những chiến lược nào đã được thực hiện để nâng cao nỗ lực xây dựng thương hiệu nhằm đạt được trải nghiệm thương hiệu mong muốn. Cung cấp các ví dụ minh họa các biện pháp được áp dụng để đánh giá hiệu quả và cách thương hiệu có thể duy trì những nỗ lực để đạt được mục tiêu của mình.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Creativity & Innovation (25 grade)",
        vi: "Mục 3: Sáng tạo & Đổi mới (25 điểm)",
      },
      description: [
        {
          en: "- Provide an overview of brand-building activities, or initiatives during the eligibility period.",
          vi: "- Cung cấp cái nhìn tổng quan về các hoạt động hoặc sáng kiến xây dựng thương hiệu trong thời gian đủ điều kiện.",
        },
        {
          en: "- Describe any innovative initiatives that had driven business growth and performance such as new growth opportunities (new customer segments, new market, new channels, etc.).",
          vi: "- Mô tả bất kỳ sáng kiến đổi mới nào đã thúc đẩy tăng trưởng và hiệu quả hoạt động kinh doanh, chẳng hạn như các cơ hội tăng trưởng mới (phân khúc khách hàng mới, thị trường mới, kênh mới, v.v.).",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Success (20 grade)",
        vi: "Mục 4: Thành công (20 điểm)",
      },
      description: [
        {
          en: `- Provide an overview of the brand's success including brand awareness, increased sales, and sustainable competitive advantage.`,
          vi: "- Cung cấp cái nhìn tổng quan về sự thành công của thương hiệu bao gồm nhận thức về thương hiệu, tăng doanh số bán hàng và lợi thế cạnh tranh bền vững.",
        },
        {
          en: "- Provide evidence of marketing successes across brand planning, consumer insight, communication strategy, innovation, and campaign activation, as well as great use of all elements of the marketing mix across brands and campaigns.",
          vi: "- Cung cấp bằng chứng về sự thành công của hoạt động tiếp thị trong quá trình lập kế hoạch thương hiệu, hiểu biết sâu sắc về người tiêu dùng, chiến lược truyền thông, đổi mới và kích hoạt chiến dịch cũng như việc sử dụng hiệu quả tất cả các yếu tố của tổ hợp tiếp thị giữa các thương hiệu và chiến dịch.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Culture (5 grade)",
        vi: "Mục 5: Văn hóa (5 điểm)",
      },
      description: [
        {
          en: `- The brand's image or message has a cultural combination.`,
          vi: "- Hình ảnh hoặc thông điệp của thương hiệu có sự kết hợp văn hóa.",
        },
        {
          en: "- The application of Vietnamese culture, elements of Vietnamese traditions and customs, and morals of Vietnamese people in communication and advertising activities.",
          vi: "- Ứng dụng văn hóa Việt Nam, các yếu tố truyền thống, phong tục Việt Nam và đạo đức của người Việt Nam trong hoạt động truyền thông, quảng cáo.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 6: Contribution (15 grade)",
        vi: "Mục 6: Sự đóng góp (15 điểm)",
      },
      description: [
        {
          en: "- Describe how the company had contributed to the wider public including any CSR activities that the brand was involved with to show leadership and contribution.",
          vi: "- Mô tả cách công ty đã đóng góp cho công chúng rộng rãi hơn, bao gồm mọi hoạt động CSR mà thương hiệu tham gia để thể hiện khả năng lãnh đạo và đóng góp.",
        },
        {
          en: "- Provide examples where the company has shown exceptional commitment to deliver the very best in their sector, demonstrate best practices, and show an innovative and creative approach to the work they deliver.",
          vi: "- Đưa ra những ví dụ trong đó công ty đã thể hiện cam kết đặc biệt trong việc mang lại những điều tốt nhất trong lĩnh vực của họ, thể hiện phương pháp thực hành tốt nhất và thể hiện cách tiếp cận đổi mới và sáng tạo đối với công việc mà họ thực hiện.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "",
        vi: "",
      },
      description: [
        {
          en: "Case videos created to inspire the country to vote for you are encouraged. In addition to the written submission, any items that are required to be kept confidential please highlight in yellow.",
          vi: "Khuyến khích sử dụng video trường hợp được tạo ra để truyền cảm hứng cho đất nước bỏ phiếu cho bạn. Ngoài bài nộp bằng văn bản, vui lòng đánh dấu màu vàng bất kỳ mục nào cần được giữ bí mật.",
        },
      ],
    },
  ],
  "strategist-cua-nam": [
    {
      title: {
        en: "Section 1: Background (5 grade)",
        vi: "Mục 1: Bối cảnh (5 điểm)",
      },
      description: [
        {
          en: `- Please provide the nominee's brief career history, age, and professional qualifications.`,
          vi: "- Vui lòng cung cấp tóm tắt quá trình nghề nghiệp, độ tuổi và trình độ chuyên môn của người được đề cử.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 2: Business Development (20 grade)",
        vi: "Mục 2: Phát triển kinh doanh (20 điểm)",
      },
      description: [
        {
          en: "- Please provide details of existing clients serviced and details of new business wins, as well as examples of how the individual has contributed to both the client and the agency.",
          vi: "- Vui lòng cung cấp thông tin chi tiết về các khách hàng hiện tại đã được phục vụ và thông tin chi tiết về những thắng lợi kinh doanh mới, cũng như các ví dụ về cách cá nhân đó đã đóng góp cho cả khách hàng và agency.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 3: Strategic Skills (25 grade)",
        vi: "Mục 3: Kỹ năng chiến lược (25 điểm)",
      },
      description: [
        {
          en: "- Please demonstrate insights into the core business issues and how they relate to the marketing and communications subsequently developed for any number of campaigns, the individual has worked on.",
          vi: "- Vui lòng trình bày những hiểu biết sâu sắc về các vấn đề kinh doanh cốt lõi và cách chúng liên quan đến hoạt động tiếp thị và truyền thông được phát triển sau đó cho bất kỳ chiến dịch nào mà cá nhân đó đã thực hiện.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 4: Innovation (20 grade)",
        vi: "Mục 4: Đổi mới (20 điểm)",
      },
      description: [
        {
          en: "-  Describe any insightful and innovative approaches with examples of problem-solving, and ability to lead in the production of and/or develop creative solutions that drive results.",
          vi: "- Mô tả bất kỳ cách tiếp cận sâu sắc và sáng tạo nào kèm theo các ví dụ về giải quyết vấn đề và khả năng lãnh đạo trong việc tạo ra và/hoặc phát triển các giải pháp sáng tạo mang lại kết quả.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Media Neutrality (20 grade)",
        vi: "Mục 5: Truyền thông trung lập (20 điểm)",
      },
      description: [
        {
          en: "- Demonstrate examples of his/her ability to intelligently recommend and explore new and diverse platform options.",
          vi: "- Thể hiện các ví dụ về khả năng đề xuất và khám phá các lựa chọn nền tảng mới và đa dạng một cách thông minh.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 6: Client & Supervisor Testimonials (10 grade)",
        vi: "Mục 6: Công nhận của khách hàng và người giám sát (10 điểm)",
      },
      description: [
        {
          en: "- Client & supervisor testimonials will be required as part of supporting material. (with signature or provide a copy of the email).",
          vi: "- Công nhận của khách hàng và người giám sát sẽ được yêu cầu như một phần của tài liệu hỗ trợ. (có chữ ký hoặc cung cấp bản sao email).",
        },
      ],
      points: 10,
    },
  ],
  "account-cua-nam": [
    {
      title: {
        en: "Section 1: Background (5 grade)",
        vi: "Mục 1: Bối cảnh (5 điểm)",
      },
      description: [
        {
          en: `- Please provide the nominee's brief career history and professional qualifications.`,
          vi: "- Vui lòng cung cấp tóm tắt quá trình nghề nghiệp và trình độ chuyên môn của người được đề cử.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 2:  Account & Relationship Management (25 grade)",
        vi: "Mục 2: Quản lý tài khoản và quan hệ (25 điểm)",
      },
      description: [
        {
          en: "- Please provide details of existing clients serviced and growth within the existing business.",
          vi: "- Vui lòng cung cấp thông tin chi tiết về các khách hàng hiện tại được phục vụ và sự phát triển trong hoạt động kinh doanh hiện tại.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Business Performance (20 grade)",
        vi: "Mục 3: Hiệu suất kinh doanh (20 điểm)",
      },
      description: [
        {
          en: "- Please provide details of new business wins, as well as evidence of revenue returns, both for the agency and the client including positive organic growth for clients.",
          vi: "- Vui lòng cung cấp thông tin chi tiết về các chiến thắng kinh doanh mới cũng như bằng chứng về lợi nhuận doanh thu cho cả agency và khách hàng, bao gồm cả mức tăng trưởng hữu cơ tích cực cho khách hàng.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Strategic Skills (20 grade)",
        vi: "Mục 4: Kỹ năng chiến lược (20 điểm)",
      },
      description: [
        {
          en: "- Demonstrate insights into the core business issues and how that relate to the marketing and communications subsequently developed.",
          vi: "- Thể hiện sự hiểu biết sâu sắc về các vấn đề kinh doanh cốt lõi và mối liên hệ của chúng với hoạt động tiếp thị và truyền thông được phát triển sau đó.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Creativity & Innovation (20 grade)",
        vi: "Mục 5: Sáng tạo & Đổi mới (20 điểm)",
      },
      description: [
        {
          en: "- Provide examples of unique approaches to problem-solving.",
          vi: "- Cung cấp ví dụ về các phương pháp độc đáo để giải quyết vấn đề.",
        },
        {
          en: "- Demonstrate the individual’s ability to develop creative solutions that drive results.",
          vi: "- Thể hiện khả năng của cá nhân trong việc phát triển các giải pháp sáng tạo mang lại kết quả.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 6: Client Testimonials (10 grade)",
        vi: "Mục 6: Công nhận của khách hàng (10 điểm)",
      },
      description: [
        {
          en: "- Client testimonials will be required as support material.(with signature or provide a copy of the email).",
          vi: "- Công nhận của khách hàng sẽ được yêu cầu làm tài liệu hỗ trợ. (có chữ ký hoặc cung cấp bản sao email)",
        },
      ],
      points: 10,
    },
  ],
  "creative-director-cua-nam": [
    {
      title: {
        en: "Section 1: Background (5 grade)",
        vi: "Mục 1: Bối cảnh (5 điểm)",
      },
      description: [
        {
          en: "- Provide the career history of the last 5 senior roles including company name, location, title, and dates.",
          vi: "- Cung cấp lịch sử nghề nghiệp của 5 vai trò cấp cao gần đây nhất bao gồm tên công ty, địa điểm, chức danh và ngày tháng.",
        },
        {
          en: "- Current responsibilities to include the size of operation, number of direct reports, etc. ",
          vi: "- Trách nhiệm hiện tại bao gồm quy mô hoạt động, số lượng báo cáo trực tiếp, v.v.",
        },
      ],
      points: 5,
    },
    {
      title: {
        en: "Section 2: Key Work & Highlights (30 grade)",
        vi: "Mục 2: Công việc chính & Điểm nổi bật (30 điểm)",
      },
      description: [
        {
          en: "- Please elaborate on the individual’s best work and highlights within the eligibility period.",
          vi: "- Vui lòng trình bày chi tiết về công việc tốt nhất và điểm nổi bật của cá nhân trong khoảng thời gian cho phép.",
        },
        {
          en: "- Describe any game-changing, ground-breaking creative work and ideas that demonstrated excellence in building/nurturing creativity across the agency/industry.",
          vi: "- Mô tả bất kỳ công việc và ý tưởng sáng tạo mang tính đột phá, mang tính thay đổi cuộc chơi nào thể hiện sự xuất sắc trong việc xây dựng/nuôi dưỡng tính sáng tạo trong toàn Agency/ngành.",
        },
        {
          en: "- Entries should illustrate this through the work, testimonials, etc. Samples of the nominee’s work will be required as supporting materials.",
          vi: "- Các bài dự thi phải minh họa điều này thông qua tác phẩm, lời chứng thực, v.v. Các mẫu tác phẩm của người được đề cử sẽ được yêu cầu làm tài liệu hỗ trợ.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 3: Leadership & Innovation (20 grade)",
        vi: "Mục 3: Lãnh đạo & Đổi mới (20 điểm)",
      },
      description: [
        {
          en: "- Explain how the nominee demonstrated exemplary leadership abilities in building and nurturing creativity across the creative team and the agency that enabled others to succeed and have been critical to the success of the business.",
          vi: "- Giải thích cách người được đề cử thể hiện khả năng lãnh đạo mẫu mực trong việc xây dựng và nuôi dưỡng khả năng sáng tạo trong nhóm sáng tạo và agency, giúp những người khác thành công và đóng vai trò quan trọng đối với sự thành công của doanh nghiệp.",
        },
        {
          en: "- Provide examples of how the nominee has been a business enabler, implementing knowledge-based initiatives that have benefited and transformed the business for the better.",
          vi: "- Cung cấp các ví dụ về cách người được đề cử đã trở thành người thúc đẩy hoạt động kinh doanh, thực hiện các sáng kiến dựa trên kiến thức nhằm mang lại lợi ích và chuyển đổi hoạt động kinh doanh trở nên tốt đẹp hơn.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Client Recognition (10 grade)",
        vi: "Mục 4: Ghi nhận khách hàng (10 điểm)",
      },
      description: [
        {
          en: "-  Details of client recognition towards the individual’s creative works, provide evidence of driving business results and client satisfaction.",
          vi: "-  Chi tiết về sự công nhận của khách hàng đối với các tác phẩm sáng tạo của cá nhân, cung cấp bằng chứng về việc thúc đẩy kết quả kinh doanh và sự hài lòng của khách hàng.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Industry Recognition (15 grade)",
        vi: "Mục 5: Sự công nhận của ngành (15 điểm)",
      },
      description: [
        {
          en: "- Major awards received, specify awards type, and geographic location and indicate if won directly by the entrant agency during the eligibility period, include both creative and effectiveness awards, only medals won should be included for local awards, i.e., not shortlisted entries or commendation.",
          vi: "- Các giải thưởng lớn đã nhận được, nêu rõ loại giải thưởng, vị trí địa lý và cho biết liệu Agency đăng ký có giành được trực tiếp trong thời gian đủ điều kiện hay không, bao gồm cả giải thưởng sáng tạo và hiệu quả, chỉ những huy chương giành được mới được đưa vào các giải thưởng địa phương, tức là không đưa vào danh sách rút gọn hoặc khen thưởng.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 6: Staff Development (10 grade)",
        vi: "Mục 6: Phát triển nhân viên (10 điểm)",
      },
      description: [
        {
          en: "- Provide clear evidence of personal involvement in staff development and mentorship initiatives, particularly their involvement in nurturing young talent.",
          vi: "- Cung cấp bằng chứng rõ ràng về sự tham gia của cá nhân vào các sáng kiến cố vấn và phát triển nhân viên, đặc biệt là sự tham gia của họ trong việc nuôi dưỡng tài năng trẻ.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 7: Contribution (10 grade)",
        vi: "Mục 7: Sự đóng góp (10 điểm)",
      },
      description: [
        {
          en: "- What industry initiatives and programs did you support and how?",
          vi: "- Bạn đã hỗ trợ những sáng kiến và chương trình nào trong ngành và bằng cách nào?",
        },
        {
          en: `- Provide examples of the individual's contribution and describe any contributions to industry advancement and improving the image of advertising in your marketplace and the quality of creativity.`,
          vi: "- Cung cấp ví dụ về đóng góp của cá nhân và mô tả bất kỳ đóng góp nào cho sự phát triển của ngành cũng như cải thiện hình ảnh quảng cáo trên thị trường của bạn cũng như chất lượng sáng tạo.",
        },
      ],
      points: 10,
    },
  ],
  "dao-dien-cua-nam": [
    {
      title: {
        en: "Section 1: Creative and Technical Excellence (50 grade)",
        vi: "Mục 1: Xuất sắc về sáng tạo và kỹ thuật (50 điểm)",
      },
      description: [
        {
          en: "- Artistic Vision and Innovation: Evaluate the nominee’s ability to craft a compelling and unique artistic vision for their projects, showcasing innovative storytelling techniques.",
          vi: "- Tầm nhìn nghệ thuật và đổi mới: Đánh giá khả năng của người được đề cử trong việc tạo ra tầm nhìn nghệ thuật hấp dẫn và độc đáo cho dự án của họ, thể hiện kỹ thuật kể chuyện sáng tạo.",
        },
        {
          en: "- Technical Skills and Production Quality: Assess the nominee’s proficiency in utilizing film and television production techniques, including cinematography, editing, and special effects, with a focus on the quality of their work and attention to detail.",
          vi: "- Trình độ kỹ thuật và chất lượng sản xuất: Đánh giá trình độ của người được đề cử trong việc sử dụng các kỹ thuật sản xuất phim và truyền hình, bao gồm quay phim, biên tập và hiệu ứng đặc biệt, tập trung vào chất lượng công việc và sự chú ý đến từng chi tiết.",
        },
      ],
      points: 50,
    },
    {
      title: {
        en: "Section 2: Impact and Audience Engagement (30 grade)",
        vi: "Mục 2: Tác động và mức độ tương tác của khán giả (30 điểm)",
      },
      description: [
        {
          en: "- Audience Reception and Engagement: including viewership ratings, box office performance, and online engagement.",
          vi: "- Sự tiếp nhận và tương tác của khán giả: bao gồm xếp hạng người xem, hiệu suất phòng vé và mức độ tương tác trực tuyến.",
        },
        {
          en: "- Client and Stakeholder Relations: Detail the nominee’s effectiveness in maintaining strong relationships with clients, producers, and other key stakeholders.",
          vi: "- Quan hệ khách hàng và các bên liên quan: Trình bày chi tiết về hiệu quả của người được đề cử trong việc duy trì mối quan hệ bền chặt với khách hàng, nhà sản xuất và các bên liên quan quan trọng khác.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 3: Leadership and Professional Development (20 grade)",
        vi: "Mục 3: Khả năng lãnh đạo và phát triển chuyên môn (20 điểm)",
      },
      description: [
        {
          en: "- Team Leadership and Collaboration: Highlight the nominee’s ability to lead a production team, including actors, crew, and creative collaborators. Provide examples of effective leadership and team-building.",
          vi: "- Lãnh đạo và hợp tác nhóm: Làm nổi bật khả năng lãnh đạo nhóm sản xuất của người được đề cử, bao gồm diễn viên, đoàn làm phim và cộng tác viên sáng tạo. Cung cấp các ví dụ về lãnh đạo hiệu quả và xây dựng đội nhóm.",
        },
        {
          en: "- Professional Growth and Industry Contributions: Outline the nominee’s professional growth over the past year, including new skills acquired, educational pursuits, and significant career milestones, as well as contributions to the industry through speaking engagements, publications, mentorship roles, or involvement in industry associations and initiatives.",
          vi: "- Tăng trưởng chuyên nghiệp và đóng góp cho ngành: Phác thảo sự phát triển nghề nghiệp của người được đề cử trong năm qua, bao gồm các kỹ năng mới có được, mục tiêu học tập và các cột mốc quan trọng trong sự nghiệp, cũng như những đóng góp cho ngành thông qua các buổi diễn thuyết, ấn phẩm, vai trò cố vấn hoặc tham gia vào các hiệp hội và sáng kiến trong ngành.",
        },
      ],
      points: 20,
    },
  ],
  "dop-cua-nam": [
    {
      title: {
        en: "Section 1: Creative and Technical Excellence (50 grade)",
        vi: "Mục 1: Xuất sắc về sáng tạo và kỹ thuật (50 điểm)",
      },
      description: [
        {
          en: "- Visual Storytelling and Innovation: Evaluate the nominee’s ability to create compelling and visually stunning advertisements. Provide examples of standout commercials that demonstrate their creativity and innovation in cinematography.",
          vi: "- Kể chuyện bằng hình ảnh và đổi mới: Đánh giá khả năng của người được đề cử trong việc tạo ra các quảng cáo hấp dẫn và trực quan ấn tượng. Cung cấp các ví dụ về quảng cáo nổi bật thể hiện sự sáng tạo và đổi mới trong kỹ thuật quay phim.",
        },
        {
          en: "- Technical Mastery: Assess the nominee’s proficiency with camera equipment, lighting, and other technical aspects of shooting commercials. Focus on their ability to achieve high production values and maintain attention to detail.",
          vi: "- Nắm vững kỹ thuật: Đánh giá trình độ của người được đề cử với thiết bị máy ảnh, ánh sáng và các khía cạnh kỹ thuật khác khi quay quảng cáo. Tập trung vào khả năng của họ để đạt được giá trị sản xuất cao và duy trì sự chú ý đến từng chi tiết.",
        },
        {
          en: "- Consistency and Quality: Highlight the overall consistency and quality of the nominee’s work across multiple projects, emphasizing their reliability and expertise in delivering top-notch visual content.",
          vi: "- Tính nhất quán và chất lượng: Làm nổi bật tính nhất quán và chất lượng tổng thể trong công việc của người được đề cử trong nhiều dự án, nhấn mạnh độ tin cậy và chuyên môn của họ trong việc cung cấp nội dung trực quan hàng đầu.",
        },
      ],
      points: 50,
    },
    {
      title: {
        en: "Section 2: Impact and Collaboration (30 grade)",
        vi: "Mục 2: Tác động và hợp tác (30 điểm)",
      },
      description: [
        {
          en: "- Effectiveness in Advertising: Measure the success of the nominee’s work based on the effectiveness of the commercials in achieving marketing objectives, such as increased brand awareness, engagement, and sales. Include metrics and client feedback where possible.",
          vi: "- Hiệu quả trong quảng cáo: Đo lường sự thành công trong công việc của người được đề cử dựa trên hiệu quả của quảng cáo trong việc đạt được các mục tiêu tiếp thị, chẳng hạn như tăng nhận thức về thương hiệu, mức độ tương tác và doanh số bán hàng. Bao gồm các số liệu và phản hồi của khách hàng nếu có thể.",
        },
        {
          en: "- Collaboration with Creative Teams: Detail the nominee’s ability to work seamlessly with directors, producers, art directors, and other key members of the creative team. Provide examples of successful collaborations that resulted in highly effective advertisements.",
          vi: "- Hợp tác với các nhóm sáng tạo: Nêu chi tiết khả năng làm việc liền mạch của người được đề cử với các đạo diễn, nhà sản xuất, giám đốc nghệ thuật và các thành viên chủ chốt khác của nhóm sáng tạo. Cung cấp các ví dụ về sự hợp tác thành công mang lại hiệu quả quảng cáo cao.",
        },
        {
          en: "- Client and Stakeholder Relations: Highlight the nominee’s effectiveness in maintaining strong relationships with clients and other stakeholders, showcasing their ability to understand and fulfill client visions and requirements.",
          vi: "- Quan hệ khách hàng và các bên liên quan: Làm nổi bật tính hiệu quả của người được đề cử trong việc duy trì mối quan hệ bền chặt với khách hàng và các bên liên quan khác, thể hiện khả năng hiểu và đáp ứng tầm nhìn cũng như yêu cầu của khách hàng.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 3: Professional Development and Industry Contributions (20 grade)",
        vi: "Mục 3: Đóng góp cho ngành và phát triển chuyên môn (20 điểm)",
      },
      description: [
        {
          en: "- Career Growth and Skills Development: Outline the nominee’s professional growth over the past year, including new skills acquired, educational pursuits, and significant career milestones. Emphasize their commitment to continuous improvement and staying updated with industry trends.",
          vi: "- Phát triển nghề nghiệp và kỹ năng: Phác thảo sự phát triển nghề nghiệp của người được đề cử trong năm qua, bao gồm các kỹ năng mới có được, mục tiêu học tập và các cột mốc quan trọng trong sự nghiệp. Nhấn mạnh cam kết của họ về việc cải tiến liên tục và luôn cập nhật các xu hướng của ngành.",
        },
        {
          en: "- Industry Involvement and Mentorship: Detail the nominee’s contributions to the advertising and cinematography community, such as speaking engagements, publications, mentorship roles, or involvement in industry associations and initiatives. Highlight their efforts in advancing the profession and supporting emerging talent.",
          vi: "- Sự tham gia và cố vấn của ngành: Nêu chi tiết những đóng góp của người được đề cử cho cộng đồng quảng cáo và điện ảnh, chẳng hạn như tham gia diễn thuyết, xuất bản, vai trò cố vấn hoặc tham gia vào các hiệp hội và sáng kiến trong ngành. Nêu bật những nỗ lực của họ trong việc thăng tiến nghề nghiệp và hỗ trợ các tài năng mới nổi.",
        },
      ],
      points: 20,
    },
  ],
  "art-director-cua-nam": [
    {
      title: {
        en: "Section 1: Creative Vision and Innovation (30 grade)",
        vi: "Mục 1: Tầm nhìn sáng tạo và đổi mới (30 điểm)",
      },
      description: [
        {
          en: "- Artistic Innovation: Evaluate the nominee’s ability to develop visually captivating and innovative designs. Provide examples of standout projects that demonstrate their creativity and unique artistic vision.",
          vi: "- Đổi mới nghệ thuật: Đánh giá khả năng của người được đề cử trong việc phát triển các thiết kế sáng tạo và hấp dẫn về mặt thị giác. Cung cấp ví dụ về các dự án nổi bật thể hiện sự sáng tạo và tầm nhìn nghệ thuật độc đáo của họ.",
        },
        {
          en: "- Visual Storytelling: Assess how effectively the nominee’s designs contribute to the narrative and emotional impact of advertisements.",
          vi: "- Kể chuyện bằng hình ảnh: Đánh giá mức độ hiệu quả mà thiết kế của người được đề cử đóng góp vào tác động tường thuật và cảm xúc của quảng cáo.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 2: Design Execution and Quality (20 grade)",
        vi: "Mục 2: Thực hiện Thiết kế và Chất lượng (20 điểm)",
      },
      description: [
        {
          en: "- Set and Environment Design: Evaluate the nominee’s proficiency in designing sets, locations, and environments. Highlight their attention to detail, aesthetic consistency, and the overall quality of their design work.",
          vi: "- Thiết kế bối cảnh và môi trường: Đánh giá trình độ của người được đề cử trong việc thiết kế bối cảnh, địa điểm và môi trường. Làm nổi bật sự chú ý của họ đến từng chi tiết, tính nhất quán về mặt thẩm mỹ và chất lượng tổng thể của công việc thiết kế của họ.",
        },
        {
          en: "- Brand Alignment: Examine the nominee’s ability to align their visual designs with brand guidelines and marketing objectives, ensuring cohesive and memorable visual experiences.",
          vi: "- Liên kết thương hiệu: Kiểm tra khả năng của người được đề cử trong việc điều chỉnh thiết kế hình ảnh của họ với các nguyên tắc thương hiệu và mục tiêu tiếp thị, đảm bảo trải nghiệm hình ảnh gắn kết và đáng nhớ.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 3: Collaboration and Team Leadership (20 grade)",
        vi: "Mục 3: Hợp tác và lãnh đạo nhóm (20 điểm)",
      },
      description: [
        {
          en: "- Team Leadership: Detail the nominee’s effectiveness in leading and collaborating with production designers, directors, cinematographers, and other key members of the production team. Provide examples of successful teamwork and the nominee’s role in facilitating a cohesive production process.",
          vi: "- Lãnh đạo nhóm: Trình bày chi tiết về hiệu quả của người được đề cử trong việc lãnh đạo và cộng tác với các nhà thiết kế sản xuất, đạo diễn, nhà quay phim và các thành viên chủ chốt khác của nhóm sản xuất. Cung cấp các ví dụ về tinh thần đồng đội thành công và vai trò của người được đề cử trong việc tạo điều kiện cho quá trình sản xuất gắn kết.",
        },
        {
          en: "- Communication Skills: Showcase the nominee’s ability to clearly communicate their vision and requirements to the production team, ensuring that all visual elements are executed according to plan.",
          vi: "- Kỹ năng giao tiếp: Thể hiện khả năng của người được đề cử trong việc truyền đạt rõ ràng tầm nhìn và yêu cầu của họ với nhóm sản xuất, đảm bảo rằng tất cả các yếu tố hình ảnh đều được thực hiện theo đúng kế hoạch. ",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Project Management and Client Relations (20 grade)",
        vi: "Mục 4: Quản lý dự án và quan hệ khách hàng (20 điểm)",
      },
      description: [
        {
          en: "- Project Execution: Measure the nominee’s ability to manage the execution of their designs, including overseeing the construction of sets, sourcing props, and ensuring that all visual elements are completed on time and within budget.",
          vi: "- Thực hiện dự án: Đo lường khả năng của người được đề cử trong việc quản lý việc thực hiện thiết kế của họ, bao gồm giám sát việc xây dựng bối cảnh, tìm nguồn cung ứng đạo cụ và đảm bảo rằng tất cả các yếu tố hình ảnh được hoàn thành đúng thời gian và trong ngân sách.",
        },
        {
          en: "- Client Relations: Highlight the nominee’s effectiveness in understanding and fulfilling client visions and requirements, maintaining strong relationships with clients and other stakeholders throughout the production process.",
          vi: "- Quan hệ khách hàng: Làm nổi bật tính hiệu quả của người được đề cử trong việc hiểu và đáp ứng tầm nhìn cũng như yêu cầu của khách hàng, duy trì mối quan hệ bền chặt với khách hàng và các bên liên quan khác trong suốt quá trình sản xuất.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Professional Growth and Industry Contributions (10 grade)",
        vi: "Mục 5: Tăng trưởng chuyên nghiệp và đóng góp của ngành (10 điểm)",
      },
      description: [
        {
          en: "- Career Development: Outline the nominee’s professional growth over the past year, including new skills acquired, educational pursuits, and significant career milestones. Emphasize their dedication to continuous improvement and staying current with industry trends and technologies.",
          vi: "- Phát triển nghề nghiệp: Phác thảo sự phát triển nghề nghiệp của người được đề cử trong năm qua, bao gồm các kỹ năng mới có được, mục tiêu học tập và các cột mốc quan trọng trong sự nghiệp. Nhấn mạnh sự cống hiến của họ trong việc cải tiến liên tục và theo kịp các xu hướng và công nghệ của ngành.",
        },
        {
          en: "- Industry Involvement: Detail the nominee’s contributions to the advertising and film production community, such as speaking engagements, publications, mentorship roles, or involvement in industry associations and initiatives. Highlight their efforts in advancing the profession and supporting emerging talent.",
          vi: "- Sự tham gia của ngành: Nêu chi tiết những đóng góp của người được đề cử cho cộng đồng sản xuất phim và quảng cáo, chẳng hạn như tham gia diễn thuyết, xuất bản, vai trò cố vấn hoặc tham gia vào các hiệp hội và sáng kiến trong ngành. Nêu bật những nỗ lực của họ trong việc thăng tiến nghề nghiệp và hỗ trợ các tài năng mới nổi.",
        },
      ],
      points: 10,
    },
  ],
  "music-composer-cua-nam": [
    {
      title: {
        en: "Section 1: Creative Vision and Innovation (35 grade)",
        vi: "Mục 1: Tầm nhìn sáng tạo và đổi mới (35 điểm)",
      },
      description: [
        {
          en: "- Musical Innovation: Evaluate the nominee’s ability to develop compositions that are original, innovative, and push the boundaries of the genre. Highlight examples of standout projects that showcase their creativity and unique musical vision.",
          vi: "- Đổi mới âm nhạc: Đánh giá khả năng của người được đề cử trong việc phát triển các sáng tác độc đáo, sáng tạo và vượt qua ranh giới của thể loại. Nêu bật các ví dụ về các dự án nổi bật thể hiện sự sáng tạo và tầm nhìn âm nhạc độc đáo của họ.",
        },
        {
          en: "- Emotional Impact: Assess how effectively the nominee’s compositions contribute to the narrative and evoke emotion, enhancing the overall impact of the project.",
          vi: "- Tác động về mặt cảm xúc: Đánh giá mức độ hiệu quả mà tác phẩm của người được đề cử đóng góp vào câu chuyện và khơi gợi cảm xúc, nâng cao tác động chung của dự án.",
        },
      ],
      points: 35,
    },
    {
      title: {
        en: "Section 2: Composition Execution and Quality (30 grade)",
        vi: "Mục 2: Thực hiện Sáng tác và Chất lượng (30 điểm)",
      },
      description: [
        {
          en: "- Musical Arrangement: Examine the nominee’s proficiency in arranging and orchestrating music, focusing on the complexity, depth, and cohesiveness of the compositions.",
          vi: "- Biên soạn âm nhạc: Kiểm tra trình độ của người được đề cử trong việc biên soạn và phối khí âm nhạc, tập trung vào độ phức tạp, chiều sâu và tính gắn kết của các tác phẩm.",
        },
        {
          en: "- Sound Quality and Production: Evaluate the technical quality of the music, including sound design, mixing, and mastering, ensuring the compositions are sonically rich and well-produced.",
          vi: "- Chất lượng âm thanh và sản xuất: Đánh giá chất lượng kỹ thuật của âm nhạc, bao gồm thiết kế âm thanh, phối khí và master, đảm bảo các tác phẩm có âm thanh phong phú và được sản xuất tốt.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 3: Collaboration and Adaptability (20 grade)",
        vi: "Mục 3: Hợp tác và Khả năng thích nghi (20 điểm)",
      },
      description: [
        {
          en: "- Team Collaboration: Detail the nominee’s effectiveness in working with directors, producers, and other key members of the production team. Provide examples of successful collaborations and the nominee’s ability to adapt their music to fit different creative visions.",
          vi: "- Thực hiện dự án: Đo lường khả năng của người được đề cử trong việc quản lý việc thực hiện thiết kế của họ, bao gồm giám sát việc xây dựng bối cảnh, tìm nguồn cung ứng đạo cụ và đảm bảo rằng tất cả các yếu tố hình ảnh được hoàn thành đúng thời gian và trong ngân sách.",
        },
        {
          en: "- Versatility: Highlight the nominee’s ability to work across different genres and styles, demonstrating a wide range of musical expertise.",
          vi: "- Tính linh hoạt: Làm nổi bật khả năng làm việc với nhiều thể loại và phong cách khác nhau của người được đề cử, thể hiện trình độ chuyên môn âm nhạc sâu rộng.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Project Management and Client Relations (10 grade)",
        vi: "Mục 4: Quản lý dự án và quan hệ khách hàng (10 điểm)",
      },
      description: [
        {
          en: "- Project Execution: Measure the nominee’s ability to manage the execution of their compositions, including meeting deadlines and working within budget constraints.",
          vi: "- Thực hiện dự án: Đo lường khả năng của người được đề cử trong việc quản lý việc thực hiện các sáng tác của họ, bao gồm việc đáp ứng thời hạn và làm việc trong phạm vi ngân sách hạn chế.",
        },
        {
          en: "- Client Relations: Highlight the nominee’s effectiveness in understanding and fulfilling client visions and requirements, maintaining strong relationships with clients and other stakeholders throughout the production process.",
          vi: "- Quan hệ khách hàng: Làm nổi bật hiệu quả của người được đề cử trong việc hiểu và đáp ứng tầm nhìn và yêu cầu của khách hàng, duy trì mối quan hệ chặt chẽ với khách hàng và các bên liên quan khác trong suốt quá trình sản xuất.",
        },
      ],
      points: 10,
    },
    {
      title: {
        en: "Section 5: Professional Growth and Industry Contributions (5 grade)",
        vi: "Mục 5: Tăng trưởng chuyên nghiệp và đóng góp của ngành (5 điểm)",
      },
      description: [
        {
          en: "- Career Development: Outline the nominee’s professional growth over the past year, including new skills acquired, educational pursuits, and significant career milestones. Emphasize their dedication to continuous improvement and staying current with industry trends.",
          vi: "- Phát triển nghề nghiệp: Phác thảo sự phát triển nghề nghiệp của người được đề cử trong năm qua, bao gồm các kỹ năng mới có được, hoạt động giáo dục và các cột mốc quan trọng trong sự nghiệp. Nhấn mạnh sự cống hiến của họ cho việc cải tiến liên tục và theo kịp xu hướng của ngành.",
        },
        {
          en: "- Industry Involvement: Detail the nominee’s contributions to the music and entertainment industry, such as speaking engagements, publications, mentorship roles, or involvement in industry associations and initiatives. Highlight their efforts in advancing the profession and supporting emerging talent.",
          vi: "- Sự tham gia của ngành: Nêu chi tiết những đóng góp của người được đề cử cho ngành âm nhạc và giải trí, chẳng hạn như các buổi nói chuyện, ấn phẩm, vai trò cố vấn hoặc sự tham gia vào các hiệp hội và sáng kiến ​​của ngành. Nêu bật những nỗ lực của họ trong việc thúc đẩy nghề nghiệp và hỗ trợ tài năng mới nổi.",
        },
      ],
      points: 5,
    },
  ],
  "photographer-cua-nam": [
    {
      title: {
        en: "Section 1: Creative Vision and Innovation (30 grade)",
        vi: "Mục 1: Tầm nhìn sáng tạo và đổi mới (30 điểm)",
      },
      description: [
        {
          en: "-Artistic Innovation: Evaluate the nominee’s ability to develop visually compelling and innovative photographs. Provide examples of standout work that demonstrates their creativity and unique artistic vision in both commercial and non-commercial contexts.",
          vi: "- Đổi mới nghệ thuật: Đánh giá khả năng của người được đề cử trong việc phát triển những bức ảnh sáng tạo và hấp dẫn về mặt hình ảnh. Cung cấp các ví dụ về tác phẩm nổi bật thể hiện sự sáng tạo và tầm nhìn nghệ thuật độc đáo của họ trong cả bối cảnh thương mại và phi thương mại.",
        },
        {
          en: "- Concept Development: Assess how effectively the nominee translates concepts and ideas into visually striking images that align with the goals of their clients (commercial) and their personal artistic expression (non-commercial).",
          vi: "- Phát triển khái niệm: Đánh giá mức độ hiệu quả mà người được đề cử chuyển tải các khái niệm và ý tưởng thành những hình ảnh trực quan ấn tượng phù hợp với mục tiêu của khách hàng (thương mại) và sự thể hiện nghệ thuật cá nhân của họ (phi thương mại).",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 2: Technical Proficiency and Quality (20 grade)",
        vi: "Mục 2: Trình độ kỹ thuật và Chất lượng (20 điểm)",
      },
      description: [
        {
          en: "- Technical Skills: Evaluate the nominee’s proficiency in using photography equipment, lighting techniques, and post-production processes. Highlight their attention to detail and the overall technical quality of their photographs across various genres.",
          vi: "- Kỹ năng kỹ thuật: Đánh giá trình độ của người được đề cử trong việc sử dụng thiết bị chụp ảnh, kỹ thuật ánh sáng và quy trình hậu kỳ. Làm nổi bật sự chú ý của họ đến từng chi tiết và chất lượng kỹ thuật tổng thể của các bức ảnh của họ ở nhiều thể loại khác nhau.",
        },
        {
          en: "- Consistency and Quality: Examine the nominee’s ability to maintain high-quality standards in different types of photography, ensuring reliability and excellence in their work, whether it’s for commercial advertising or personal projects.",
          vi: "- Tính nhất quán và chất lượng: Kiểm tra khả năng của người được đề cử trong việc duy trì các tiêu chuẩn chất lượng cao trong các thể loại nhiếp ảnh khác nhau, đảm bảo độ tin cậy và sự xuất sắc trong tác phẩm của họ, cho dù đó là quảng cáo thương mại hay dự án cá nhân.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 3: Impact and Versatility (20 grade)",
        vi: "Mục 3: Tác động và tính linh hoạt (20 điểm)",
      },
      description: [
        {
          en: "- Effectiveness in Advertising: Measure the success of the nominee’s commercial work based on its effectiveness in achieving marketing objectives, such as increased brand awareness, engagement, and sales. Include metrics and client feedback where possible.",
          vi: "- Hiệu quả trong quảng cáo: Đo lường sự thành công trong công việc thương mại của người được đề cử dựa trên tính hiệu quả của nó trong việc đạt được các mục tiêu tiếp thị, chẳng hạn như tăng nhận thức về thương hiệu, mức độ tương tác và doanh số bán hàng. Bao gồm các số liệu và phản hồi của khách hàng nếu có thể.",
        },
        {
          en: "- Audience Reception: Evaluate the impact of the nominee’s non-commercial work based on audience reception, including exhibition attendance, online engagement, and critical acclaim. Highlight the ability to connect with diverse audiences through different styles and subjects.",
          vi: "- Tiếp đón khán giả: Đánh giá tác động của tác phẩm phi thương mại của người được đề cử dựa trên sự đón nhận của khán giả, bao gồm việc tham dự triển lãm, tương tác trực tuyến và sự hoan nghênh của giới phê bình. Làm nổi bật khả năng kết nối với nhiều đối tượng khán giả khác nhau thông qua các phong cách và chủ đề khác nhau.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Collaboration and Professionalism (20 grade)",
        vi: "Mục 4: Hợp tác và chuyên nghiệp (20 điểm)",
      },
      description: [
        {
          en: "- Team Collaboration: Detail the nominee’s ability to work seamlessly with creative teams, including art directors, stylists, and marketing professionals for commercial work, as well as their ability to collaborate with subjects and other artists in non-commercial projects. Provide examples of successful collaborations that resulted in highly effective photographs.",
          vi: "- Hợp tác nhóm: Trình bày chi tiết khả năng làm việc liền mạch của người được đề cử với các nhóm sáng tạo, bao gồm giám đốc nghệ thuật, nhà tạo mẫu và chuyên gia tiếp thị cho công việc thương mại cũng như khả năng cộng tác với các đối tượng và nghệ sĩ khác trong các dự án phi thương mại. Cung cấp các ví dụ về sự hợp tác thành công mang lại những bức ảnh có hiệu quả cao.",
        },
        {
          en: "- Professional Conduct: Showcase the nominee’s professionalism in managing shoots, directing subjects, and handling logistics, ensuring smooth and efficient production processes in both commercial and non-commercial settings.",
          vi: "- Đạo đức nghề nghiệp: Thể hiện tính chuyên nghiệp của người được đề cử trong việc quản lý cảnh quay, chỉ đạo đối tượng và xử lý hậu cần, đảm bảo quy trình sản xuất suôn sẻ và hiệu quả ở cả môi trường thương mại và phi thương mại.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 5: Professional Growth and Industry Contributions (10 grade)",
        vi: "Mục 5: Sự phát triển chuyên nghiệp và đóng góp của ngành (10 điểm)",
      },
      description: [
        {
          en: "- Career Development: Outline the nominee’s professional growth over the past year, including new skills acquired, educational pursuits, and significant career milestones. Emphasize their commitment to continuous improvement and staying updated with industry trends and technologies.",
          vi: "- Phát triển nghề nghiệp: Phác thảo sự phát triển nghề nghiệp của người được đề cử trong năm qua, bao gồm các kỹ năng mới có được, mục tiêu học tập và các cột mốc quan trọng trong sự nghiệp. Nhấn mạnh cam kết của họ trong việc cải tiến liên tục và luôn cập nhật các xu hướng và công nghệ của ngành.",
        },
        {
          en: "- Industry Involvement: Detail the nominee’s contributions to the photography community, such as speaking engagements, publications, mentorship roles, or involvement in industry associations and initiatives. Highlight their efforts in advancing the profession and supporting emerging talent, across both commercial and non-commercial spheres.",
          vi: "- Sự tham gia của ngành: Nêu chi tiết những đóng góp của người được đề cử cho cộng đồng nhiếp ảnh, chẳng hạn như tham gia diễn thuyết, xuất bản, vai trò cố vấn hoặc tham gia vào các hiệp hội và sáng kiến trong ngành. Nêu bật những nỗ lực của họ trong việc thăng tiến nghề nghiệp và hỗ trợ các tài năng mới nổi, trên cả lĩnh vực thương mại và phi thương mại.",
        },
      ],
      points: 10,
    },
  ],
  "kol-noi-bat-cua-nam": [
    {
      title: {
        en: "Section 1: Influence and Reach (30 grade)",
        vi: "Mục 1: Ảnh hưởng và phạm vi tiếp cận (30 điểm)",
      },
      description: [
        {
          en: "- Level of Influence: Evaluate the nominee’s overall influence, including the number of followers, interaction rates, and their ability to spread messages across various platforms. Provide metrics and examples that demonstrate their reach and impact.",
          vi: "- Mức độ ảnh hưởng: Đánh giá mức độ ảnh hưởng tổng thể của người được đề cử, bao gồm số lượng người theo dõi, tỷ lệ tương tác và khả năng truyền bá thông điệp của họ trên nhiều nền tảng khác nhau. Cung cấp các số liệu và ví dụ chứng minh phạm vi tiếp cận và tác động của chúng.",
        },
        {
          en: "- Audience Engagement: Assess the nominee’s ability to engage and interact with their audience effectively. Highlight the quality of their interactions and the depth of their connection with followers.",
          vi: "- Sự tham gia của khán giả: Đánh giá khả năng thu hút và tương tác với khán giả của họ một cách hiệu quả của người được đề cử. Làm nổi bật chất lượng tương tác của họ và mức độ kết nối sâu sắc của họ với những người theo dõi.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 2: Content Quality and Creativity (25 grade)",
        vi: "Mục 2: Chất lượng nội dung và sự sáng tạo (25 điểm)",
      },
      description: [
        {
          en: "- Content Excellence: Evaluate the quality of the content produced by the nominee, including creativity, originality, and the value it provides to their audience. Provide examples of standout content that showcases their unique voice and perspective.",
          vi: "- Nội dung xuất sắc: Đánh giá chất lượng nội dung do người được đề cử sản xuất, bao gồm tính sáng tạo, tính độc đáo và giá trị mà nội dung đó mang lại cho khán giả của họ. Cung cấp ví dụ về nội dung nổi bật thể hiện quan điểm và tiếng nói độc đáo của họ.",
        },
        {
          en: "- Innovation: Assess the nominee’s ability to introduce new ideas, formats, or approaches in their content. Highlight instances where their creativity has set trends or elevated industry standards.",
          vi: "- Sự đổi mới: Đánh giá khả năng của người được đề cử trong việc giới thiệu những ý tưởng, hình thức hoặc cách tiếp cận mới trong nội dung của họ. Nêu bật những trường hợp mà sự sáng tạo của họ đã tạo ra xu hướng hoặc nâng cao tiêu chuẩn ngành.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Personal Image and Professionalism (20 grade)",
        vi: "Mục 3: Hình ảnh cá nhân và sự chuyên nghiệp (20 điểm)",
      },
      description: [
        {
          en: "- Authenticity and Professionalism: Examine the nominee’s personal image, focusing on their authenticity, professionalism, and adherence to ethical standards. Provide examples of how they maintain a positive and credible public image.",
          vi: "- Tính xác thực và chuyên nghiệp: Kiểm tra hình ảnh cá nhân của người được đề cử, tập trung vào tính xác thực, tính chuyên nghiệp và việc tuân thủ các tiêu chuẩn đạo đức của họ. Cung cấp các ví dụ về cách họ duy trì hình ảnh tích cực và đáng tin cậy trước công chúng.",
        },
        {
          en: "- Ethical Conduct: Evaluate how the nominee handles partnerships, endorsements, and public interactions, ensuring they align with ethical practices and contribute positively to their field.",
          vi: "- Ứng xử đạo đức: Đánh giá cách người được đề cử xử lý các mối quan hệ đối tác, chứng thực và tương tác với công chúng, đảm bảo chúng phù hợp với các thông lệ đạo đức và đóng góp tích cực cho lĩnh vực của họ. ",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Community Engagement and Representation (15 grade)",
        vi: "Mục 4: Sự tham gia và đại diện của cộng đồng (15 điểm)",
      },
      description: [
        {
          en: "- Community Activities: Detail the nominee’s involvement in community activities, including their role in influencing, representing, and advocating for their community. Provide examples of how they contribute to community development and support relevant causes.",
          vi: "- Hoạt động cộng đồng: Trình bày chi tiết về sự tham gia của người được đề cử vào các hoạt động cộng đồng, bao gồm vai trò của họ trong việc gây ảnh hưởng, đại diện và vận động cho cộng đồng của họ. Cung cấp các ví dụ về cách họ đóng góp cho sự phát triển cộng đồng và hỗ trợ các mục đích liên quan.",
        },
        {
          en: "- Impact on Community: Assess the nominee’s ability to make a positive impact within their community through initiatives, collaborations, or advocacy.",
          vi: "- Tác động tới cộng đồng: Đánh giá khả năng của người được đề cử trong việc tạo ra tác động tích cực trong cộng đồng của họ thông qua các sáng kiến, hợp tác hoặc vận động chính sách.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 5: Marketing Effectiveness (10 grade)",
        vi: "Mục 5: Hiệu quả tiếp thị (10 điểm)",
      },
      description: [
        {
          en: "- Brand Collaboration and Promotion: Evaluate the nominee’s effectiveness in attracting customers, increasing brand awareness, and promoting sales through their platforms. Provide examples of successful marketing campaigns or partnerships that demonstrate their ability to drive commercial success.",
          vi: "- Hợp tác và quảng bá thương hiệu: Đánh giá hiệu quả của người được đề cử trong việc thu hút khách hàng, nâng cao nhận thức về thương hiệu và thúc đẩy doanh số thông qua nền tảng của họ. Cung cấp ví dụ về các chiến dịch tiếp thị hoặc quan hệ đối tác thành công chứng tỏ khả năng thúc đẩy thành công thương mại của họ.",
        },
        {
          en: "- Conversion Metrics: Assess the impact of their efforts on measurable outcomes, such as increased engagement, follower growth, or direct contributions to sales and brand recognition.",
          vi: "- Chỉ số chuyển đổi: Đánh giá tác động của những nỗ lực của họ đối với các kết quả có thể đo lường được, chẳng hạn như tăng mức độ tương tác, tăng lượng người theo dõi hoặc đóng góp trực tiếp vào doanh số bán hàng và nhận diện thương hiệu.",
        },
      ],
      points: 10,
    },
  ],
  "nha-sang-tao-noi-dung-an-tuong": [
    {
      title: {
        en: "Section 1: Creativity and Originality (30 grade)",
        vi: "Mục 1: Tính sáng tạo và độc đáo (30 điểm)",
      },
      description: [
        {
          en: "- Creative Vision: Evaluate the nominee’s ability to produce original and innovative content. Provide examples that showcase their unique perspective and creativity in content creation.",
          vi: "- Tầm nhìn sáng tạo: Đánh giá khả năng của người được đề cử trong việc tạo ra nội dung gốc và sáng tạo. Cung cấp các ví dụ thể hiện quan điểm độc đáo và sự sáng tạo của họ trong việc tạo nội dung.",
        },
        {
          en: "- Innovative Approaches: Assess how the nominee introduces new ideas, formats, or storytelling techniques. Highlight instances where their creativity has set trends or broken new ground.",
          vi: "- Phương pháp đổi mới: Đánh giá cách người được đề cử giới thiệu những ý tưởng, hình thức hoặc kỹ thuật kể chuyện mới. Nêu bật những trường hợp mà sự sáng tạo của họ đã tạo ra xu hướng hoặc tạo ra nền tảng mới.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 2: Content Quality (25 grade)",
        vi: "Mục 2: Chất lượng nội dung (25 điểm)",
      },
      description: [
        {
          en: "- Excellence and Production Value: Evaluate the overall quality of the content produced, including clarity, technical skill, and visual appeal. Highlight high production values and attention to detail.",
          vi: "- Sự xuất sắc và giá trị sản xuất: Đánh giá chất lượng tổng thể của nội dung được tạo ra, bao gồm độ rõ ràng, kỹ năng kỹ thuật và sự hấp dẫn về mặt hình ảnh. Làm nổi bật giá trị sản xuất cao và sự chú ý đến từng chi tiết.",
        },
        {
          en: "- Relevance and Suitability: Assess how well the content aligns with the intended topic or theme. Ensure that the content is appropriate, engaging, and effectively tailored to the subject matter.",
          vi: "- Tính phù hợp và phù hợp: Đánh giá mức độ phù hợp của nội dung với chủ đề hoặc chủ đề dự định. Đảm bảo rằng nội dung phù hợp, hấp dẫn và phù hợp một cách hiệu quả với chủ đề.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Positive Impact (20 grade)",
        vi: "Mục 3: Tác động tích cực (20 điểm)",
      },
      description: [
        {
          en: "- Impact on Audience: Measure the positive influence of the nominee’s content on their audience, including its ability to inspire, educate, or entertain. Provide examples of content that has had a meaningful effect on viewers or readers.",
          vi: "- Tác động đến khán giả: Đo lường mức độ ảnh hưởng tích cực của nội dung của người được đề cử đối với khán giả của họ, bao gồm khả năng truyền cảm hứng, giáo dục hoặc giải trí. Cung cấp ví dụ về nội dung có tác động có ý nghĩa đối với người xem hoặc người đọc.",
        },
        {
          en: "- Community Contribution: Assess how the content contributes to discussions or movements within the community. Highlight efforts that promote positive change or raise awareness about important issues.",
          vi: "- Đóng góp của cộng đồng: Đánh giá mức độ đóng góp của nội dung vào các cuộc thảo luận hoặc phong trào trong cộng đồng. Nêu bật những nỗ lực thúc đẩy sự thay đổi tích cực hoặc nâng cao nhận thức về các vấn đề quan trọng.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Engagement and Reach (15 grade)",
        vi: "Mục 4: Sự tương tác và phạm vi tiếp cận (15 điểm)",
      },
      description: [
        {
          en: "- Followers and Interactions: Evaluate the nominee’s presence on social networking platforms, including the number of followers and the level of interaction with their content. Provide metrics and examples that demonstrate their reach and influence.",
          vi: "- Người theo dõi và tương tác: Đánh giá sự hiện diện của người được đề cử trên nền tảng mạng xã hội, bao gồm số lượng người theo dõi và mức độ tương tác với nội dung của họ. Cung cấp các số liệu và ví dụ chứng minh phạm vi tiếp cận và tầm ảnh hưởng của họ.",
        },
        {
          en: "- Engagement Quality: Assess the quality of interactions with their audience, focusing on meaningful engagement, discussions, and feedback.",
          vi: "- Chất lượng tương tác: Đánh giá chất lượng tương tác với khán giả, tập trung vào sự tham gia, thảo luận và phản hồi có ý nghĩa.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 5: Topic Dissemination and Community Involvement (10 grade)",
        vi: "Mục 5: Chủ đề Phổ biến và Sự tham gia của cộng đồng (10 điểm)",
      },
      description: [
        {
          en: "- Spreading the Topic: Detail the nominee’s effectiveness in spreading their content’s topic to a broader community. Provide examples of how they have successfully shared or promoted relevant topics or discussions.",
          vi: "- Truyền bá chủ đề: Trình bày chi tiết về hiệu quả của người được đề cử trong việc truyền bá chủ đề nội dung của họ tới cộng đồng rộng lớn hơn. Cung cấp ví dụ về cách họ đã chia sẻ hoặc quảng bá thành công các chủ đề hoặc cuộc thảo luận có liên quan.",
        },
        {
          en: "- Community Engagement: Evaluate the nominee’s role in engaging with and contributing to their community through their content. Highlight initiatives, collaborations, or activities that support or involve the community.",
          vi: "- Sự tham gia của cộng đồng: Đánh giá vai trò của người được đề cử trong việc tương tác và đóng góp cho cộng đồng thông qua nội dung của họ. Nêu bật các sáng kiến, sự hợp tác hoặc hoạt động hỗ trợ hoặc thu hút sự tham gia của cộng đồng.",
        },
      ],
      points: 10,
    },
  ],
  "social-seller-cua-nam": [
    {
      title: {
        en: "Section 1: Sales Performance and Effectiveness (30 grade)",
        vi: "Mục 1: Hiệu suất và hiệu quả bán hàng (30 điểm)",
      },
      description: [
        {
          en: "- Sales Through Social Networks: Evaluate the nominee’s success in generating sales through social media platforms over the past year. Provide metrics and examples demonstrating their ability to drive revenue through social selling.",
          vi: "- Bán hàng qua mạng xã hội: Đánh giá sự thành công của người được đề cử trong việc tạo ra doanh số bán hàng thông qua các nền tảng truyền thông xã hội trong năm qua. Cung cấp các số liệu và ví dụ thể hiện khả năng tăng doanh thu thông qua hoạt động bán hàng trên mạng xã hội.",
        },
        {
          en: "- Conversion Rates: Assess the effectiveness of their social selling strategies, including conversion rates from social interactions to actual sales. Highlight achievements in turning social media engagement into tangible business outcomes.",
          vi: "- Tỷ lệ chuyển đổi: Đánh giá hiệu quả của chiến lược bán hàng trên mạng xã hội của họ, bao gồm tỷ lệ chuyển đổi từ tương tác trên mạng xã hội sang doanh số bán hàng thực tế. Nêu bật những thành tựu trong việc biến sự tương tác trên mạng xã hội thành kết quả kinh doanh hữu hình.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 2: Content Quality and Creativity (24 grade)",
        vi: "Mục 2: Chất lượng nội dung và sự sáng tạo (25 điểm)",
      },
      description: [
        {
          en: "- Quality of Content: Evaluate the quality of the content posted on social networks, including its visual appeal, clarity, and relevance to the audience. Highlight examples of high-quality, well-produced content.",
          vi: "- Chất lượng nội dung: Đánh giá chất lượng nội dung được đăng trên mạng xã hội, bao gồm mức độ hấp dẫn về mặt hình ảnh, sự rõ ràng và mức độ phù hợp với khán giả. Nêu bật các ví dụ về nội dung chất lượng cao, được sản xuất tốt.",
        },
        {
          en: "- Creativity and Innovation: Assess the nominee’s ability to create engaging and innovative content that stands out. Provide examples of creative approaches and unique content that capture audience attention and drive engagement.",
          vi: "- Tính sáng tạo và đổi mới: Đánh giá khả năng của người được đề cử trong việc tạo ra nội dung hấp dẫn và sáng tạo nổi bật. Cung cấp ví dụ về các phương pháp tiếp cận sáng tạo và nội dung độc đáo thu hút sự chú ý của khán giả và thúc đẩy sự tương tác.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Customer Engagement and Interaction (20 grade)",
        vi: "Mục 3: Sự tham gia và tương tác của khách hàng (20 điểm)",
      },
      description: [
        {
          en: "- Attracting Customers: Measure the nominee’s effectiveness in attracting and engaging with customers through their social media content. Provide examples of successful campaigns or posts that have significantly increased customer interest.",
          vi: "- Thu hút khách hàng: Đo lường hiệu quả của người được đề cử trong việc thu hút và tương tác với khách hàng thông qua nội dung truyền thông xã hội của họ. Cung cấp ví dụ về các chiến dịch hoặc bài đăng thành công đã làm tăng đáng kể sự quan tâm của khách hàng.",
        },
        {
          en: "- Interaction Quality: Evaluate how well the nominee interacts with their audience, including the quality of their responses, engagement in conversations, and overall customer relationship management.",
          vi: "- Chất lượng tương tác: Đánh giá mức độ người được đề cử tương tác với khán giả của họ, bao gồm chất lượng phản hồi của họ, mức độ tham gia vào các cuộc trò chuyện và quản lý quan hệ khách hàng tổng thể.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Community Participation and Involvement (15 grade)",
        vi: "Mục 4: Sự tham gia và tương tác của cộng đồng (15 điểm)",
      },
      description: [
        {
          en: "- Participation in Community Activities: Detail the nominee’s involvement in community activities on social networks, including their role in participating in, supporting, or organizing community-related events or discussions.",
          vi: "- Tham gia các hoạt động cộng đồng: Nêu chi tiết sự tham gia của người được đề cử vào các hoạt động cộng đồng trên mạng xã hội, bao gồm vai trò của họ trong việc tham gia, hỗ trợ hoặc tổ chức các sự kiện hoặc thảo luận liên quan đến cộng đồng.",
        },
        {
          en: "- Influence and Representation: Assess the impact of their participation in community activities, including their ability to represent and contribute positively to the community through social media.",
          vi: "- Ảnh hưởng và đại diện: Đánh giá tác động của việc họ tham gia vào các hoạt động cộng đồng, bao gồm khả năng đại diện và đóng góp tích cực cho cộng đồng thông qua mạng xã hội.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 5: Social/Community Responsibility (10 grade)",
        vi: "Mục 5: Trách nhiệm xã hội/cộng đồng (10 điểm)",
      },
      description: [
        {
          en: "- Ethical Conduct: Evaluate the nominee’s adherence to social and community responsibility principles, including ethical practices in their social selling activities and content creation.",
          vi: "- Ứng xử đạo đức: Đánh giá sự tuân thủ của người được đề cử đối với các nguyên tắc trách nhiệm xã hội và cộng đồng, bao gồm cả các nguyên tắc đạo đức trong hoạt động bán hàng trên mạng xã hội và sáng tạo nội dung của họ.",
        },
        {
          en: "- Positive Impact: Assess the nominee’s efforts in using their platform for positive social impact, such as promoting causes, supporting charitable initiatives, or raising awareness about important issues.",
          vi: "- Tác động tích cực: Đánh giá nỗ lực của người được đề cử trong việc sử dụng nền tảng của họ để tạo ra tác động xã hội tích cực, chẳng hạn như thúc đẩy các hoạt động xã hội, hỗ trợ các sáng kiến từ thiện hoặc nâng cao nhận thức về các vấn đề quan trọng.",
        },
      ],
      points: 10,
    },
  ],
  "dai-su-quang-cao-truyen-cam-hung": [
    {
      title: {
        en: "Section 1: Creativity and Originality (30 grade)",
        vi: "Mục 1: Tính sáng tạo và độc đáo (30 điểm)",
      },
      description: [
        {
          en: "- Creative Vision: Evaluate the nominee’s ability to produce original and innovative content. Provide examples that showcase their unique perspective and creativity in content creation.",
          vi: "- Tầm nhìn sáng tạo: Đánh giá khả năng của người được đề cử trong việc tạo ra nội dung gốc và sáng tạo. Cung cấp các ví dụ thể hiện quan điểm độc đáo và sự sáng tạo của họ trong việc tạo nội dung.",
        },
        {
          en: "- Innovative Approaches: Assess how the nominee introduces new ideas, formats, or storytelling techniques. Highlight instances where their creativity has set trends or broken new ground.",
          vi: "- Phương pháp đổi mới: Đánh giá cách người được đề cử giới thiệu những ý tưởng, hình thức hoặc kỹ thuật kể chuyện mới. Nêu bật những trường hợp mà sự sáng tạo của họ đã tạo ra xu hướng hoặc tạo ra nền tảng mới.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 2: Content Quality (25 grade)",
        vi: "Mục 2: Chất lượng nội dung (25 điểm)",
      },
      description: [
        {
          en: "- Excellence and Production Value: Evaluate the overall quality of the content produced, including clarity, technical skill, and visual appeal. Highlight high production values and attention to detail.",
          vi: "- Sự xuất sắc và Giá trị sản xuất: Đánh giá chất lượng tổng thể của nội dung được tạo ra, bao gồm độ rõ ràng, kỹ năng kỹ thuật và sự hấp dẫn về mặt hình ảnh. Làm nổi bật giá trị sản xuất cao và sự chú ý đến từng chi tiết.",
        },
        {
          en: "- Relevance and Suitability: Assess how well the content aligns with the intended topic or theme. Ensure that the content is appropriate, engaging, and effectively tailored to the subject matter.",
          vi: "- Tính phù hợp và phù hợp: Đánh giá mức độ phù hợp của nội dung với chủ đề hoặc chủ đề dự định. Đảm bảo rằng nội dung phù hợp, hấp dẫn và phù hợp một cách hiệu quả với chủ đề.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Inspiration Impact (20 grade)",
        vi: "Mục 3: Tác động truyền cảm hứng (20 điểm)",
      },
      description: [
        {
          en: "- Impact on Audience: Describe how advertising campaigns/projects inspire the audience, and society and bring positive value to the brand. Provide examples of content that has had a meaningful effect on viewers or readers.",
          vi: "- Tác động đến khán giả: Mô tả cách các chiến dịch/dự án quảng cáo truyền cảm hứng cho khán giả, xã hội và mang lại giá trị tích cực cho thương hiệu. Cung cấp ví dụ về nội dung có tác động có ý nghĩa đối với người xem hoặc người đọc.",
        },
        {
          en: "- Community Contribution: Assess how the content contributes to discussions or movements within the community. Highlight efforts that promote positive change or raise awareness about important issues.",
          vi: "- Đóng góp của cộng đồng: Đánh giá mức độ đóng góp của nội dung vào các cuộc thảo luận hoặc phong trào trong cộng đồng. Nêu bật những nỗ lực thúc đẩy sự thay đổi tích cực hoặc nâng cao nhận thức về các vấn đề quan trọng.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Engagement and Reach (15 grade)",
        vi: "Mục 4: Mức độ tương tác và phạm vi tiếp cận (15 điểm)",
      },
      description: [
        {
          en: "- Followers and Interactions: Evaluate the nominee’s presence on social networking platforms, including the number of followers and the level of interaction with their content. Provide metrics and examples that demonstrate their reach and influence.",
          vi: "- Người theo dõi và tương tác: Đánh giá sự hiện diện của người được đề cử trên nền tảng mạng xã hội, bao gồm số lượng người theo dõi và mức độ tương tác với nội dung của họ. Cung cấp các số liệu và ví dụ chứng minh phạm vi tiếp cận và tầm ảnh hưởng của họ.",
        },
        {
          en: "- Engagement Quality: Assess the quality of interactions with their audience, focusing on meaningful engagement, discussions, and feedback.",
          vi: "- Chất lượng tương tác: Đánh giá chất lượng tương tác với khán giả, tập trung vào sự tham gia, thảo luận và phản hồi có ý nghĩa.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 5: Topic Dissemination and Community Involvement (10 grade)",
        vi: "Mục 5: Chủ đề Phổ biến và Sự tham gia của cộng đồng (10 điểm)",
      },
      description: [
        {
          en: "- Spreading the Topic: Detail the nominee’s effectiveness in spreading their content’s topic to a broader community. Provide examples of how they have successfully shared or promoted relevant topics or discussions.",
          vi: "- Lan truyền chủ đề: Trình bày chi tiết về hiệu quả của người được đề cử trong việc truyền bá chủ đề nội dung của họ tới cộng đồng rộng lớn hơn. Cung cấp ví dụ về cách họ đã chia sẻ hoặc quảng bá thành công các chủ đề hoặc cuộc thảo luận có liên quan.",
        },
        {
          en: "- Community Engagement: Evaluate the nominee’s role in engaging with and contributing to their community through their content. Highlight initiatives, collaborations, or activities that support or involve the community.",
          vi: "- Sự tham gia của cộng đồng: Đánh giá vai trò của người được đề cử trong việc tương tác và đóng góp cho cộng đồng thông qua nội dung của họ. Nêu bật các sáng kiến, sự hợp tác hoặc hoạt động hỗ trợ hoặc thu hút sự tham gia của cộng đồng.",
        },
      ],
      points: 10,
    },
  ],
  "dai-su-quang-cao-an-tuong-nhat": [
    {
      title: {
        en: "Section 1: Brand Representation and Ambassadorship (30 grade)",
        vi: "Mục 1: Đại diện thương hiệu và Đại sứ (30 điểm)",
      },
      description: [
        {
          en: "- Brand Ambassador Role: Evaluate how effectively the nominee represents the brand. This includes their ability to embody the brand’s values, message, and identity in a positive and engaging manner.",
          vi: "- Vai trò Đại sứ Thương hiệu: Đánh giá mức độ hiệu quả của người được đề cử đại diện cho thương hiệu. Điều này bao gồm khả năng thể hiện các giá trị, thông điệp và bản sắc của thương hiệu theo cách tích cực và hấp dẫn.",
        },
        {
          en: "- Consistency and Alignment: Assess how well the nominee aligns with the brand’s goals and consistently conveys the brand’s message across different platforms and interactions.",
          vi: "- Tính nhất quán và liên kết: Đánh giá mức độ phù hợp của người được đề cử với mục tiêu của thương hiệu và truyền tải thông điệp của thương hiệu một cách nhất quán trên các nền tảng và tương tác khác nhau.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 2: Impactful Advertising Participation (25 grade)",
        vi: "Mục 2: Sự tham gia quảng cáo có tác động (25 điểm)",
      },
      description: [
        {
          en: "- Campaign Involvement: Examine the nominee’s participation in one or more advertising campaigns. Focus on how their involvement contributes to conveying positive and effective information about the product or service.",
          vi: "- Tham gia chiến dịch: Kiểm tra sự tham gia của người được đề cử vào một hoặc nhiều chiến dịch quảng cáo. Tập trung vào việc sự tham gia của họ góp phần truyền tải thông tin tích cực và hiệu quả như thế nào về sản phẩm hoặc dịch vụ.",
        },
        {
          en: "- Effectiveness of Campaigns: Measure the success of these campaigns, including how well they communicate the product’s benefits and connect with the target audience.",
          vi: "- Hiệu quả của chiến dịch: Đo lường mức độ thành công của các chiến dịch này, bao gồm cả mức độ chúng truyền đạt lợi ích của sản phẩm và kết nối với đối tượng mục tiêu.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Industry and Community Impact (20 grade)",
        vi: "Mục 3: Tác động đến ngành và cộng đồng (20 điểm)",
      },
      description: [
        {
          en: "- Positive Industry Impression: Evaluate any activities or campaigns led or participated in by the nominee that have made a positive impression on the industry. Highlight efforts that showcase leadership and excellence in advertising.",
          vi: "- Ấn tượng tích cực về ngành: Đánh giá bất kỳ hoạt động hoặc chiến dịch nào do người được đề cử dẫn đầu hoặc tham gia đã tạo ấn tượng tích cực cho ngành. Nêu bật những nỗ lực thể hiện khả năng lãnh đạo và sự xuất sắc trong quảng cáo.",
        },
        {
          en: "- Community and Societal Impact: Assess the nominee’s contributions to creating a positive impact on the community and society. Include examples of how their work has enhanced the brand’s reach or promoted cultural values.",
          vi: "- Tác động cộng đồng và xã hội: Đánh giá những đóng góp của người được đề cử trong việc tạo ra tác động tích cực đến cộng đồng và xã hội. Bao gồm các ví dụ về cách công việc của họ đã nâng cao phạm vi tiếp cận của thương hiệu hoặc quảng bá các giá trị văn hóa.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Enhancement of Brand and Cultural Values (15 grade)",
        vi: "Mục 4: Nâng cao giá trị thương hiệu và văn hóa (15 điểm)",
      },
      description: [
        {
          en: "- Brand Enhancement: Measure the nominee’s role in enhancing the brand’s reach and positioning. Provide examples of how their efforts have strengthened the brand’s presence and reputation.",
          vi: "- Nâng cao thương hiệu: Đo lường vai trò của người được đề cử trong việc nâng cao phạm vi tiếp cận và định vị của thương hiệu. Cung cấp ví dụ về nỗ lực của họ đã củng cố sự hiện diện và danh tiếng của thương hiệu như thế nào.",
        },
        {
          en: "- Promotion of Cultural Values: Evaluate the nominee’s contribution to promoting Vietnamese cultural values through their advertising work. Highlight any campaigns or activities that have celebrated or advanced cultural heritage.",
          vi: "- Phát huy giá trị văn hóa: Đánh giá sự đóng góp của ứng viên trong việc phát huy giá trị văn hóa Việt Nam thông qua công việc quảng cáo của họ. Nêu bật bất kỳ chiến dịch hoặc hoạt động nào nhằm tôn vinh hoặc nâng cao di sản văn hóa.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 5: Inspirational Leadership and Influence (10 grade)",
        vi: "Mục 5: Khả năng lãnh đạo và tạo ảnh hưởng truyền cảm hứng (10 điểm)",
      },
      description: [
        {
          en: "- Inspirational Influence: Assess the nominee’s ability to inspire others through their role as an advertising ambassador. Include examples of how they have motivated others or set a positive example in their field.",
          vi: "- Ảnh hưởng truyền cảm hứng: Đánh giá khả năng truyền cảm hứng cho người khác của người được đề cử thông qua vai trò đại sứ quảng cáo. Bao gồm các ví dụ về cách họ đã động viên người khác hoặc nêu gương tích cực trong lĩnh vực của họ.",
        },
        {
          en: "- Leadership in Advertising: Evaluate the nominee’s leadership qualities in the context of advertising, including their ability to lead campaigns, drive innovation, and set high standards.",
          vi: "- Lãnh đạo trong quảng cáo: Đánh giá phẩm chất lãnh đạo của người được đề cử trong bối cảnh quảng cáo, bao gồm khả năng dẫn dắt các chiến dịch, thúc đẩy sự đổi mới và đặt ra các tiêu chuẩn cao.",
        },
      ],
      points: 10,
    },
  ],
  "dai-su-quang-cao-duoc-yeu-thich-nhat": [
    {
      title: {
        en: "Section 1: Influence and Reach (30 grade)",
        vi: "Mục 1: Ảnh hưởng và phạm vi tiếp cận (30 điểm)",
      },
      description: [
        {
          en: "- Level of Influence: Evaluate the nominee’s overall influence, including the number of followers, interaction rates, and their ability to spread messages across various platforms. Provide metrics and examples that demonstrate their reach and impact.",
          vi: "- Mức độ ảnh hưởng: Đánh giá mức độ ảnh hưởng tổng thể của người được đề cử, bao gồm số lượng người theo dõi, tỷ lệ tương tác và khả năng truyền bá thông điệp của họ trên nhiều nền tảng khác nhau. Cung cấp các số liệu và ví dụ chứng minh phạm vi tiếp cận và tác động của chúng.",
        },
        {
          en: "- Audience Engagement: Assess the nominee’s ability to engage and interact with their audience effectively. Highlight the quality of their interactions and the depth of their connection with followers.",
          vi: "- Sự tham gia của khán giả: Đánh giá khả năng thu hút và tương tác với khán giả của họ một cách hiệu quả của người được đề cử. Làm nổi bật chất lượng tương tác của họ và mức độ kết nối sâu sắc của họ với những người theo dõi.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 2: Content Quality and Creativity (25 grade)",
        vi: "Mục 2: Chất lượng nội dung và sự sáng tạo (25 điểm)",
      },
      description: [
        {
          en: "- Content Excellence: Evaluate the quality of the content produced by the nominee, including creativity, originality, and the value it provides to their audience. Provide examples of standout content that showcases their unique voice and perspective.",
          vi: "- Nội dung xuất sắc: Đánh giá chất lượng nội dung do người được đề cử sản xuất, bao gồm tính sáng tạo, tính độc đáo và giá trị mà nội dung đó mang lại cho khán giả của họ. Cung cấp ví dụ về nội dung nổi bật thể hiện quan điểm và tiếng nói độc đáo của họ.",
        },
        {
          en: "- Innovation: Assess the nominee’s ability to introduce new ideas, formats, or approaches in their content. Highlight instances where their creativity has set trends or elevated industry standards.",
          vi: "- Sự đổi mới: Đánh giá khả năng của người được đề cử trong việc giới thiệu những ý tưởng, hình thức hoặc cách tiếp cận mới trong nội dung của họ. Nêu bật những trường hợp mà sự sáng tạo của họ đã tạo ra xu hướng hoặc nâng cao tiêu chuẩn ngành.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Personal Image and Professionalism (20 grade)",
        vi: "Mục 3: Hình ảnh cá nhân và sự chuyên nghiệp (20 điểm)",
      },
      description: [
        {
          en: "- Authenticity and Professionalism: Examine the nominee’s personal image, focusing on their authenticity, professionalism, and adherence to ethical standards. Provide examples of how they maintain a positive and credible public image.",
          vi: "- Tính xác thực và chuyên nghiệp: Kiểm tra hình ảnh cá nhân của người được đề cử, tập trung vào tính xác thực, tính chuyên nghiệp và việc tuân thủ các tiêu chuẩn đạo đức của họ. Cung cấp các ví dụ về cách họ duy trì hình ảnh tích cực và đáng tin cậy trước công chúng.",
        },
        {
          en: "- Ethical Conduct: Evaluate how the nominee handles partnerships, endorsements, and public interactions, ensuring they align with ethical practices and contribute positively to their field.",
          vi: "- Ứng xử đạo đức: Đánh giá cách người được đề cử xử lý các mối quan hệ đối tác, chứng thực và tương tác với công chúng, đảm bảo chúng phù hợp với các thông lệ đạo đức và đóng góp tích cực cho lĩnh vực của họ.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Community Engagement and Representation (15 grade)",
        vi: "Mục 4: Sự tương tác và đại diện của cộng đồng (15 điểm)",
      },
      description: [
        {
          en: "- Community Activities: Detail the nominee’s involvement in community activities, including their role in influencing, representing, and advocating for their community. Provide examples of how they contribute to community development and support relevant causes.",
          vi: "- Hoạt động cộng đồng:Trình bày chi tiết về sự tham gia của người được đề cử vào các hoạt động cộng đồng, bao gồm vai trò của họ trong việc gây ảnh hưởng, đại diện và vận động cho cộng đồng của họ. Cung cấp các ví dụ về cách họ đóng góp cho sự phát triển cộng đồng và hỗ trợ các mục đích liên quan.",
        },
        {
          en: "- Impact on Community: Assess the nominee’s ability to make a positive impact within their community through initiatives, collaborations, or advocacy.",
          vi: "- Tác động tới cộng đồng: Đánh giá khả năng của người được đề cử trong việc tạo ra tác động tích cực trong cộng đồng của họ thông qua các sáng kiến, hợp tác hoặc vận động chính sách.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 5: Marketing Effectiveness (10 grade)",
        vi: "Mục 5: Hiệu quả tiếp thị (10 điểm)",
      },
      description: [
        {
          en: "- Brand Collaboration and Promotion: Evaluate the nominee’s effectiveness in attracting customers, increasing brand awareness, and promoting sales through their platforms. Provide examples of successful marketing campaigns or partnerships that demonstrate their ability to drive commercial success.",
          vi: "- Hợp tác và quảng bá thương hiệu: Đánh giá hiệu quả của người được đề cử trong việc thu hút khách hàng, nâng cao nhận thức về thương hiệu và thúc đẩy doanh số thông qua nền tảng của họ. Cung cấp ví dụ về các chiến dịch tiếp thị hoặc quan hệ đối tác thành công chứng tỏ khả năng thúc đẩy thành công thương mại của họ.",
        },
        {
          en: "- Conversion Metrics: Assess the impact of their efforts on measurable outcomes, such as increased engagement, follower growth, or direct contributions to sales and brand recognition.",
          vi: "- Chỉ số chuyển đổi: Đánh giá tác động của những nỗ lực của họ đối với các kết quả có thể đo lường được, chẳng hạn như tăng mức độ tương tác, tăng lượng người theo dõi hoặc đóng góp trực tiếp vào doanh số bán hàng và nhận diện thương hiệu.",
        },
      ],
      points: 10,
    },
  ],
  "dai-su-quang-cao-nhi-duoc-yeu-thich-nhat": [
    {
      title: {
        en: "Section 1: Influence and Reach (30 grade)",
        vi: "Mục 1: Ảnh hưởng và phạm vi tiếp cận (30 điểm)",
      },
      description: [
        {
          en: "- Level of Influence: Evaluate the nominee’s overall influence, including the number of followers, interaction rates, and their ability to spread messages across various platforms. Provide metrics and examples that demonstrate their reach and impact.",
          vi: "- Mức độ ảnh hưởng: Đánh giá mức độ ảnh hưởng tổng thể của người được đề cử, bao gồm số lượng người theo dõi, tỷ lệ tương tác và khả năng truyền bá thông điệp của họ trên nhiều nền tảng khác nhau. Cung cấp các số liệu và ví dụ chứng minh phạm vi tiếp cận và tác động của chúng.",
        },
        {
          en: "- Audience Engagement: Assess the nominee’s ability to engage and interact with their audience effectively. Highlight the quality of their interactions and the depth of their connection with followers.",
          vi: "- Sự tham gia của khán giả: Đánh giá khả năng thu hút và tương tác với khán giả của họ một cách hiệu quả của người được đề cử. Làm nổi bật chất lượng tương tác của họ và mức độ kết nối sâu sắc của họ với những người theo dõi.",
        },
      ],
      points: 30,
    },
    {
      title: {
        en: "Section 2: Content Quality and Creativity (25 grade)",
        vi: "Mục 2: Chất lượng nội dung và sự sáng tạo (25 điểm)",
      },
      description: [
        {
          en: "- Content Excellence: Evaluate the quality of the content produced by the nominee, including creativity, originality, and the value it provides to their audience. Provide examples of standout content that showcases their unique voice and perspective.",
          vi: "- Nội dung xuất sắc: Đánh giá chất lượng nội dung do người được đề cử sản xuất, bao gồm tính sáng tạo, tính độc đáo và giá trị mà nội dung đó mang lại cho khán giả của họ. Cung cấp ví dụ về nội dung nổi bật thể hiện quan điểm và tiếng nói độc đáo của họ.",
        },
        {
          en: "- Innovation: Assess the nominee’s ability to introduce new ideas, formats, or approaches in their content. Highlight instances where their creativity has set trends or elevated industry standards.",
          vi: "- Sự đổi mới: Đánh giá khả năng của người được đề cử trong việc giới thiệu những ý tưởng, hình thức hoặc cách tiếp cận mới trong nội dung của họ. Nêu bật những trường hợp mà sự sáng tạo của họ đã tạo ra xu hướng hoặc nâng cao tiêu chuẩn ngành.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 3: Personal Image and Professionalism (20 grade)",
        vi: "Mục 3: Hình ảnh cá nhân và sự chuyên nghiệp (20 điểm)",
      },
      description: [
        {
          en: "- Authenticity and Professionalism: Examine the nominee’s personal image, focusing on their authenticity, professionalism, and adherence to ethical standards. Provide examples of how they maintain a positive and credible public image.",
          vi: "- Tính xác thực và chuyên nghiệp: Kiểm tra hình ảnh cá nhân của người được đề cử, tập trung vào tính xác thực, tính chuyên nghiệp và việc tuân thủ các tiêu chuẩn đạo đức của họ. Cung cấp các ví dụ về cách họ duy trì hình ảnh tích cực và đáng tin cậy trước công chúng.",
        },
        {
          en: "- Ethical Conduct: Evaluate how the nominee handles partnerships, endorsements, and public interactions, ensuring they align with ethical practices and contribute positively to their field.",
          vi: "- Ứng xử đạo đức: Đánh giá cách người được đề cử xử lý các mối quan hệ đối tác, chứng thực và tương tác với công chúng, đảm bảo chúng phù hợp với các thông lệ đạo đức và đóng góp tích cực cho lĩnh vực của họ.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Community Engagement and Representation (15 grade)",
        vi: "Mục 4: Sự tương tác và đại diện của cộng đồng (15 điểm)",
      },
      description: [
        {
          en: "- Community Activities: Detail the nominee’s involvement in community activities, including their role in influencing, representing, and advocating for their community. Provide examples of how they contribute to community development and support relevant causes.",
          vi: "- Hoạt động cộng đồng:Trình bày chi tiết về sự tham gia của người được đề cử vào các hoạt động cộng đồng, bao gồm vai trò của họ trong việc gây ảnh hưởng, đại diện và vận động cho cộng đồng của họ. Cung cấp các ví dụ về cách họ đóng góp cho sự phát triển cộng đồng và hỗ trợ các mục đích liên quan.",
        },
        {
          en: "- Impact on Community: Assess the nominee’s ability to make a positive impact within their community through initiatives, collaborations, or advocacy.",
          vi: "- Tác động tới cộng đồng: Đánh giá khả năng của người được đề cử trong việc tạo ra tác động tích cực trong cộng đồng của họ thông qua các sáng kiến, hợp tác hoặc vận động chính sách.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 5: Marketing Effectiveness (10 grade)",
        vi: "Mục 5: Hiệu quả tiếp thị (10 điểm)",
      },
      description: [
        {
          en: "- Brand Collaboration and Promotion: Evaluate the nominee’s effectiveness in attracting customers, increasing brand awareness, and promoting sales through their platforms. Provide examples of successful marketing campaigns or partnerships that demonstrate their ability to drive commercial success.",
          vi: "- Hợp tác và quảng bá thương hiệu: Đánh giá hiệu quả của người được đề cử trong việc thu hút khách hàng, nâng cao nhận thức về thương hiệu và thúc đẩy doanh số thông qua nền tảng của họ. Cung cấp ví dụ về các chiến dịch tiếp thị hoặc quan hệ đối tác thành công chứng tỏ khả năng thúc đẩy thành công thương mại của họ.",
        },
        {
          en: "- Conversion Metrics: Assess the impact of their efforts on measurable outcomes, such as increased engagement, follower growth, or direct contributions to sales and brand recognition.",
          vi: "- Chỉ số chuyển đổi: Đánh giá tác động của những nỗ lực của họ đối với các kết quả có thể đo lường được, chẳng hạn như tăng mức độ tương tác, tăng lượng người theo dõi hoặc đóng góp trực tiếp vào doanh số bán hàng và nhận diện thương hiệu.",
        },
      ],
      points: 10,
    },
  ],
  "grand-prix": [
    {
      title: {
        en: "Section 1: Creative Excellence (25 grade)",
        vi: "Mục 1: Sự xuất sắc trong sáng tạo (25 điểm)",
      },
      description: [
        {
          en: "- Originality and Innovation: Evaluate how unique and innovative the campaign is within the context of Vietnamese culture and market.",
          vi: "- Tính độc đáo và đổi mới : Đánh giá mức độ độc đáo và sáng tạo của chiến dịch trong bối cảnh văn hóa và thị trường Việt Nam.",
        },
        {
          en: "- Cultural Relevance: Assess how well the campaign reflects and respects Vietnamese traditions, values, and aesthetics.",
          vi: "- Sự phù hợp về văn hóa: Đánh giá xem chiến dịch phản ánh và tôn trọng truyền thống, giá trị và thẩm mỹ của Việt Nam như thế nào.",
        },
        {
          en: "- Artistic Quality: Consider the design quality, visuals, and overall execution.",
          vi: "- Chất lượng nghệ thuật: Hãy xem xét chất lượng thiết kế, hình ảnh và cách thực hiện tổng thể.",
        },
      ],
      points: 25,
    },
    {
      title: {
        en: "Section 2: Effectiveness and Results (20 grade)",
        vi: "Mục 2: Hiệu quả và Kết quả (20 điểm)",
      },
      description: [
        {
          en: `- Impact on Brand Objectives: Measure the campaign'
        s success in achieving
        its goals,
        such as
        increased sales,
        brand awareness,
        and customer
        engagement
        .`,
          vi: "- Tác động đến mục tiêu thương hiệu: Đo lường mức độ thành công của chiến dịch trong việc đạt được mục tiêu, chẳng hạn như tăng doanh số bán hàng, nhận thức về thương hiệu và mức độ tương tác của khách hàng.",
        },
        {
          en: "- Market Penetration: Evaluate how well the campaign penetrated the Vietnamese market and resonated with local consumers.",
          vi: "- Thâm nhập thị trường: Đánh giá mức độ thâm nhập của chiến dịch vào thị trường Việt Nam và gây được tiếng vang với người tiêu dùng địa phương.",
        },
        {
          en: "- Return on Investment (ROI): Assess the financial effectiveness and the measurable impact on the brand’s performance.",
          vi: "- Lợi tức đầu tư (ROI): Đánh giá hiệu quả tài chính và tác động có thể đo lường được đối với hiệu suất của thương hiệu.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 3: Audience Engagement (20 grade)",
        vi: "Mục 3: Mức độ tương tác của khán giả (20 điểm)",
      },
      description: [
        {
          en: "- Resonance with Target Audience: Examine how well the campaign connected with its intended Vietnamese audience, considering local tastes and preferences.",
          vi: "- Cộng hưởng với đối tượng mục tiêu: Kiểm tra mức độ kết nối của chiến dịch với khán giả Việt Nam mục tiêu, xem xét thị hiếu và sở thích địa phương.",
        },
        {
          en: "- Customer Interaction: Evaluate the quality and depth of customer interactions, including engagement on various local platforms and direct customer feedback.",
          vi: "- Tương tác khách hàng: Đánh giá chất lượng và độ sâu tương tác của khách hàng, bao gồm cả sự tương tác trên các nền tảng địa phương khác nhau và phản hồi trực tiếp của khách hàng.",
        },
        {
          en: "- Social Media Impact: Assess engagement metrics specific to popular Vietnamese social media platforms like Zalo, Facebook, and YouTube.",
          vi: "- Tác động của truyền thông xã hội: Đánh giá số liệu tương tác cụ thể cho các nền tảng truyền thông xã hội phổ biến của Việt Nam như Zalo, Facebook và YouTube.",
        },
      ],
      points: 20,
    },
    {
      title: {
        en: "Section 4: Integrated Campaign Approach (15 grade)",
        vi: "Mục 4: Phương pháp tiếp cận chiến dịch tích hợp (15 điểm)",
      },
      description: [
        {
          en: "- Use of Multiple Channels: Assess the campaign’s integration across various media channels, such as digital, print, TV, and local social media, to create a unified and comprehensive strategy.",
          vi: "- Sử dụng nhiều kênh: Đánh giá sự tích hợp của chiến dịch trên các kênh truyền thông khác nhau, chẳng hạn như kỹ thuật số, báo in, truyền hình và mạng xã hội địa phương, để tạo ra một chiến lược thống nhất và toàn diện.",
        },
        {
          en: "- Consistency and Cohesiveness: Evaluate how well the different elements and channels of the campaign work together to deliver a consistent message.",
          vi: "- Tính nhất quán và gắn kết: Đánh giá mức độ phối hợp giữa các yếu tố và kênh khác nhau của chiến dịch để truyền tải thông điệp nhất quán.",
        },
      ],
      points: 15,
    },
    {
      title: {
        en: "Section 5: Social and Cultural Impact (20 grade)",
        vi: "Mục 5: Tác động xã hội và văn hóa (20 điểm)",
      },
      description: [
        {
          en: "- Positive Social Actions: Evaluate the campaign’s contribution to promoting positive social change, addressing important societal issues in Vietnam, or supporting community initiatives.",
          vi: "- Hành động xã hội tích cực: Đánh giá mức độ đóng góp của chiến dịch trong việc thúc đẩy những thay đổi tích cực trong xã hội, giải quyết các vấn đề xã hội quan trọng ở Việt Nam hoặc hỗ trợ các sáng kiến cộng đồng.",
        },
        {
          en: "- Corporate Social Responsibility (CSR): Assess the brand’s efforts in social responsibility, including sustainability initiatives, ethical practices, and contributions to the Vietnamese community. Highlight specific actions and campaigns that have had a significant positive impact.",
          vi: "- Trách nhiệm xã hội của doanh nghiệp (CSR): Đánh giá những nỗ lực của thương hiệu trong trách nhiệm xã hội, bao gồm các sáng kiến bền vững, thực hành đạo đức và đóng góp cho cộng đồng Việt Nam. Làm nổi bật các hành động và chiến dịch cụ thể đã có tác động tích cực đáng kể.",
        },
        {
          en: "- Promotion of Vietnamese Culture: Consider how the campaign enhances the reach and appreciation of Vietnamese cultural values, both locally and internationally.",
          vi: "- Quảng bá văn hóa Việt Nam: Hãy xem xét cách chiến dịch nâng cao khả năng tiếp cận và đánh giá cao các giá trị văn hóa Việt Nam, cả ở địa phương và quốc tế.",
        },
      ],
      points: 20,
    },
  ],
}
