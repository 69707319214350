import React from "react"
import { Link } from "react-router-dom"
import LoadingIcon from "../LoadingIcon"

const Button = (
  {
    url = "",
    outline = false,
    className = "",
    isSubmit = false,
    disabled = false,
    loading = false,
    children,
    ...res
  }) => {
  const classNames = `w-fit relative flex items-center whitespace-nowrap justify-center text-16 px-4 h-12 rounded-button ${
    disabled ? "before:absolute before:w-full before:h-full before:bg-black/50 before:rounded-button cursor-not-allowed" : ""
  } ${
    outline ? "border-[2px] border-yellow" : "bg-yellowGradient"
  } ${className}`
  return url ? (
    <Link to={url} className={classNames} {...res}>
      {
        loading ?
          <LoadingIcon/>
          :
          <p className={outline ? "text-yellowGradient" : "font-bold text-pink"}>
            {children}
          </p>
      }
    </Link>
  ) : (
    <button
      disabled={disabled}
      type={isSubmit ? "submit" : "button"}
      className={classNames}
      {...res}
    >
      {
        loading ?
          <LoadingIcon/>
          :
          <p className={`${outline ? "text-yellowGradient" : "font-bold text-pink"}`}>
            {children}
          </p>
      }
    </button>
  )

}

export default Button
